import { createSlice } from "@reduxjs/toolkit";

import claims from "../../../../actions/claims";
import genericAxiosCall from "../../../../AxiosConfig/genericAxiosCall";

import { setListingState } from "../../filters/claimManagerFilters";
import { serverRoutes } from "./../../../../constants/serverRoutes";

export const PatientDetailSlice = createSlice({
  name: "PatientDetailSlice",
  initialState: {
    patientDetail_837Manager: [],
    patientDetail_835Payment: [],
    patientDetail_TimeLine: [],
    patientDetail_TimeLine_Obj: {},
    patientDetail_Data: {},
  },
  reducers: {
    setPateintDetail_837Manager: (state, action) => {
      let data = action.payload;

      state.patientDetail_837Manager = data;
    },

    setPateintDetail_835Payment: (state, action) => {
      state.patientDetail_835Payment = action.payload;
    },
    setPateintDetail_TimeLine: (state, action) => {
      let data = action.payload;

      // handle timeLine here
      if (data?.patientTimeline?.length > 0) {
        // let newArr = sortByDate(data?.patientTimeline, "dos");

        // state.patientDetail_TimeLine = {
        //   count: data?.count,
        //   patientTimeline: newArr,

        // };
        const timeLine = data?.patientTimeline;
        let obj = {};

        timeLine?.map((item, index) => {
          if (obj[item?.dos]) {
            let arr = {
              chargeAmount: item?.chargeAmount,
              type: item?.claimRequestId ? "837" : "835",
              claimPaymentId: item?.claimPaymentId,
              claimRequestId: item?.claimRequestId,
              claimType: item?.claimType,
              dos: item?.dos,
              patientControlNo: item?.patientControlNo,
              reimbursentAmount: item?.reimbursentAmount,
              status: item?.status,
            };
            obj[item?.dos].push(arr);
          } else {
            let arr = [
              {
                chargeAmount: item?.chargeAmount,
                type: item?.claimRequestId ? "837" : "835",
                claimPaymentId: item?.claimPaymentId,
                claimRequestId: item?.claimRequestId,
                claimType: item?.claimType,
                dos: item?.dos,
                patientControlNo: item?.patientControlNo,
                reimbursentAmount: item?.reimbursentAmount,
                status: item?.status,
              },
            ];

            obj[item?.dos] = arr;
          }
        });

        state.patientDetail_TimeLine_Obj = obj;
        state.patientDetail_TimeLine = data;
      } else {
        state.patientDetail_TimeLine_Obj = {};

        state.patientDetail_TimeLine = [];
      }
    },

    setPatientDetail_Data: (state, action) => {
      let data = action.payload;
      if (data?.length) {
        state.patientDetail_Data = data[0];
      } else {
        state.patientDetail_Data = {};
      }
    },
  },
});

export const {
  setPateintDetail_837Manager,
  setPateintDetail_835Payment,
  setPateintDetail_TimeLine,
  setPatientDetail_Data,
} = PatientDetailSlice.actions;

export const getPatientDetail_837Manager_Call =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.patientDatail837Filter;
    let patientDatailFilterForId =
      getState().npReducers.filters.patientDatailFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;
    let PatientId = patientDatailFilterForId.id;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "patientDatail837Filter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await claims.getPatentDetailFor837Manager({
        start,
        limit,
        PatientId,
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "patientDatail837Filter",
          value: {
            hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
            totalRecord_DB: res?.data?.data?.count,
          },
        })
      );
      if (res?.data?.success) {
        dispatch(setPateintDetail_837Manager(res?.data?.data));
      } else {
        dispatch(setPateintDetail_837Manager([]));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "patientDatail837Filter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setPateintDetail_837Manager([]));
      return false;
    }
  };

export const getPatientDetail_835Payment_Call =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.patientDatailFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;
    let PatientId = sliceState.id;
    let order = sliceState.order;
    let orderBy = sliceState.orderBy;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "patientDatailFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await claims.getPatentDetailFor835Payment({
        start,
        limit,
        PatientId,
        order,
        orderBy,
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "patientDatailFilter",
          value: {
            hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
            totalRecord_DB: res?.data?.data?.count,
          },
        })
      );

      if (res?.data?.success) {
        dispatch(setPateintDetail_835Payment(res?.data?.data));
      } else {
        dispatch(setPateintDetail_835Payment([]));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "patientDatailFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setPateintDetail_835Payment([]));
      return false;
    }
  };

export const getPatientDetail_TimeLine_Call =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.patientTimelineDatailFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;
    let PatientId = sliceState.id;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      let params = {
        start,
        limit,
      };
      dispatch(
        setListingState({
          listing: "patientTimelineDatailFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );
      ///// API CALL //////

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_PATIENT_TIME_LINE_DATA}${PatientId}?`,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      if (res?.data?.success) {
        dispatch(setPateintDetail_TimeLine(res?.data?.data));
      } else {
        dispatch(setPateintDetail_TimeLine([]));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(setPateintDetail_TimeLine([]));
      return false;
    } finally {
      dispatch(
        setListingState({
          listing: "patientTimelineDatailFilter",
          value: {
            loadingMore: "",
          },
        })
      );
    }
  };

export const getPatientDetail_Data_Call = () => async (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.patientDatailFilter;

  let id = sliceState.id;

  try {
    dispatch(
      setListingState({
        listing: "patientDatailFilter",
        value: {
          patientDetailLoadingMore: "data-loading",
        },
      })
    );

    ///// API CALL //////

    const res = await claims.getPatientDetails(id);

    ////// API CALL END //////////////

    dispatch(
      setListingState({
        listing: "patientDatailFilter",
        value: {
          // hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
          patientDetailLoadingMore: "",
          // totalRecord_DB: res?.data?.data?.count,
        },
      })
    );

    if (res?.data?.success) {
      dispatch(setPatientDetail_Data(res?.data?.data));
    } else {
      dispatch(setPatientDetail_Data([]));
    }
    return res?.data;
  } catch (e) {
    // console.log("e--->>", e);

    dispatch(
      setListingState({
        listing: "patientDatailFilter",
        value: {
          // initialLoading: false,
          patientDetailLoadingMore: "",
          // hasMore: false,
        },
      })
    );
    dispatch(setPatientDetail_Data([]));
    return false;
  }
};
export default PatientDetailSlice.reducer;
