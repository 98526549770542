import { createSlice } from "@reduxjs/toolkit";

// import {
//   // setListingState,
//   resetListingState,
//   setListingState,
// } from '../filters/claimManagerFilters';
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";
import { toast } from "react-toastify";
import {
  ErrorToast,
  SuccessToast,
  UnSuccessfullToast,
} from "../../../components/common/Utils/utils";

export const Departments = createSlice({
  name: "Departments",
  initialState: {
    //Filter Array

    departmentsListCPT: [],
    departmentsListHCPCS: [],
    departmentsListCustom: [],

    loading: false,
    loadingHCPCS: false,
    loadingCustom: false,

    adding: false,
  },
  reducers: {
    gettingData: (state, action) => {
      state.loading = true;
    },
    settingDepartmentsList: (state, action) => {
      state.departmentsListCPT = action?.payload;
      state.loading = false;
    },
    gettingDepartmentsListFailed: (state, action) => {
      state.departmentsListCPT = [];
      state.loading = false;
    },

    gettingDataCustom: (state, action) => {
      state.loadingCustom = true;
    },
    settingDepartmentsListCustom: (state, action) => {
      state.departmentsListCustom = action?.payload;
      state.loadingCustom = false;
    },
    gettingDepartmentsListFailedCustom: (state, action) => {
      state.departmentsListCustom = [];
      state.loadingCustom = false;
    },
    gettingDataHCPCS: (state, action) => {
      state.loadingHCPCS = true;
    },
    settingDepartmentsListHCPCS: (state, action) => {
      state.departmentsListHCPCS = action?.payload;
      state.loadingHCPCS = false;
    },
    gettingDepartmentsListFailedHCPCS: (state, action) => {
      state.departmentsListHCPCS = [];
      state.loadingHCPCS = false;
    },
    addingData: (state, action) => {
      state.adding = true;
    },
    added: (state, action) => {
      state.adding = false;
    },
  },
});

export const {
  gettingData,
  settingDepartmentsList,
  gettingDepartmentsListFailed,
  gettingDataCustom,
  settingDepartmentsListCustom,
  gettingDepartmentsListFailedCustom,
  gettingDataHCPCS,
  settingDepartmentsListHCPCS,
  gettingDepartmentsListFailedHCPCS,
  addingData,
  added,
} = Departments.actions;

//
export const servicelinesReset =
  (setOpenResetModal) => async (dispatch, getState) => {
    try {
      await genericAxiosCall(
        serverRoutes?.GET_RESET_SERVICE_LINES_LIST,
        "put",
        "",
        ""
      ).then((res) => {
        toast.success(
          res?.data?.message ? res?.data?.message : "Something went wrong",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
      setOpenResetModal(false);

      dispatch(gettingDepartmentsList());
      dispatch(gettingDepartmentsListHCPCS());
      dispatch(gettingDepartmentsListCustom());
    } catch (error) {
      toast.error(
        res?.data?.message ? res?.data?.message : "Something went wrong",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };
export const gettingDepartmentsList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      //   dispatch(
      //     resetListingState({
      //       listing: "goalTrackingFilter",
      //       ignore: ["claimStatus", "payers", "frequencyType", "search"],
      //     })
      //   );
    }
    dispatch(gettingData());
    let params = {
      CodeType: "CPT",
      timestamp: new Date().getTime(),
    };
    genericAxiosCall(
      serverRoutes.GET_DEPARTMENTMENT_TREE_VIEW,
      "get",
      "",
      params
    )
      .then((res) => {
        let data = JSON.parse(res?.data?.data);
        dispatch(settingDepartmentsList(data));
      })
      .catch((error) => {
        dispatch(gettingDepartmentsListFailed());

        // toast.error(
        //   error.response.data.message
        //     ? error.response.data.message
        //     : "Something went wrong",
        //   {
        //     position: toast.POSITION.TOP_CENTER,
        //   }
        // );
      });
  };
export const gettingDepartmentsListHCPCS =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      //   dispatch(
      //     resetListingState({
      //       listing: "goalTrackingFilter",
      //       ignore: ["claimStatus", "payers", "frequencyType", "search"],
      //     })
      //   );
    }

    dispatch(gettingDataHCPCS());
    let params = {
      CodeType: "HCPCS",
      timestamp: new Date().getTime(),
    };
    try {
      genericAxiosCall(
        serverRoutes.GET_DEPARTMENTMENT_TREE_VIEW,
        "get",
        "",
        params
      ).then((res) => {
        let data = JSON.parse(res?.data?.data);

        dispatch(settingDepartmentsListHCPCS(data));
      });
    } catch (error) {
      dispatch(gettingDepartmentsListFailedHCPCS());

      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Something went wrong",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };
export const gettingDepartmentsListCustom =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      //   dispatch(
      //     resetListingState({
      //       listing: "goalTrackingFilter",
      //       ignore: ["claimStatus", "payers", "frequencyType", "search"],
      //     })
      //   );
    }

    dispatch(gettingDataCustom());
    let params = {
      CodeType: "Custom",
      timestamp: new Date().getTime(),
    };
    try {
      genericAxiosCall(
        serverRoutes.GET_DEPARTMENTMENT_TREE_VIEW,
        "get",
        "",
        params
      ).then((res) => {
        let data = JSON.parse(res?.data?.data);
        dispatch(settingDepartmentsListCustom(data));
      });
    } catch (error) {
      dispatch(gettingDepartmentsListFailedCustom());

      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Something went wrong",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

export const addingInitialLevel =
  (
    inputs,
    range,
    procedureCodeType,
    handleClose,
    setDefaultPath,
    setInitialLevel,
    event
  ) =>
  async (dispatch, getState) => {
    // dispatch(gettingData());
    dispatch(addingData());
    const body = {
      Level0Name: inputs?.name,
      Level0RangeType: inputs?.rangeType,
      Level0Range: range,
      ProcedureCodeType: procedureCodeType,
      IsAlphaRange: inputs?.isAlphanumeric,
      AlphaCharacter: inputs?.isAlphanumeric ? inputs?.alphanumericValue : "",
      CodeType: inputs?.codeType,
      Category: inputs?.category,
      Visible: inputs?.visible,
    };
    genericAxiosCall(serverRoutes.SET_AT_INITIAL_LEVEL, "post", body, "")
      .then((res) => {
        dispatch(added());
        if (res?.data?.success === true) {
          toast.success(res?.data?.message ? res?.data?.message : "Success", {
            position: toast.POSITION.TOP_CENTER,
          });
          handleClose();

          dispatch(event());
          setDefaultPath([]);
          setInitialLevel(false);
        } else {
          toast.error(
            res?.data?.message ? res?.data?.message : "Something went wrong",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
        return res;
      })
      .catch((error) => {
        // dispatch(gettingDepartmentsListFailed());
        dispatch(added());
        toast.error(
          error.response.data ? error.response.data : "Something went wrong",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        throw new Error(error);
      });
  };

export const addingInsideLevel =
  (body, level, handleClose, event) => async (dispatch, getState) => {
    // dispatch(gettingData());
    dispatch(addingData());

    genericAxiosCall(
      `${serverRoutes.SET_AT_LEVEL}${level + 1}`,
      "post",
      body,
      ""
    )
      .then((res) => {
        dispatch(added());
        if (res?.data?.success) {
          SuccessToast(res);
          handleClose();
          dispatch(event());
        } else {
          UnSuccessfullToast(res);
        }

        return res;
      })
      .catch((error) => {
        dispatch(added());
        ErrorToast(error);
      });
  };

export const editInsideLevel =
  (body, level, handleClose, event) => async (dispatch, getState) => {
    // dispatch(gettingData());
    dispatch(addingData());

    genericAxiosCall(`${serverRoutes.EDIT_AT_LEVEL}${level}`, "put", body, "")
      .then((res) => {
        if (res?.data?.success) {
          SuccessToast(res);
          handleClose();
          dispatch(event());
        } else {
          UnSuccessfullToast(res);
        }

        return res;
      })
      .catch((error) => {
        ErrorToast(error);
      })
      .finally(() => {
        dispatch(added());
      });
  };

export const deleteLevel =
  (treeViewItem, level, event, setDeleteItem, setOpenDeleteModal) =>
  async (dispatch, getState) => {
    try {
      genericAxiosCall(
        `${serverRoutes.DELETE_LEVEL}${level}?Level${level}Id=${treeViewItem?.Id}`,
        "delete",
        "",
        ""
      )
        .then((res) => {
          toast.success("Deleted Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          dispatch(event());
          setDeleteItem({});
          setOpenDeleteModal(false);
          return res;
        })
        .catch((error) => {
          setDeleteItem({});
          setOpenDeleteModal(false);
          toast.error(
            error.response.data.message
              ? error.response.data.message
              : "Something went wrong",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    } catch (e) {
      // console.log("e--->>", e);
    } finally {
      setDeleteItem({});
      setOpenDeleteModal(false);
    }
  };

export default Departments.reducer;
