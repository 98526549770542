import { createSlice } from "@reduxjs/toolkit";
import {
  setListingState,
  resetListingState,
  resetListingStateForResetButton,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  getCurrentClientId,
  // commonPaginationWithCatch,
  getMultiSelectFilterValueForApi,
  getTreeNodeObj,
} from "../../../components/common/Utils/utils";
import genericAxiosCall from "./../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "./../../../constants/serverRoutes";
import { getClaimAllFiltersData_Call } from "./claimAllFiltersSlice";
import store from "../../store";

export const denialDetailsSlice = createSlice({
  name: "denialDetailsSlice",
  initialState: {
    // denial by claims screen data
    allDenialsList: {
      tabularArr: [],
      totalAllowedAmount: 0,
      totalBilledAmount: 0,
      totalDeniedAmount: 0,
      totalPaidAmount: 0,
      patientRespAmount: 0,
      count: 0,
    },
    payersForDenialbyClaimsFilterArr: [],
    ReasonCodesForDenialbyClaimsFilterArr: [],
    PayeeForDenialbyClaimsFilterArr: [],
    fillingIndicatorForDenialbyClaimsFilterArr: [],
    cptCodesForDenialbyClaimsFilterArr: [],
    jCodesForDenialbyClaimsFilterArr: [],
    IPRemarkCodesForDenialbyClaimsFilterArr: [],
    OPRemarkCodesForDenialbyClaimsFilterArr: [],

    denialGroupByPayerDataList: [],

    denialGroupByCptDataList: [],

    denialGroupByReasonCodeDataList: [],

    // denials By ServiceLine
    denialsByServiceLine: {
      denialByServiceLineList: [],
      count: null,
      totalBilledAmount: null,
      totalPaidAmount: null,
      totalClaimAmount: null,
    },

    // denials By partiallyDenied
    deniedByPartiallyDeniedList: {
      tabularDataList: [],
      count: null,
      totalBilledAmount: null,
      totalPaidAmount: null,
    },
    payersForDenialByPartiallyFilterArr: [],
    ReasonCodesForDenialByPartiallyFilterArr: [],
    PayeeForDenialByPartiallyFilterArr: [],
    fillingIndicatorForDenialByPartiallyFilterArr: [],
    cptCodesForDenialByPartiallyFilterArr: [],
    jCodesForDenialByPartiallyFilterArr: [],
    IPRemarkCodesForDenialByPartiallyFilterArr: [],
    OPRemarkCodesForDenialByPartiallyFilterArr: [],

    // denials By reversalDenied
    deniedByreversalDeniedList: {
      tabularDataList: [],
      count: null,
      totalBilledAmount: null,
      totalPaidAmount: null,
    },

    //denials By ServiceLine GB PayerCN
    denialsByServiceLineGBPayerCN_Data: {},

    payersForDenialByReversalFilterArr: [],
    ReasonCodesForDenialByReversalFilterArr: [],
    PayeeForDenialByReversalFilterArr: [],
    fillingIndicatorForDenialByReversalFilterArr: [],
    cptCodesForDenialByReversalFilterArr: [],
    jCodesForDenialByReversalFilterArr: [],
    IPRemarkCodesForDenialByReversalFilterArr: [],
    OPRemarkCodesForDenialByReversalFilterArr: [],

    // new filters
    filterLoader: {
      payers: true,
      serviceLine: true,
      claimStatus: true,
      reasoncode: true,
      facility: true,
      provider: true,
      fillingIndicator: true,
      ipRemarkCode: true,
      cptCode: true,
      groupReasonCode: true,
    },
    // filters for (denial by claims) screen
    serviceLineFilterArr: [],
    cptCodesForFilterArr: [],
    selectedCptFilter: [],
    groupReasonCodeFilterArr: [],
    claimStatusForFilterArr: [],
    payersForFilterArr: [],
    payeeForFilterArr: [],
    facilityForFilterArr: [],
    claimAdjReasonCodesForFilterArr: [],
    claimfillingIndiCodeForFilterArr: [],
    jCodesForFilterArr: [],
    IPRemarkCodesForFilterArr: [],
    OPRemarkCodesForFilterArr: [],
  },
  reducers: {
    denialDetails_AllDenials: (state, action) => {
      let data = action.payload;

      // table view data
      if (data?.denialClaims?.length) {
        state.allDenialsList.tabularArr = data?.denialClaims;
        state.allDenialsList.totalAllowedAmount =
          data?.denialClaims[0]?.totalAllowedAmount;
        state.allDenialsList.totalBilledAmount =
          data?.denialClaims[0]?.totalBilledAmount;
        state.allDenialsList.totalDeniedAmount =
          data?.denialClaims[0]?.totalDeniedAmount;
        state.allDenialsList.totalPaidAmount =
          data?.denialClaims[0]?.totalPaidAmount;
        state.allDenialsList.patientRespAmount =
          data?.denialClaims[0]?.patientRespAmount;

        state.allDenialsList.count = data?.denialClaims[0]?.count;
      } else {
        state.allDenialsList.tabularArr = [];
        state.allDenialsList.totalAllowedAmount = 0;
        state.allDenialsList.totalBilledAmount = 0;
        state.allDenialsList.totalDeniedAmount = 0;
        state.allDenialsList.totalPaidAmount = 0;
        state.allDenialsList.patientRespAmount = 0;
        state.allDenialsList.count = 0;
      }

      // // for  FillingIndicatorCodeList filter Arr
      // if (data?.FillingIndicatorCodeList?.length) {
      //   let newArr = [];
      //   data?.FillingIndicatorCodeList?.forEach((item) => {
      //     newArr.push({
      //       label: item?.description,
      //       value: item?.code,
      //     });
      //   });
      //   state.fillingIndicatorForDenialbyClaimsFilterArr = newArr;
      // } else {
      //   state.fillingIndicatorForDenialbyClaimsFilterArr = [];
      // }
      // // for  JCodes filter Arr
      // if (data?.JCodes?.length) {
      //   let newArr = [];
      //   data?.JCodes?.forEach((item) => {
      //     let userFriendlyName = item.description?.length
      //       ? `${item.cptCode} - ${item.description}`
      //       : `${item.cptCode}`;
      //     newArr.push({
      //       value: item?.cptCode,
      //       count: item?.count,
      //       label: userFriendlyName,
      //     });
      //   });

      //   state.jCodesForDenialbyClaimsFilterArr = newArr;
      // } else {
      //   state.jCodesForDenialbyClaimsFilterArr = [];
      // }

      // // for  cptCodes filter Arr
      // if (data?.cptCodes?.length) {
      //   let newArr = [];
      //   data?.cptCodes?.forEach((item) => {
      //     let userFriendlyName = item?.description?.length
      //       ? `${item?.cptCode} - ${item?.description}`
      //       : `${item?.cptCode}`;
      //     newArr.push({
      //       value: item?.cptCode,
      //       count: item?.count,
      //       label: userFriendlyName,
      //     });
      //   });

      //   let descending = newArr.sort(
      //     (a, b) => Number(b.count) - Number(a.count)
      //   );

      //   state.cptCodesForDenialbyClaimsFilterArr = descending;
      // } else {
      //   state.cptCodesForDenialbyClaimsFilterArr = [];
      // }

      // // for  payers filter Arr
      // if (data?.payers?.length) {
      //   let newArr = [];
      //   data?.payers?.forEach((item) => {
      //     newArr.push({
      //       label: item?.payer,
      //       value: item?.id,
      //       // id: item.id,
      //     });
      //   });

      //   state.payersForDenialbyClaimsFilterArr = newArr;
      // } else {
      //   state.payersForDenialbyClaimsFilterArr = [];
      // }

      // // for  Payee filter Arr
      // if (data?.Payee?.length) {
      //   let newArr = [];
      //   data?.Payee?.forEach((item) => {
      //     let payeeName_With_PrimaryId = `${item?.name} - ${item?.primaryId}`;

      //     newArr.push({
      //       label: payeeName_With_PrimaryId,
      //       value: item?.payeeId,
      //       // id: item.id,
      //     });
      //   });

      //   state.PayeeForDenialbyClaimsFilterArr = newArr;
      // } else {
      //   state.PayeeForDenialbyClaimsFilterArr = [];
      // }

      // // for  ReasonCodes filter Arr
      // if (data?.ReasonCodes?.length) {
      //   let newArr = [];
      //   data?.ReasonCodes?.forEach((item) => {
      //     let userFriendlyName = item?.description?.length
      //       ? `${item?.reasonCode} - ${item?.description}`
      //       : `${item?.reasonCode}`;
      //     newArr.push({
      //       value: item?.reasonCode,
      //       count: item?.reasonCodeCount,
      //       label: userFriendlyName,
      //     });
      //   });

      //   let descending = newArr.sort(
      //     (a, b) => Number(b.count) - Number(a.count)
      //   );

      //   state.ReasonCodesForDenialbyClaimsFilterArr = descending;
      // } else {
      //   state.ReasonCodesForDenialbyClaimsFilterArr = [];
      // }

      // // for  IPRemarkCodes filter Arr
      // if (data?.IPRemarkCodes?.length) {
      //   let newArr = [];
      //   data?.IPRemarkCodes?.forEach((item) => {
      //     let userFriendlyName = `${item?.ipRemarkCode}${
      //       item?.description ? ` - ${item?.description}` : ""
      //     }`;
      //     newArr.push({
      //       value: item?.ipRemarkCode,
      //       label: userFriendlyName,
      //     });
      //   });

      //   state.IPRemarkCodesForDenialbyClaimsFilterArr = newArr;
      // } else {
      //   state.IPRemarkCodesForDenialbyClaimsFilterArr = [];
      // }

      // // for  OPRemarkCodes filter Arr
      // if (data?.OPRemarkCodes?.length) {
      //   let newArr = [];
      //   data?.OPRemarkCodes?.forEach((item) => {
      //     let userFriendlyName = `${item?.opRemarkCode}${
      //       item?.description ? ` - ${item?.description}` : ""
      //     }`;
      //     newArr.push({
      //       value: item?.opRemarkCode,
      //       label: userFriendlyName,
      //     });
      //   });

      //   state.OPRemarkCodesForDenialbyClaimsFilterArr = newArr;
      // } else {
      //   state.OPRemarkCodesForDenialbyClaimsFilterArr = [];
      // }
    },

    denialByServiceLine_Data: (state, action) => {
      let data = action.payload;

      // for table view data
      if (data?.denialClaims?.length) {
        state.denialsByServiceLine.denialByServiceLineList = data?.denialClaims;
        state.denialsByServiceLine.count = data?.denialClaims[0]?.count;
        state.denialsByServiceLine.totalBilledAmount =
          data?.denialClaims[0]?.totalBilledAmount;
        state.denialsByServiceLine.totalClaimAmount =
          data?.denialClaims[0]?.totalDenialsAmount;
        state.denialsByServiceLine.totalPaidAmount =
          data?.denialClaims[0]?.totalPaidAmount;
      } else {
        state.denialsByServiceLine.denialByServiceLineList = [];
        state.denialsByServiceLine.count = null;
        state.denialsByServiceLine.totalBilledAmount = null;
        state.denialsByServiceLine.totalPaidAmount = null;
        state.denialsByServiceLine.totalClaimAmount = null;
      }
    },

    partiallyDenied_Data: (state, action) => {
      let data = action.payload;

      // for table view data
      if (data?.denialClaims?.length) {
        state.deniedByPartiallyDeniedList.tabularDataList = data?.denialClaims;
        state.deniedByPartiallyDeniedList.count = data?.count;
        state.deniedByPartiallyDeniedList.totalBilledAmount =
          data?.totalBilledAmount;
        state.deniedByPartiallyDeniedList.totalPaidAmount =
          data?.totalPaidAmount;
      } else {
        state.deniedByPartiallyDeniedList.tabularDataList = [];
        state.deniedByPartiallyDeniedList.count = null;
        state.deniedByPartiallyDeniedList.totalBilledAmount = null;
        state.deniedByPartiallyDeniedList.totalPaidAmount = null;
      }
    },

    partiallyDeniedFilters_Data: (state, action) => {
      let data = action.payload;

      // for  FillingIndicatorCodeList filter Arr
      if (data?.FillingIndicatorCodeList?.length) {
        let newArr = [];
        data?.FillingIndicatorCodeList?.forEach((item) => {
          newArr.push({
            label: item?.description,
            value: item?.code,
          });
        });
        state.fillingIndicatorForDenialByPartiallyFilterArr = newArr;
      } else {
        state.fillingIndicatorForDenialByPartiallyFilterArr = [];
      }
      // for  JCodes filter Arr
      if (data?.JCodes?.length) {
        let newArr = [];
        data?.JCodes?.forEach((item) => {
          let userFriendlyName = item.description?.length
            ? `${item.cptCode} - ${item.description}`
            : `${item.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            count: item?.count,
            label: userFriendlyName,
          });
        });

        state.jCodesForDenialByPartiallyFilterArr = newArr;
      } else {
        state.jCodesForDenialByPartiallyFilterArr = [];
      }

      // for  cptCodes filter Arr
      if (data?.cptCodes?.length) {
        let newArr = [];
        data?.cptCodes?.forEach((item) => {
          let userFriendlyName = item?.description?.length
            ? `${item?.cptCode} - ${item?.description}`
            : `${item?.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            count: item?.count,
            label: userFriendlyName,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.cptCodesForDenialByPartiallyFilterArr = descending;
      } else {
        state.cptCodesForDenialByPartiallyFilterArr = [];
      }

      // for  payers filter Arr
      if (data?.payers?.length) {
        let newArr = [];
        data?.payers?.forEach((item) => {
          newArr.push({
            label: item?.payer,
            value: item?.id,
            // id: item.id,
          });
        });

        state.payersForDenialByPartiallyFilterArr = newArr;
      } else {
        state.payersForDenialByPartiallyFilterArr = [];
      }

      // for  Payee filter Arr
      if (data?.Payee?.length) {
        let newArr = [];
        data?.Payee?.forEach((item) => {
          let payeeName_With_PrimaryId = `${item?.name} - ${item?.primaryId}`;
          newArr.push({
            label: payeeName_With_PrimaryId,
            value: item?.payeeId,
            // id: item.id,
          });
        });

        state.PayeeForDenialByPartiallyFilterArr = newArr;
      } else {
        state.PayeeForDenialByPartiallyFilterArr = [];
      }

      // for  ReasonCodes filter Arr
      if (data?.ReasonCodes?.length) {
        let newArr = [];
        data?.ReasonCodes?.forEach((item) => {
          let userFriendlyName = item?.description?.length
            ? `${item?.cptCode} - ${item?.description}`
            : `${item?.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            // count: item?.count,
            label: userFriendlyName,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.ReasonCodesForDenialByPartiallyFilterArr = descending;
      } else {
        state.ReasonCodesForDenialByPartiallyFilterArr = [];
      }

      // for  IPRemarkCodes filter Arr
      if (data?.IPRemarkCodes?.length) {
        let newArr = [];
        data?.IPRemarkCodes?.forEach((item) => {
          let userFriendlyName = `${item?.ipRemarkCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            value: item?.ipRemarkCode,
            label: userFriendlyName,
          });
        });

        state.IPRemarkCodesForDenialByPartiallyFilterArr = newArr;
      } else {
        state.IPRemarkCodesForDenialByPartiallyFilterArr = [];
      }

      // for  OPRemarkCodes filter Arr
      if (data?.OPRemarkCodes?.length) {
        let newArr = [];
        data?.OPRemarkCodes?.forEach((item) => {
          let userFriendlyName = `${item?.opRemarkCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            value: item?.opRemarkCode,
            label: userFriendlyName,
          });
        });

        state.OPRemarkCodesForDenialByPartiallyFilterArr = newArr;
      } else {
        state.OPRemarkCodesForDenialByPartiallyFilterArr = [];
      }
    },

    reversalDenied_Data: (state, action) => {
      let data = action.payload;

      // for table view data
      if (data?.denialClaims?.length) {
        state.deniedByreversalDeniedList.tabularDataList = data.denialClaims;
        state.deniedByreversalDeniedList.count = data.count;
        state.deniedByreversalDeniedList.totalBilledAmount =
          data.totalBilledAmount;
        state.deniedByreversalDeniedList.totalPaidAmount = data.totalPaidAmount;
      } else {
        state.deniedByreversalDeniedList.tabularDataList = [];
        state.deniedByreversalDeniedList.count = null;
        state.deniedByreversalDeniedList.totalBilledAmount = null;
        state.deniedByreversalDeniedList.totalPaidAmount = null;
      }
    },

    reversalDeniedFilters_Data: (state, action) => {
      let data = action.payload;

      // for  FillingIndicatorCodeList filter Arr
      if (data?.FillingIndicatorCodeList?.length) {
        let newArr = [];
        data?.FillingIndicatorCodeList?.forEach((item) => {
          newArr.push({
            label: item?.description,
            value: item?.code,
          });
        });
        state.fillingIndicatorForDenialByReversalFilterArr = newArr;
      } else {
        state.fillingIndicatorForDenialByReversalFilterArr = [];
      }
      // for  JCodes filter Arr
      if (data?.JCodes?.length) {
        let newArr = [];
        data?.JCodes?.forEach((item) => {
          let userFriendlyName = item.description?.length
            ? `${item.cptCode} - ${item.description}`
            : `${item.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            count: item?.count,
            label: userFriendlyName,
          });
        });

        state.jCodesForDenialByReversalFilterArr = newArr;
      } else {
        state.jCodesForDenialByReversalFilterArr = [];
      }

      // for  cptCodes filter Arr
      if (data?.cptCodes?.length) {
        let newArr = [];
        data?.cptCodes?.forEach((item) => {
          let userFriendlyName = item?.description?.length
            ? `${item?.cptCode} - ${item?.description}`
            : `${item?.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            count: item?.count,
            label: userFriendlyName,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.cptCodesForDenialByReversalFilterArr = descending;
      } else {
        state.cptCodesForDenialByReversalFilterArr = [];
      }

      // for  payers filter Arr
      if (data?.payers?.length) {
        let newArr = [];
        data?.payers?.forEach((item) => {
          newArr.push({
            label: item?.payer,
            value: item?.id,
            // id: item.id,
          });
        });

        state.payersForDenialByReversalFilterArr = newArr;
      } else {
        state.payersForDenialByReversalFilterArr = [];
      }

      // for  Payee filter Arr
      if (data?.Payee?.length) {
        let newArr = [];
        data?.Payee?.forEach((item) => {
          let payeeName_With_PrimaryId = `${item?.name} - ${item?.primaryId}`;

          newArr.push({
            label: payeeName_With_PrimaryId,
            value: item?.payeeId,
            // id: item.id,
          });
        });

        state.PayeeForDenialByReversalFilterArr = newArr;
      } else {
        state.PayeeForDenialByReversalFilterArr = [];
      }

      // for  ReasonCodes filter Arr
      if (data?.ReasonCodes?.length) {
        let newArr = [];
        data?.ReasonCodes?.forEach((item) => {
          let userFriendlyName = item?.description?.length
            ? `${item?.cptCode} - ${item?.description}`
            : `${item?.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            // count: item?.count,
            label: userFriendlyName,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.ReasonCodesForDenialByReversalFilterArr = descending;
      } else {
        state.ReasonCodesForDenialByReversalFilterArr = [];
      }

      // for  IPRemarkCodes filter Arr
      if (data?.IPRemarkCodes?.length) {
        let newArr = [];
        data?.IPRemarkCodes?.forEach((item) => {
          let userFriendlyName = `${item?.ipRemarkCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            value: item?.ipRemarkCode,
            label: userFriendlyName,
          });
        });

        state.IPRemarkCodesForDenialByReversalFilterArr = newArr;
      } else {
        state.IPRemarkCodesForDenialByReversalFilterArr = [];
      }

      // for  OPRemarkCodes filter Arr
      if (data?.OPRemarkCodes?.length) {
        let newArr = [];
        data?.OPRemarkCodes?.forEach((item) => {
          let userFriendlyName = `${item?.opRemarkCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            value: item?.opRemarkCode,
            label: userFriendlyName,
          });
        });

        state.OPRemarkCodesForDenialByReversalFilterArr = newArr;
      } else {
        state.OPRemarkCodesForDenialByReversalFilterArr = [];
      }
    },

    denialGroupByCpt_DataAction: (state, action) => {
      state.denialGroupByCptDataList = action.payload;
    },

    denialGroupByPayer_DataAction: (state, action) => {
      state.denialGroupByPayerDataList = action.payload;
    },

    denialGroupByReasonCode_DataAction: (state, action) => {
      state.denialGroupByReasonCodeDataList = action.payload;
    },

    denialsByServiceLineGBPayerCN_DataAction: (state, action) => {
      let data = action.payload;
      if (data?.denialClaims && data?.denialClaims?.length) {
        state.denialsByServiceLineGBPayerCN_Data.denialClaims =
          data.denialClaims;
        state.denialsByServiceLineGBPayerCN_Data.totalBilledAmount =
          data.denialClaims[0]?.totalDenialsAmount;
        state.denialsByServiceLineGBPayerCN_Data.totalPaidAmount =
          data.denialClaims[0]?.totalPaidAmount;
        state.denialsByServiceLineGBPayerCN_Data.count =
          data.denialClaims[0].totalCount;
      } else {
        state.denialsByServiceLineGBPayerCN_Data = {
          denialClaims: [],
          totalBilledAmount: null,
          totalPaidAmount: null,
          count: null,
        };
      }
    },
    // changes start here ==========================
  },
});

export const {
  denialDetails_AllDenials,
  reversalDenied_Data,
  reversalDeniedFilters_Data,
  partiallyDenied_Data,
  denialsByServiceLineGBPayerCN_DataAction,
  partiallyDeniedFilters_Data,
  denialGroupByPayer_DataAction,
  denialGroupByCpt_DataAction,
  denialGroupByReasonCode_DataAction,
  denialByServiceLine_Data,
} = denialDetailsSlice.actions;
let cacheParams = {};

export const denialsRequestCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: ["claimStatus,payers,cptCodes,CARC"],
        })
      );
    }
    if (clear === "resetBtn") {
      dispatch(
        resetListingStateForResetButton({
          listing: "denialDetailsFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),
        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),

        ...(sliceState?.reasonCode?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.reasonCode),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "All",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };
      let params = {
        start,
        limit,
        ...(sliceState?.search && { Filter: sliceState.search }),

        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),

        ...(sliceState?.reasonCode?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.reasonCode),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "All",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const payerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const reasonCodeParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      const providerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      const billingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const renderingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const drgCodeParams = {};

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        denialDetails_AllDenials,
        "denialDetailsFilter",
        "GET_ALL_DENIALS_DETAIL",
        null
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          billingProviderParams,
          renderingProviderParams,
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: true,
            isReasonCodeAPI: true,
            isProviderAPI: false,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isIPOPRemartCodeAPI: true,
            isCptCodeAPI: true,
            isGroupCodeAPI: true,
            isDrgAPI: false,
          }
        )
      );
    } catch (e) {
      // console.log("catch_e--->>", e);

      return false;
    }
  };
export const denialsRequestCallTimestamp =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: ["claimStatus,payers,cptCodes,CARC"],
        })
      );
    }
    // console.log("hey khizer");
    if (clear === "resetBtn") {
      dispatch(
        resetListingStateForResetButton({
          listing: "denialDetailsFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),
        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),

        ...(sliceState?.reasonCode?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.reasonCode),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "All",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
        timestamp: new Date().getTime(),
      };
      let params = {
        start,
        limit,
        ...(sliceState?.search && { Filter: sliceState.search }),

        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),

        ...(sliceState?.reasonCode?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.reasonCode),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "All",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
        timestamp: new Date().getTime(),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const payerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const reasonCodeParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      const providerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      const billingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const renderingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const drgCodeParams = {};

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        denialDetails_AllDenials,
        "denialDetailsFilter",
        "GET_ALL_DENIALS_DETAIL",
        null
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          billingProviderParams,
          renderingProviderParams,
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: true,
            isReasonCodeAPI: true,
            isProviderAPI: false,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isIPOPRemartCodeAPI: true,
            isCptCodeAPI: true,
            isGroupCodeAPI: true,
            isDrgAPI: false,
          }
        )
      );
    } catch (e) {
      // console.log("catch_e--->>", e);

      return false;
    }
  };

export const denialsRequestGroupByPayerCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState?.pageNumber;
    let limit = sliceState?.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceState?.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState?.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_DENIALS_DETAIL_GB_PAYER,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            hasMore: res?.data?.data?.output?.length === sliceState?.pageSize,
            loadingMore: "",
            totalRecord_DB: res?.data?.data?.count,
          },
        })
      );
      dispatch(denialGroupByPayer_DataAction(res?.data?.data?.output));
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export const denialsRequestByPayerDropdownCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: [],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = 0;
    let limit = 500;

    let selectedPayer = sliceState.selectedPayer;

    try {
      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceState?.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState?.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        // for group by view handling

        ...(selectedPayer !== "" && {
          payers: selectedPayer,
        }),
        ...{
          claimStatus: "Fully Denied",
        },
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_DENIALS_DETAIL,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data?.denialClaims;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

export const denialsRequestGroupByCptCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceState?.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState?.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_DENIALS_DETAIL_GB_CPT_CODE,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
            totalRecord_DB: res?.data?.data?.count,
          },
        })
      );
      dispatch(denialGroupByCpt_DataAction(res?.data?.data?.output));
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export const denialsRequestGroupByCptDropdownCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: [],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = 0;
    let limit = 500;

    let selectedCptCode = sliceState?.selectedCptCode;

    try {
      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceState?.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState?.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(selectedCptCode !== "" && {
          cptCodes: selectedCptCode,
        }),
        ...{
          claimStatus: "Fully Denied",
        },
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_DENIALS_DETAIL,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data?.denialClaims;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

export const denialsRequestGroupByReasonCodeCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState?.pageNumber;
    let limit = sliceState?.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceState?.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState?.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_DENIALS_DETAIL_GB_REASON_CODE,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
            totalRecord_DB: res?.data?.data?.count,
          },
        })
      );
      dispatch(denialGroupByReasonCode_DataAction(res?.data?.data?.output));
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export const denialsRequestGroupByReasonCodeDropdownCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: [],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = 0;
    let limit = 500;

    let selectedReasonCode = sliceState?.selectedReasonCode;

    try {
      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceState?.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState?.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        // for group by view handling
        ...(selectedReasonCode !== "" && {
          reasonCode: selectedReasonCode,
        }),
        ...{
          claimStatus: "Fully Denied",
        },
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_DENIALS_DETAIL,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data?.denialClaims;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };
export const denialsByServiceLineRequest_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialByServiceLineFilter",
          ignore: ["cptCodes,payers,reasonCode,search"],
        })
      );
    }
    if (clear === "resetBtn") {
      dispatch(
        resetListingStateForResetButton({
          listing: "denialByServiceLineFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    }

    // console.log("denialsByServiceLineRequest_Call--->>");

    denialsByServiceLineRequest_Call;
    let sliceState = getState().npReducers.filters.denialByServiceLineFilter;
    let sliceState2 =
      getState().npReducers.filters.denialByServiceLineGroupByPYCNFilter;

    let sliceStateUser = getState().pReducers.user;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }
    try {
      let paramsTemp = {
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),
        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.claimStatus?.length > 0 && {
          ClaimStatusCodeIds: getMultiSelectFilterValueForApi(
            sliceState?.claimStatus
          ),
        }),

        ...(sliceState?.chargeAmountCondition &&
          sliceState?.chargeAmountCondition !== "Service Line" &&
          sliceState?.chargeAmountCondition !== "=0" && {
            ChargeAmountCondition: sliceState.chargeAmountCondition,
          }),

        ...(sliceState?.chargeAmountCondition &&
          sliceState?.chargeAmountCondition === "=0" && {
            PaidAmountCondition: sliceState.chargeAmountCondition,
          }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "All",
        },
        ...(sliceState.claimViewType !== "" && {
          hardDenial: sliceState.claimViewType,
        }),

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),
        ...(sliceState?.chargeAmountCondition &&
          sliceState?.chargeAmountCondition !== "Service Line" &&
          sliceState?.chargeAmountCondition !== "=0" && {
            ChargeAmountCondition: sliceState.chargeAmountCondition,
          }),

        ...(sliceState?.chargeAmountCondition &&
          sliceState?.chargeAmountCondition === "=0" && {
            PaidAmountCondition: sliceState.chargeAmountCondition,
          }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.claimStatus?.length > 0 && {
          ClaimStatusCodeIds: getMultiSelectFilterValueForApi(
            sliceState?.claimStatus
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "All",
        },
        ...(sliceState.claimViewType !== "" && {
          hardDenial: sliceState.claimViewType,
        }),

        ...(sliceState.claimViewType !== "" &&
        sliceState.claimViewType == undefined
          ? { hardDenial: sliceState2.claimViewType }
          : {
              hardDenial: sliceState.claimViewType,
            }),

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const payerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const reasonCodeParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      const providerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      const billingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const renderingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const drgCodeParams = {};
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        denialByServiceLine_Data,
        "denialByServiceLineFilter",
        "GET_DENIALS_GROUP_BY_SERVICE_LINE",
        false,
        "",
        ""
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          billingProviderParams,
          renderingProviderParams,
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: true,
            isReasonCodeAPI: true,
            isProviderAPI: false,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isIPOPRemartCodeAPI: true,
            isCptCodeAPI: true,
            isGroupCodeAPI: true,
            isDrgAPI: false,
          }
        )
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// denials detail screen tab denial by Partially Denied
export const denialsByPartiallyDenied_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "partiallyDeniedFilter",
          ignore: ["cptCodes,payers,reasonCode,search"],
        })
      );
    }
    if (clear === "resetBtn") {
      dispatch(
        resetListingStateForResetButton({
          listing: "partiallyDeniedFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    } else {
    }

    let sliceState = getState().npReducers.filters.partiallyDeniedFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),

        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),
        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          FillingIndicatorCode: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...{
          claimStatus: "Partially Denied",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),

        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),
        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          FillingIndicatorCode: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...{
          claimStatus: "Partially Denied",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        partiallyDenied_Data,
        "partiallyDeniedFilter",
        "GET_DENIALS_GROUP_BY_SERVICE_LINE",
        true,
        "GET_DENIALS_GROUP_BY_SERVICE_LINE_FILTERS",
        partiallyDeniedFilters_Data
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// get partial denied screen filters data
export const gettingDenialsByPartialDeniedFiltersData_Call =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.partiallyDeniedFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let params = {
        start,
        limit,
        // ...(sliceState?.search && { search: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),

        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),
        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          FillingIndicatorCode: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...{
          claimStatus: "Partially Denied",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.GET_DENIALS_GROUP_BY_SERVICE_LINE_FILTERS,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(partiallyDeniedFilters_Data([]));
        dispatch(
          setListingState({
            listing: "partiallyDeniedFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(partiallyDeniedFilters_Data(res?.data?.data));
        dispatch(
          setListingState({
            listing: "partiallyDeniedFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      }

      return 0;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "partiallyDeniedFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(partiallyDeniedFilters_Data([]));

      return false;
    }
  };

// denials detail screen tab denial by Reversal Denied

export const denialsByReversalDenied_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "reversalDeniedFilter",
          ignore: ["cptCodes,payers,reasonCode,search"],
        })
      );
    }
    if (clear === "resetBtn") {
      dispatch(
        resetListingStateForResetButton({
          listing: "reversalDeniedFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    } else {
    }

    let sliceState = getState().npReducers.filters.reversalDeniedFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),

        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),
        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "Reversal Amount",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),

        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),
        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "Reversal Amount",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        reversalDenied_Data,
        "reversalDeniedFilter",
        "GET_DENIALS_GROUP_BY_SERVICE_LINE",
        true,
        "GET_DENIALS_GROUP_BY_SERVICE_LINE_FILTERS",
        reversalDeniedFilters_Data
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// get reversal denied screen filters data
export const gettingDenialsByReversalFiltersData_Call =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.reversalDeniedFilter;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let params = {
        start,
        limit,
        // ...(sliceState?.search && { search: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),

        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),
        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "Reversal Amount",
        },

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.GET_DENIALS_GROUP_BY_SERVICE_LINE_FILTERS,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(reversalDeniedFilters_Data([]));
        dispatch(
          setListingState({
            listing: "partiallyDeniedFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(reversalDeniedFilters_Data(res?.data?.data));
        dispatch(
          setListingState({
            listing: "partiallyDeniedFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      }

      return 0;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "partiallyDeniedFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(reversalDeniedFilters_Data([]));

      return false;
    }
  };

// denials By ServiceLine GB PayerCN
export const denialsByServiceLineGBPayerCN_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialByServiceLineGroupByPYCNFilter",
          ignore: ["claimStatus,payers,cptCodes,CARC"],
        })
      );
    }
    if (clear === "resetBtn") {
      dispatch(
        resetListingStateForResetButton({
          listing: "denialByServiceLineGroupByPYCNFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    }
    // console.log("denialsByServiceLineGBPayerCN_Call--->>");

    let sliceState =
      getState().npReducers.filters.denialByServiceLineGroupByPYCNFilter;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }
    try {
      let paramsTemp = {
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.chargeAmountCondition &&
          sliceState?.chargeAmountCondition !== "Claims" && {
            ChargeAmountCondition: sliceState?.chargeAmountCondition,
          }),
        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.claimStatus?.length > 0 && {
          ClaimStatusCodeIds: getMultiSelectFilterValueForApi(
            sliceState?.claimStatus
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "All",
        },
        ...(sliceState.claimViewType !== "" &&
          sliceState.claimViewType !== undefined && {
            hardDenial: sliceState.claimViewType,
          }),

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.billingProvider?.length > 0 && {
          PayeeId: getMultiSelectFilterValueForApi(sliceState?.billingProvider),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.chargeAmountCondition &&
          sliceState?.chargeAmountCondition !== "Claim Level" && {
            ChargeAmountCondition: sliceState?.chargeAmountCondition,
          }),
        ...(sliceState?.reasonCodes?.length > 0 && {
          reasonCode: getMultiSelectFilterValueForApi(sliceState?.reasonCodes),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.claimStatus?.length > 0 && {
          ClaimStatusCodeIds: getMultiSelectFilterValueForApi(
            sliceState?.claimStatus
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
            }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterValueForApi(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...{
          claimStatus: "All",
        },
        ...(sliceState.claimViewType !== "" &&
          sliceState.claimViewType !== undefined && {
            hardDenial: sliceState.claimViewType,
          }),
        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const payerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const reasonCodeParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      const providerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      const billingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const renderingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const drgCodeParams = {};
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        denialsByServiceLineGBPayerCN_DataAction,
        "denialByServiceLineGroupByPYCNFilter",
        "GET_DENIALS_GROUP_BY_SERVICE_LINE_GB_PAYER_CN",
        false,
        "",
        ""
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          billingProviderParams,
          renderingProviderParams,
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: true,
            isReasonCodeAPI: true,
            isProviderAPI: false,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isIPOPRemartCodeAPI: true,
            isCptCodeAPI: true,
            isGroupCodeAPI: true,
            isDrgAPI: false,
          }
        )
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

export default denialDetailsSlice.reducer;
