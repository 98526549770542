import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { format } from "date-fns";
import moment from "moment";
import { environment } from "../../../constants/serverRoutes";

let dateFormat = "MM/dd/yyyy";

// // Helper function to get the current month label
// const getCurrentMonthLabel = (monthIndex) => {
//   const months = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];
//   return months[monthIndex];
// };

// const getCurrentQuarter = () => {
//   const currentMonth = new Date().getMonth();
//   const currentYear = new Date().getFullYear();
//   const quarters = ["Q1", "Q2", "Q3", "Q4"];
//   const currentQuarter = quarters[Math.floor(currentMonth / 3)];
//   return `${currentQuarter}-${currentYear}`;
// };

// const getLastEightQuarters = () => {
//   const quarters = [];
//   let currentQuarter = getCurrentQuarter();

//   for (let i = 0; i < 8; i++) {
//     quarters.push({
//       label: currentQuarter,
//       value: currentQuarter,
//     });

//     const [quarter, year] = currentQuarter.split("-");
//     const currentYear = parseInt(year);
//     const currentQuarterIndex = parseInt(quarter.slice(1)) - 1;

//     if (currentQuarterIndex === 0) {
//       currentQuarter = `Q4-${currentYear - 1}`;
//     } else {
//       currentQuarter = `Q${currentQuarterIndex}-${currentYear}`;
//     }
//   }

//   return quarters;
// };

// export const initialListingState = {
//   // for handle sideNav drawer
//   openDrawer: true,
//   openDrawerChatbot: false,
//   openClaimNotes: false,
//   fetchLatest: false,
//   clientId: "",
//   cachePagination: {},
//   cacheParams: {},
//   currentPage: 1,
//   pageNumber: 1,
//   pageSize: Number(localStorage.getItem("NoOfRecordInTable")) || 10,
//   dateMainState: "Months",
//   dateForMonth: {
//     label: "Jan",
//     value: "1",
//   },
//   dateForYear: {
//     label: environment === "dev" ? "2021" : "2024",
//     value: environment === "dev" ? 2021 : 2024,
//   },
//   dateForQuarter: {
//     label: getLastEightQuarters()?.[0]?.label,
//     value: getLastEightQuarters()?.[0]?.label,
//   },
//   hasMore: true,
//   hasMoreDataOnPage: false,
//   loadingMore: "data-loading",
//   loadingFilters: "data-loading",
//   initialLoading: true,
//   search: "",
//   orderBy: "",
//   // order: "asc",
//   order: "",
//   dateValid: true,
//   dateErrorMsg: "",
//   showFilterBody: "",

//   smartFilter: true,

//   isValidDateForErrMsg: true,
//   to: format(new Date(), dateFormat), // end date
//   // from: format(new Date().setMonth(new Date().getMonth() - 1), dateFormat), // start date
//   // from: format(new Date().setDate(new Date().getDay() - 1), dateFormat), // start date
//   from: format(new Date().setDate(new Date().getDate() - 14), dateFormat), // start date

//   datePickerType: "",
//   // for denialDetailsFilter
//   serviceLineTypeonCology: false,
//   selectedClaimStatus: "All",
//   serviceLineSelClaimStatus: "All",
//   groupViewType: "Claim",

//   // It's use for all screens filter
//   pageNumberForBack: 1,

//   // for handle GroupByView on denial screens
//   groupByViewTypeForDenialScreens: ["ALL", "CPT", "Payer", "Reason Code"],
//   selGroupByViewTypeForDenialScreens: "ALL",

//   dropdownbButtons: [
//     "3M",
//     "Current Year",
//     "2021",
//     // "1Y",
//     "3Y",
//     "Q4-2021",
//     "Q3-2021",
//     "Q2-2021",
//     "Q1-2021",
//     "Q4-2020",
//     "Q3-2020",
//     "Q2-2020",
//     "Q1-2020",
//     "Q4-2019",
//     "Q3-2019",
//     "Q2-2019",
//     "Q1-2019",
//   ],

//   serviceLineTypeArr: [
//     { value: "All", label: "All Others" },
//     { value: "oncology", label: "Oncology" },
//   ],
// };

// export const initialListingState = {
//   // for handle sideNav drawer
//   openDrawer: true,
//   openDrawerChatbot: false,
//   openClaimNotes: false,
//   fetchLatest: false,
//   clientId: "",
//   cachePagination: {},
//   cacheParams: {},
//   currentPage: 1,
//   pageNumber: 1,
//   pageSize: Number(localStorage.getItem("NoOfRecordInTable")) || 10,
//   dateMainState: "Months",

//   // Set default month and year based on the environment
//   dateForMonth: {
//     label:
//       environment === "dev"
//         ? "Jan"
//         : getCurrentMonthLabel(new Date().getMonth()), // Dynamic month
//     value: environment === "dev" ? "1" : `${new Date().getMonth() + 1}`, // Dynamic month (1-based)
//   },
//   dateForYear: {
//     label: environment === "dev" ? "2021" : `${new Date().getFullYear()}`, // Current year if not dev
//     value: environment === "dev" ? 2021 : new Date().getFullYear(), // Current year if not dev
//   },
//   dateForQuarter: {
//     label: getLastEightQuarters()?.[0]?.label,
//     value: getLastEightQuarters()?.[0]?.label,
//   },
//   hasMore: true,
//   hasMoreDataOnPage: false,
//   loadingMore: "data-loading",
//   loadingFilters: "data-loading",
//   initialLoading: true,
//   search: "",
//   orderBy: "",
//   order: "",
//   dateValid: true,
//   dateErrorMsg: "",
//   showFilterBody: "",
//   smartFilter: true,
//   isValidDateForErrMsg: true,

//   // Default dates
//   to: format(new Date(), dateFormat), // End date (current date)
//   from: format(new Date().setDate(new Date().getDate() - 14), dateFormat), // Start date (14 days ago)

//   datePickerType: "",
//   serviceLineTypeonCology: false,
//   selectedClaimStatus: "All",
//   serviceLineSelClaimStatus: "All",
//   groupViewType: "Claim",

//   // It's use for all screens filter
//   pageNumberForBack: 1,

//   // for handle GroupByView on denial screens
//   groupByViewTypeForDenialScreens: ["ALL", "CPT", "Payer", "Reason Code"],
//   selGroupByViewTypeForDenialScreens: "ALL",

//   dropdownbButtons: [
//     "3M",
//     "Current Year",
//     "2021",
//     "3Y",
//     "Q4-2021",
//     "Q3-2021",
//     "Q2-2021",
//     "Q1-2021",
//     "Q4-2020",
//     "Q3-2020",
//     "Q2-2020",
//     "Q1-2020",
//     "Q4-2019",
//     "Q3-2019",
//     "Q2-2019",
//     "Q1-2019",
//   ],

//   serviceLineTypeArr: [
//     { value: "All", label: "All Others" },
//     { value: "oncology", label: "Oncology" },
//   ],
// };

// Helper function to get the current month label
const getCurrentMonthLabel = (monthIndex) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[monthIndex];
};

const getCurrentQuarter = () => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  const currentQuarter = quarters[Math.floor(currentMonth / 3)];
  return `${currentQuarter}-${currentYear}`;
};

const getLastEightQuarters = () => {
  const quarters = [];
  let currentQuarter = getCurrentQuarter();

  for (let i = 0; i < 8; i++) {
    quarters.push({
      label: currentQuarter,
      value: currentQuarter,
    });

    const [quarter, year] = currentQuarter.split("-");
    const currentYear = parseInt(year);
    const currentQuarterIndex = parseInt(quarter.slice(1)) - 1;

    if (currentQuarterIndex === 0) {
      currentQuarter = `Q4-${currentYear - 1}`;
    } else {
      currentQuarter = `Q${currentQuarterIndex}-${currentYear}`;
    }
  }

  return quarters;
};

export const initialListingState = {
  // for handle sideNav drawer
  openDrawer: true,
  openDrawerChatbot: false,
  openClaimNotes: false,
  fetchLatest: false,
  clientId: "",
  cachePagination: {},
  cacheParams: {},
  currentPage: 1,
  pageNumber: 1,
  pageSize: Number(localStorage.getItem("NoOfRecordInTable")) || 10,
  dateMainState: "Months",

  // Set default month and year based on the environment
  dateForMonth: {
    label:
      environment === "dev"
        ? "Jan"
        : getCurrentMonthLabel(new Date().getMonth() - 1), // Previous month if not dev
    value:
      environment === "dev"
        ? "1"
        : `${new Date().getMonth() === 0 ? 12 : new Date().getMonth()}`, // If current month is Jan, set December
  },
  dateForYear: {
    label: environment === "dev" ? "2021" : `${new Date().getFullYear()}`, // Current year if not dev
    value: environment === "dev" ? 2021 : new Date().getFullYear(), // Current year if not dev
  },
  dateForQuarter: {
    label: getLastEightQuarters()?.[0]?.label,
    value: getLastEightQuarters()?.[0]?.label,
  },
  hasMore: true,
  hasMoreDataOnPage: false,
  loadingMore: "data-loading",
  loadingFilters: "data-loading",
  initialLoading: true,
  search: "",
  orderBy: "",
  order: "",
  dateValid: true,
  dateErrorMsg: "",
  showFilterBody: "",
  smartFilter: true,
  isValidDateForErrMsg: true,

  // Default dates
  to: format(new Date(), dateFormat), // End date (current date)
  from: format(new Date().setDate(new Date().getDate() - 14), dateFormat), // Start date (14 days ago)

  datePickerType: "",
  serviceLineTypeonCology: false,
  selectedClaimStatus: "All",
  serviceLineSelClaimStatus: "All",
  groupViewType: "Claim",

  // It's use for all screens filter
  pageNumberForBack: 1,

  // for handle GroupByView on denial screens
  groupByViewTypeForDenialScreens: ["ALL", "CPT", "Payer", "Reason Code"],
  selGroupByViewTypeForDenialScreens: "ALL",

  dropdownbButtons: [
    "3M",
    "Current Year",
    "2021",
    "3Y",
    "Q4-2021",
    "Q3-2021",
    "Q2-2021",
    "Q1-2021",
    "Q4-2020",
    "Q3-2020",
    "Q2-2020",
    "Q1-2020",
    "Q4-2019",
    "Q3-2019",
    "Q2-2019",
    "Q1-2019",
  ],

  serviceLineTypeArr: [
    { value: "All", label: "All Others" },
    { value: "oncology", label: "Oncology" },
  ],
};

export const listingSlice = createSlice({
  name: "listings",
  initialState: {
    userPreferencesFilter: {
      ...initialListingState,
      chatbotEpandWidth: false,
      currentModule: {
        label: "Denials 360",
        value: "Denials",
      },
    },
    azureSearchFilter: {
      ...initialListingState,
    },
    claimManager: {
      ...initialListingState,
      pageNumber: 1,
      treeNodeServiceLine: [],
      claimStatus: [],
      payers: [],
      frequencyType: [],
      DiagnosisCode: [],
      cptCodes: [],
      jCodes: [],
      serviceLine: [],
      billingProviders: [],
      renderingProviders: [],
      attendingProviders: [],
      drgCodes: [],
      remarkCode: [],
      IsExceedUnit: false,
      IsIncorrectBundled: false,
      isSuggestedCPT: false,
    },
    claimProgress: {
      ...initialListingState,
      pageNumber: 1,
      treeNodeServiceLine: [],
      claimStatus: [],
      payers: [],
      frequencyType: [],
      DiagnosisCode: [],
      cptCodes: [],
      jCodes: [],
      serviceLine: [],
      billingProviders: [],
      renderingProviders: [],
      attendingProviders: [],
      remarkCode: [],
      IsExceedUnit: false,
      IsIncorrectBundled: false,
      isSuggestedCPT: false,
    },

    claimManagerServiceLineLevel: {
      ...initialListingState,
      pageNumber: 1,
    },

    claimManagerTestFilter: {
      ...initialListingState,

      pageNumber: 1,
      currentPage: 1,
      claimStatus: [],
      payers: [],
      frequencyType: [],
      DiagnosisCode: [],
      CPTCode: [],
      BillingProviderIds: [],
    },

    underPaymentFilters: {
      ...initialListingState,

      loadingFilters: "data-loading",

      pageNumber: 1,
      claimPaymentStatus: [],
      payers: [],
      facility: [],
      cptCodes: [],
      CARC: [],
      treeNodeServiceLine: [],
      serviceLine: [],
      filingIndicator: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      jCodes: [],
      billingProviders: [],
      renderingProviders: [],
      DRGs: [],

      groupReasonCode: [],
      selectedRowState: {},

      // for detail screen popover
      popOver: false,
    },

    underPaymentFilters2Level: {
      ...initialListingState,

      loadingFilters: "data-loading",

      pageNumber: 1,
    },

    claimPayment: {
      ...initialListingState,
      fetchLatest: false,
      loadingFilters: "data-loading",

      pageNumber: 1,
      claimPaymentStatus: [],
      payers: [],
      facility: [],
      cptCodes: [],
      CARC: [],
      treeNodeServiceLine: [],
      serviceLine: [],
      filingIndicator: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      jCodes: [],
      billingProviders: [],
      renderingProviders: [],
      DRGs: [],
      // these values for handle back button for getting prev condition

      groupReasonCode: [],
      selectedRowState: {},

      // for detail screen popover
      popOver: false,
    },

    claimPayment2Level: {
      ...initialListingState,

      loadingFilters: "data-loading",

      pageNumber: 1,
    },

    // =============== ClaimPayment (835) dropdown filter start here ===============
    claimPaymentPayerFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentServiceLineFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentClaimStatusFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentFacilityFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentProviderFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentFillingIndicatorFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentConfigFilter: {
      ...initialListingState,
    },
    claimPaymentBillingProviderFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentRenderingProviderFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentReasonCodeFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentIpRemarkCodeFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentCptCodeFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
    },
    claimPaymentGroupReasonCodeFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },
    claimPaymentDrgCodeFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },
    // =============== ClaimPayment (835) dropdown filter end here ===============

    // =============== ClaimsRequest (837) dropdown filter start here ===============
    claimRequestPayerFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },

    claimRequestServiceLineFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },

    claimRequestFillingIndicatorFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },

    claimRequestProviderFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },

    claimRequestFrequencyTypeFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },

    claimRequestDiagnoseCodeFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },

    claimRequestCptCodesFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },
    claimRequestDrgCodeFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },

    claimRequestBillingProviderFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },
    claimRequestRenderingProviderFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },
    claimRequestAttendingProviderFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
    },

    // =============== ClaimsRequest (837) dropdown filter end here ===============

    workQueueFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",

      pageNumber: 1,

      groupByFilter: "ALL",
      // type: "",
      assigneeUserId: "",
      selectedItem_id: "",
    },

    clientsConfigFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      selectedItem_id: null,
      fetchLatest: false,
    },

    claimCustomCodeConfigFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      selectedItem_id: null,
      fetchLatest: false,
    },
    claimCustomCodeConfigFilterDropdwon: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      selectedItem_id: null,
      fetchLatest: false,
    },

    customProceCodeFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      selectedItem_id: null,
      fetchLatest: false,
    },

    denialsConfigFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      selectedItem_id: null,
      fetchLatest: false,
    },

    workQueueViewFilter: {
      ...initialListingState,

      loadingFilters: "data-loading",
      focusFrom: false,
      focusTo: false,
    },

    workQueueBucketsFilter: {
      ...initialListingState,
    },

    appealsDetailFilterMain: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      selectedRowState: {},

      selectedItem_id: "",
      order: "desc",
      orderBy: "AppealExpiredOn",
      orderByDefault: "AppealExpiredOn",
      fetchLatest: false,
      tabulrArrayLength: 0,
    },
    appealsDetailFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      selectedRowState: {},

      selectedItem_id: "",
      order: "desc",
      orderBy: "AppealExpiredOn",
      orderByDefault: "AppealExpiredOn",
      fetchLatest: false,
      tabulrArrayLength: 0,
      lastMovedWorkQueueId: "",
    },
    myTasksFilter835: {
      ...initialListingState,
      groupBy: "",
      fetchLatest: false,
    },
    myTasksFilter837: {
      ...initialListingState,
      groupBy: "",
      fetchLatest: false,
    },
    myTasksFilter8352Level: {
      ...initialListingState,
      groupBy: "",
      fetchLatest: false,
    },
    myTasksFilter8372Level: {
      ...initialListingState,
      groupBy: "",
      fetchLatest: false,
    },

    //
    appealsQueueFilter: {
      ...initialListingState,
    },
    workQueueDetailFilterMain: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      selectedRowState: [],

      selectedItem_id: "",
      order: "desc",
      orderBy: "",
      QueueState: "",
      orderByDefault: "",
      fetchLatest: false,
      tabulrArrayLength: 0,
    },
    workQueueDetailFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      selectedRowState: [],

      selectedItem_id: "",
      order: "desc",
      orderBy: "",
      QueueState: "",
      orderByDefault: "",
      fetchLatest: false,
      tabulrArrayLength: 0,
      lastMovedWorkQueueId: "",
    },

    workQueueDetailFilter2Level: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      id: "",
    },

    workQueueLookUpFilter: {
      ...initialListingState,
      loadingFilters: "data-loading",
      pageNumber: 1,
      fetchLatest: false,
    },

    claimAckFilter: {
      ...initialListingState,

      // these values for handle back button for getting prev condition
    },

    patientDatailFilter: {
      ...initialListingState,
      patientDetailLoadingMore: "data-loading",
      timeLineLoading: "data-loading",
      id: "",
      // these values for handle back button for getting prev condition
    },

    patientDatail837Filter: {
      ...initialListingState,
      patientDetailLoadingMore: "data-loading",
      timeLineLoading: "data-loading",
      id: "",
      // these values for handle back button for getting prev condition
    },

    patientTimelineDatailFilter: {
      ...initialListingState,

      id: "",
    },

    queryGeneratorFilter: {
      ...initialListingState,
      loadingMore: "",

      runQueryFuncType: "",
      runQueryForPagination: 0,
      totalRecord_DB: 0,

      selectedColumn_835: [],
      selectedColumn_837: [],
      // for execute prevQuery
      exePrevQuery: "",
    },

    denialOverviewFilter: {
      ...initialListingState,

      // these dates use only for Denial Overview Dashboard screen
      to: format(new Date(), dateFormat), // end date
      from: format(new Date().setMonth(new Date().getMonth() - 3), dateFormat),

      selFilterType: "3Y",

      payers: [],
      newFilterPayers: [],

      loadingDenialByCpt: "",
      loadingDenialsVolume: "",
      loadingDenialsPayer: "",
      loadingClaimBySL: "",
      loadingClaimByCL: "",
      totalRecord_DB: 0,
    },

    denialDetailsFilter: {
      ...initialListingState,

      serviceLine: [],
      treeNodeServiceLine: [],
      filingIndicator: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      cptCodes: [],
      payers: [],
      groupReasonCode: [],
      reasonCode: [],
      billingProvider: [],
      billingProviders: [],
      renderingProviders: [],
      jCodes: [],
      // selectedClaimStatus: "All",

      totalRecord_DB: 0,
      // for dropdown table
      dropdown_pageNumber: 1,
      dropdown_pageSize: 10,
      dropdown_totalRecord_DB: 0,
      dropdown_LoadingMore: "",
      // for handling view
      selectedPayer: "",
      selectedReasonCode: "",
      selectedCptCode: "",

      // these values for handle back button for getting prev condition

      // these values for graph loading classes
      denialsByCptLoading: "data-loading",
      denialsByPayerLoading: "data-loading",

      // these values for handle denial by service line screen

      serviceLineSelClaimStatus: "All",
      selectedRowState: {},
    },

    denialDetails2LevelFilter: {
      ...initialListingState,
    },

    // denials screen common date filter
    denialSreensCommonFilter: {
      ...initialListingState,
    },

    // report screen common date filter
    reportSreensCommonFilter: {
      ...initialListingState,
    },

    denialByServiceLineFilter: {
      ...initialListingState,

      groupViewType: "Service Line",
      claimViewType: "all",
      treeNodeServiceLine: [],
      cptCodes: [],
      jCodes: [],
      payers: [],
      billingProvider: [],
      reasonCodes: [],
      groupReasonCode: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      serviceLine: [],
      filingIndicator: [],
      claimStatus: [],
      billingProviders: [],
      renderingProviders: [],

      chargeAmountCondition: "Service Line",
      selectedRowState: {},
    },

    denialByServiceLineGroupByPYCNFilter: {
      ...initialListingState,
      claimViewType: "all",
      cptCodes: [],
      jCodes: [],
      payers: [],
      treeNodeServiceLine: [],
      billingProvider: [],
      reasonCodes: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      serviceLine: [],
      filingIndicator: [],
      claimStatus: [],
      billingProviders: [],
      renderingProviders: [],

      chargeAmountCondition: "Claim Level",

      selectedRowState: {},
    },

    denialByServiceLineGroupByPYCNFilter2Level: {
      ...initialListingState,
    },

    logsPageFilter: {
      ...initialListingState,

      groupViewType: "Job History",
      ftpSourceId: "",
    },

    fileSuccessFailFilter: {
      ...initialListingState,
    },
    fileSuccessFailFilter1stDropdown: {
      ...initialListingState,
      loadingMore: "",
    },

    partiallyDeniedFilter: {
      ...initialListingState,

      serviceLine: [],
      payers: [],
      billingProvider: [],
      filingIndicator: [],
      claimStatus: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      reasonCodes: [],
      jCodes: [],
      cptCodes: [],
      treeNodeServiceLine: [],
    },

    reversalDeniedFilter: {
      ...initialListingState,
      treeNodeServiceLine: [],
      serviceLine: [],
      payers: [],
      billingProvider: [],
      filingIndicator: [],
      claimStatus: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      reasonCodes: [],
      jCodes: [],
      cptCodes: [],
    },

    denialsByPayerFilter: {
      ...initialListingState,
      revenueGraphLoadingMore: "data-loading",
      volumeGraphLoadingMore: "data-loading",
    },

    revenueByPayerFilter: {
      ...initialListingState,

      payers: "",
      // selectedView: "",

      groupViewType: "Payer",
    },

    // reports section screen
    denialByPayerFilter: {
      ...initialListingState,
      payers: [],
      // groupViewType: "ALL",
    },

    testFilter: {
      ...initialListingState,
    },

    drugsRejectedFilter: {
      ...initialListingState,
      code: "",
      groupViewType: "Reason Code",
      groupByForApi: "",
      treeNodeServiceLine: [],
      reasonCodes: [],
      cptCodes: [],
      payers: [],
    },

    drugsRejectedFilter2ndLevel: {
      ...initialListingState,
      pageNumber: 1,
      code: "",
      groupViewType: "Claim",
    },

    drugsRejectedGBClaimFilter: {
      ...initialListingState,
      code: "",

      groupByForApi: "",
      treeNodeServiceLine: [],
      reasonCodes: [],
      cptCodes: [],
      payers: [],
    },

    drugsRejectedGBJcodeFilter: {
      ...initialListingState,
      code: "",

      groupByForApi: "",
      treeNodeServiceLine: [],
      reasonCodes: [],
      cptCodes: [],
      payers: [],
    },

    notificationsFilter: {
      ...initialListingState,
      newNotification: false,
      newNotificationCount: 0,
      newNotificationValue: {},
    },

    notificationsTypeFilter: {
      ...initialListingState,
      notificationTypeArr: [],
    },
    newRemittanceDetail: {
      ...initialListingState,
      loadingMore: "",
      pageNumber: 1,
      order: "asc",
      orderBy: "",
    },
    new: {
      ...initialListingState,
      loadingMore: "",
    },
    notificationsFilterTable: {
      ...initialListingState,
    },

    smartInsightsDetailFilter: {
      ...initialListingState,
      totalRecord_DB: 0,
    },

    // Reports -> service line denials screen
    serviceLineDenialsReportFilter: {
      ...initialListingState,

      // for dropdown
      dropdownPageNumber: 1,
      dropdownPageSize: 10,
      payers: [],
      cptCodes: [],
      reasonCode: [],
      treeNodeServiceLine: [],
    },
    serviceLineDenialsReportFilter2Level: {
      ...initialListingState,

      // for dropdown
      dropdownPageNumber: 1,
      dropdownPageSize: 10,
      payers: "",
      cptCodes: "",
      reasonCode: "",
    },
    // Setting -> activityLog screen
    activityLogFilter: {
      ...initialListingState,
    },
    // Setting -> advance setting screen
    advanceSettingFilter: {
      ...initialListingState,
      ClaimStatusCodeIds: [],
      ServiceDenialReasonCodeIds: [],
      selWriteOffDays: 30,
    },

    // Setting -> dataSource
    dataSourceFilter: {
      ...initialListingState,
      selectedItem_id: "",

      updateLoading: "",

      // loading for ErrorLogs
      errorLogsLoadingMore: "",
      errorLogspageNumber: 1,
      errorLogspageSize: 10,

      // loading for ActivityLogs
      activityLogsLoadingMore: "",
      activityLogspageNumber: 1,
      activityLogspageSize: 10,
    },

    // Setting -> dataSource -> activityLogs
    dataSource_ActivityLogsFilter: {
      ...initialListingState,
    },

    // Setting -> dataSource -> errorLogs
    dataSource_ErrorLogsFilter: {
      ...initialListingState,
    },

    // Setting -> dataSource -> errorLogs
    applicationUsersFilter: {
      ...initialListingState,
      selectedItem_id: "",
      order: "asc",
      orderBy: "displayName",
      search: "",
    },

    // new DenialsByCptScreen
    denialsByCptScreenFilter: {
      ...initialListingState,

      ServiceBilledAmount: "",
      treeNodeServiceLine: [],

      payers: [],
      filingIndicator: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      // reasonCodes: [],
      jCodes: [],
      cptCodes: [],
    },

    // drug acceptance rate filter
    drugAccRateFilter: {
      ...initialListingState,
      serviceLine: [{ value: "oncology", label: "Oncology" }],
      ServiceBilledAmount: "",
      payers: [],
      filingIndicator: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      // reasonCodes: [],
      jCodes: [],
      cptCodes: [],

      treeNodeServiceLine: [],

      // drug acceptance rate detail screen
      selJcode: "",
    },
    serviceLineDrugAccRateFilter: {
      ...initialListingState,
      serviceLine: [{ value: "oncology", label: "Oncology" }],
      treeNodeServiceLine: [],
      ServiceBilledAmount: "",
      payers: [],
      filingIndicator: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      // reasonCodes: [],
      jCodes: [],
      cptCodes: [],

      // drug acceptance rate detail screen
      selJcode: "",
    },
    // drug acceptance rate filter
    drugAccRateFilterDetail: {
      ...initialListingState,
      serviceLine: [{ value: "oncology", label: "Oncology" }],
      ServiceBilledAmount: "",
      payers: [],
      filingIndicator: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      // reasonCodes: [],
      jCodes: [],
      cptCodes: [],

      // drug acceptance rate detail screen
      selJcode: "",
    },
    // drug acceptance rate filter
    drugAccRateFilterDetailSecondPass: {
      ...initialListingState,
      serviceLine: [{ value: "oncology", label: "Oncology" }],
      ServiceBilledAmount: "",
      payers: [],
      filingIndicator: [],
      IPRemarkCodes: [],
      OPRemarkCodes: [],
      // reasonCodes: [],
      jCodes: [],
      cptCodes: [],

      // drug acceptance rate detail screen
      selJcode: "",
    },

    //  drugAccRateand recurring denials common date filter
    drugAccRateCommonFilter: {
      ...initialListingState,
    },
    //recurring denial screen filter
    recurringDenialScreenFilter: {
      ...initialListingState,
    },

    // exective dashboard filters
    exectiveDashboardFilter: {
      ...initialListingState,

      // these dates use only for Exective Dashboard screen
      to: format(new Date(), dateFormat), // end date
      from: format(new Date().setMonth(new Date().getMonth() - 3), dateFormat),
    },

    // provider dashboard filters
    providerDashboardFilter: {
      ...initialListingState,

      selFilterType: "2021",
      ProviderName: [],
      billingProviders: [],
      renderingProviders: [],

      // these dates use only for provider Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
    },

    // denial By Facility filters
    denialByFacilityDashFilter: {
      ...initialListingState,

      loadingMoreForTable: "data-loading",

      facilityName: [],
      selFilterType: "2021",

      // these dates use only for oncology Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(3, "years").format("MM/DD/YYYY"), // start date
    },

    // denial By Facility filters
    denialByFacilityDashFilterForTable: {
      ...initialListingState,
    },

    // denial By Facility filters
    denialByFacilityDashFilterForTable2Dropdown: {
      ...initialListingState,
    },

    // oncology dashboard filters
    oncologyDashboardFilter: {
      ...initialListingState,
      loading1: "data-loading",
      loading2: "data-loading",
      loading3: "data-loading",
      loading4: "data-loading",
      loading5: "data-loading",
      loading6: "data-loading",
      loading7: "data-loading",
      loading8: "data-loading",
      loading9: "data-loading",
      loading10: "data-loading",
      barChartJcodeLoading: "data-loading",
      barChartAnLoading: "data-loading",
      barChartPreLoading: "data-loading",
      barChartAdminLoading: "data-loading",
      loadingFilters: "data-loading",

      selFilterType: "2021",
      activeTab: "All",
      groupBy: "CARC",

      // these dates use only for oncology Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
      //filters
      cancerFacility: [],
      payers: [],

      oncologyType: false,
      smartFilter: true,

      smartFilterArr: [],
    },

    // appeals dashboard filters
    appealsDashboardFilter: {
      ...initialListingState,
      loading1: "data-loading",
      loading2: "data-loading",
      loading3: "data-loading",
      barChartJcodeLoading: "data-loading",
      barChartAnLoading: "data-loading",
      barChartPreLoading: "data-loading",
      barChartAdminLoading: "data-loading",
      loadingFilters: "data-loading",

      selFilterType: "2021",
      activeTab: "All",
      groupBy: "CARC",

      // these dates use only for oncology Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
      //filters
      cancerFacility: [],

      oncologyType: false,
      smartFilter: true,

      smartFilterArr: [],
    },

    appealsAttachmentFilter: {
      ...initialListingState,
    },

    // oncology denial dashboard filters
    oncologyDenialDashboardFilter: {
      ...initialListingState,
      loading1: "data-loading",
      loading2: "data-loading",
      loading3: "data-loading",
      loadingFilters: "data-loading",

      selFilterType: "2021",
      activeTab: "All",
      groupBy: "CARC",

      // these dates use only for oncology Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
      //filters
      cancerFacility: [],
      payers: [],

      oncologyType: false,
      smartFilter: true,

      smartFilterArr: [],
    },
    // oncology denial dashboard CptJCode filters
    oncologyDenialDashboardCptJCodeFilter: {
      ...initialListingState,
    },
    oncologyDenialDashboardCptAncillaryFilter: {
      ...initialListingState,
    },
    oncologyDenialDashboardCptPreMedFilter: {
      ...initialListingState,
    },
    oncologyDenialDashboardCptAllCodeFilter: {
      ...initialListingState,
    },
    oncologyDenialDashboardCptAdminCodeFilter: {
      ...initialListingState,
    },
    oncologyDenialDashboardCarcAllCodFilter: {
      ...initialListingState,
    },
    oncologyDenialDashboardCarcJCodFilter: {
      ...initialListingState,
    },
    oncologyDenialDashboardCarcAncillaryFilter: {
      ...initialListingState,
    },
    oncologyDenialDashboardCarcPreMedFilter: {
      ...initialListingState,
    },
    oncologyDenialDashboardCarcAdminCodeFilter: {
      ...initialListingState,
    },

    landingPageFilter: {
      ...initialListingState,

      dropdownbButtons: ["3M", "2021", "3Y", "Q4-2021"],

      loading1: "data-loading",
      loading2: "data-loading",
      loading3: "data-loading",

      selFilterType: "2021",

      // these dates use only for oncology Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
      //filters

      loadingFilters: "",
    },
    landingPageFilterExecutive: {
      ...initialListingState,

      dropdownbButtons: ["3M", "2021", "3Y", "Q4-2021"],

      loading1: "data-loading",
      loading2: "data-loading",
      loading3: "data-loading",

      selFilterType: "2021",

      // these dates use only for oncology Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
      //filters

      loadingFilters: "",
    },
    landingPageNewHomeFilter: {
      ...initialListingState,

      dropdownbButtons: ["3M", "2021", "3Y", "Q4-2021"],

      loading1: "data-loading",
      loading2: "data-loading",
      loading3: "data-loading",

      selFilterType: "2021",

      // these dates use only for oncology Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
      //filters

      loadingFilters: "",
      //filters
      selHospitalsArr: [],
    },
    appealPageFilter: {
      ...initialListingState,

      dropdownbButtons: ["3M", "2021", "3Y", "Q4-2021"],

      loading1: "data-loading",
      loading2: "data-loading",
      loading3: "data-loading",

      selFilterType: "2021",

      // these dates use only for oncology Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
      //filters

      loadingFilters: "",
    },

    // carcTrends dashboard filters
    carcTrendsDashboardFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      treeNodeServiceLine: [],
      selFilterType: "2021",

      // these dates use only for carcTrends Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      oncologyType: false,

      //filters
      ReasonCode: [],
      Category: [],

      // smart filter
      smartFilter: false,
      smartFilterArr: [],
    },

    // reasonCode dashboard filters
    reasonCodeDashboardFilter: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      payersFilter: [],
      groupReasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: true,
      smartFilterArr: [],
    },

    // CarcDashboard filters
    CarcDashboardFilter: {
      ...initialListingState,
      treeNodeServiceLine: [],
      // reasonCodeFilter: [],
      // payersFilter: [],
      // groupReasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: true,
      smartFilterArr: [],

      carcFilters: [],
      payerFilter: [],
      cptFilters: [],
      rarcFilters: [],
    },
    // departments dashboard filters
    departmentDashboardFilter: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      payersFilter: [],
      groupReasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: true,
      smartFilterArr: [],
    },
    departmentDashboardFilterTopCards: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      payersFilter: [],
      groupReasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: true,
      smartFilterArr: [],
    },
    departmentDashboardFilterTopFive: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      payersFilter: [],
      groupReasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: true,
      smartFilterArr: [],
    },
    departmentDashboardFilterTopTen: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      payersFilter: [],
      groupReasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: true,
      smartFilterArr: [],
    },
    // reasonCode dashboard filters
    carcDistributionAnalysis: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      payersFilter: [],
      groupReasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: true,
      smartFilterArr: [],
    },
    carcDenialOvervView: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      payersFilter: [],
      groupReasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: true,
      smartFilterArr: [],
    },
    reasonCodeDashboardBarChartFilterSoft: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: false,
      smartFilterArr: [],
    },
    reasonCodeDashboardBarChartFilterHard: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: false,
      smartFilterArr: [],
    },
    reasonCodeDashboardDenialByReasonCode: {
      ...initialListingState,
      treeNodeServiceLine: [],
      reasonCodeFilter: [],
      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      // smart filter
      smartFilter: false,
      smartFilterArr: [],
    },

    goalTrackingFilter: {
      ...initialListingState,

      loadingMore2: "data-loading",
      loadingMore3: "data-loading",
      loadingMore4: "data-loading",
      oncologyType: false,

      //filters
      fetchLatest: false,
    },
    procedureCodeDashFilter: {
      ...initialListingState,
      procCode: [],
      smartfilter: [],
      groupViewType: "Payer",
      proceureCodes: [],
      treeNodeServiceLine: [],
      selFilterType: "2021",
    },
    denialDistributionDashFilter: {
      ...initialListingState,
      procCode: [],
      smartfilter: [],
      groupViewType: "Payer",
      proceureCodes: [],
      treeNodeServiceLine: [],
      selFilterType: "2021",
    },
    payerDenialDashFilter: {
      ...initialListingState,
      procCode: [],
      smartfilter: [],
      groupViewType: "Payer",
      proceureCodes: [],
      treeNodeServiceLine: [],
      selFilterType: "2021",
    },

    denialPerByPayerFilter: {
      ...initialListingState,
      procCode: [],
      smartfilter: [],
      groupViewType: "Payer",
      proceureCodes: [],
      treeNodeServiceLine: [],
      selFilterType: "2021",
    },

    procedureCodeDashFilterPayer: {
      ...initialListingState,
    },
    procedureCodeDashFilterProvider: {
      ...initialListingState,
    },
    procedureCodeDashFilterFacilityCode: {
      ...initialListingState,
    },
    procedureCodeDashFilterFacilityType: {
      ...initialListingState,
    },

    //Login activity
    loginActivityFilter: {
      ...initialListingState,

      //filters
    },

    //files Integration
    filesIntegrationFilter: {
      ...initialListingState,

      viewFilter: "",
    },

    goalTrackingHistoryFilter: {
      ...initialListingState,

      //filters
    },

    carcTrendsDashboardDetailFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      selFilterType: "2021",

      // these dates use only for carcTrends Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      //filters
      ReasonCode: [],
      groupReasonCode: [],
      Category: [],
      treeNodeServiceLine: [],
    },

    // insights dashboard screens filters
    insightsSreensCommonFilter: {
      ...initialListingState,

      // dateMainState: "Months",
      // dateForMonth: {
      //   label: "Jan",
      //   value: "1",
      // },
      // dateForYear: {
      //   label: environment === "dev" ? "2021" : "2024",
      //   value: environment === "dev" ? 2021 : 2024,
      // },
      // dateForQuarter: {
      //   label: getLastEightQuarters()?.[0]?.label,
      //   value: getLastEightQuarters()?.[0]?.label,
      // },

      selFilterType: "2021",
      smartFilterArr: [],
      smartFilter: true,

      // these dates use only for claim Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
    },

    // claim dashboard filters
    claimDashboardFilter: {
      ...initialListingState,

      loadingCards: "data-loading",
      loadingCards2: "data-loading",
      loadingCards3: "data-loading",

      loadingForBarChart: "data-loading",

      loadingMoreForNormalCalculation: "data-loading",

      selFilterType: "2021",

      smartFilterArr: [],
      smartFilter: true,

      // these dates use only for claim Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
    },

    // Executive dashboard filters
    executivePayersDashboardFilter: {
      ...initialListingState,

      selFilterType: "2021",

      // these dates use only for claim Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
    },

    // UploadClaim screen filter
    UploadClaimFilter: {
      ...initialListingState,

      selectedOption: "Claim Upload",
    },

    // reasonCodeByPayer dashboard filters
    reasonCodeTrendsbyPayerFilters: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "YOY",
        "2021",
        // "1Y",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],
      selFilterType: "2021",
      // these dates use only for reasonCode Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date

      smartFilterArr: [],
      smartFilter: true,
      loadingFilters: "",

      // for payers filter array
      payers: [],
      groupReasonCodes: [],
    },

    // alpha health
    mainOrgDashboardFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      smartFilterArr: [],
      smartFilter: true,
      loadingFilters: "",
      selFilterType: "2021",
      // for payers filter array
      payers: [],
    },
    //
    // carcTrends dashboard filters
    payerDashboardFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      smartFilterArr: [],
      smartFilter: true,
      loadingFilters: "",
      selFilterType: "2021",
      // for payers filter array
      payers: [],
    },

    payerDashDataFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      smartFilterArr: [],
      smartFilter: true,
      loadingFilters: "",
      selFilterType: "2021",
      // for payers filter array
      payers: [],
    },
    payerDashboardTopTenFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      smartFilterArr: [],
      smartFilter: true,
      loadingFilters: "",
      selFilterType: "2021",
      // for payers filter array
      payers: [],
    },
    payerDashboardTopCardsFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      smartFilterArr: [],
      smartFilter: true,
      loadingFilters: "",
      selFilterType: "2021",
      // for payers filter array
      payers: [],
    },
    payerDashboardFilter2: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2021",
        "Q3-2021",
        "Q2-2021",
        "Q1-2021",
        "Q4-2020",
        "Q3-2020",
        "Q2-2020",
        "Q1-2020",
        "Q4-2019",
        "Q3-2019",
        "Q2-2019",
        "Q1-2019",
      ],

      smartFilterArr: [],
      smartFilter: true,
      loadingFilters: "",
      selFilterType: "2021",
      // for payers filter array
      payers: [],
    },

    // drug denial detail filters
    drugDenialDetailFilter: {
      ...initialListingState,

      selFilterType: "3Y",
      selCode: "",

      // these dates use only for drug denial detail screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(3, "years").format("MM/DD/YYYY"), // start date
    },

    // drug denial detail filters 2 level
    drugDenialDetailFilter2Level: {
      ...initialListingState,

      selFilterType: "3Y",
      selCode: "",
      isMLapi: false,

      id: "1da836f0-7e35-4b7e-923a-10032b307275",

      // these dates use only for drug denial detail screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(3, "years").format("MM/DD/YYYY"), // start date
    },

    // denialByPayers dashboard filters
    denialByPayersDashboardFilter: {
      ...initialListingState,

      selFilterType: "2021",
      payers: [],

      // these dates use only for oncology Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(3, "years").format("MM/DD/YYYY"), // start date
    },
    askZoeFilter: {
      ...initialListingState,
      showTableColumnFilter: "",
      newChat: false,
    },
    // exective dashboard filters
    denialsDashboardFilter: {
      ...initialListingState,

      selFilterType: "2021",
      // these dates use only for Exective Dashboard screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
    },

    //  mapBox filters
    mapBoxFilter: {
      ...initialListingState,
      selFilterType: "2021",

      // these dates use only for  mapBox screen
      to: moment().format("MM/DD/YYYY"), // end date
      from: moment().subtract(1, "years").format("MM/DD/YYYY"), // start date
    },

    //  health system filters
    healthSystemFilter: {
      ...initialListingState,
      fetchLatest: false,
    },

    // duration Wise Bucketfilters
    claimAgingReportFilter: {
      ...initialListingState,
      fromClaim: "181",
      toClaim: "280",
      selDurationButton: "Write Off Claims",
      pageNumber: 1,
      payers: [],
      filingIndicators: [],
      billingProviders: [],
      frequencyType: [],
      diagnosisCode: [],
      cptCodes: [],
      fromDate: "2021-09-21",
      toDate: "2022-09-21",
      to: "2022-09-21",
      from: "2021-09-21",
      billingProviders: [],
      attendingProviders: [],
      renderingProviders: [],
    },

    // details by PTCN filter
    detailsByPTCNFilter: {
      ...initialListingState,

      loading1: "data-loading",
      loading2: "data-loading",
      loading3: "data-loading",

      selPTCN: [],
    },
    //Revenue Heatmap Filter
    revenueHeatMapFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],
      groupBy: "All",
      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      claimStatusIds: [
        // {
        //   value: "1",
        //   label: "",
        // },
        // {
        //   value: "5",
        //   label: "",
        // },
      ],
      payerFilter: [],
      selHospitalsArr: [],
      smartFilter: true,
      claimStatusFilter: false,

      selFacilitiesArr: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
    },
    aiDashboardFilter: {
      ...initialListingState,
      selHospitalsArr: [],
    },
    appealConfigurationFilter: {
      ...initialListingState,
    },
    appealConfigurationFilterPayer: {
      ...initialListingState,
    },
    appealConfigurationFilterDropDown: {
      ...initialListingState,
    },

    alertDashboardFilter: {
      ...initialListingState,
    },
    alertDashboardDetailsFilter: {
      ...initialListingState,
    },
    inboxDashboardFilter: {
      ...initialListingState,
      order: "desc",
      orderBy: "createdOn",
      isReadArr: [],
    },
    archiveDashboardFilter: {
      ...initialListingState,
    },
    revenueHeatMapFilterAll: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],
      groupBy: "All",
      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      claimStatusIds: [
        // {
        //   value: "1",
        //   label: "",
        // },
        // {
        //   value: "5",
        //   label: "",
        // },
      ],
      selHospitalsArr: [],
      smartFilter: true,
      claimStatusFilter: false,

      selFacilitiesArr: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
    },
    revenueHeatMapFilterSub: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],
      groupBy: "All",
      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      claimStatusIds: [],
      selHospitalsArr: [],
      smartFilter: true,
      claimStatusFilter: false,

      selFacilitiesArr: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
    },

    //RARC Dashboard
    RARCFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],

      claimStatusIds: [],
      payerFilter: [],
      selHospitalsArr: [],
      smartFilter: true,
      claimStatusFilter: false,
      payerFilter: [],
      selFacilitiesArr: [],
      cptCodeFilter: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
      groupByRemarkCodeFilter: "",
    },
    //Payer Dashboard
    PayerCPTFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],

      claimStatusIds: [
        // {
        //   value: "1",
        //   label: "",
        // },
        // {
        //   value: "5",
        //   label: "",
        // },
      ],
      payerFilter: [],
      selHospitalsArr: [],
      smartFilter: true,
      claimStatusFilter: false,
      payerFilter: [],
      selFacilitiesArr: [],
      cptCodeFilter: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
      groupByRemarkCodeFilter: "",
    },
    CARCFilter: {
      ...initialListingState,
    },
    PayerCPTDashFilter: {
      ...initialListingState,
    },
    SAMLFilter: {
      ...initialListingState,
    },
    UploadPolicyFilter: {
      ...initialListingState,
      orderBy: "createdOn",
      order: "desc",
      search: "",
    },
    CustomCopilotFilter: {
      ...initialListingState,
    },
    PolicyCopilotFilter: {
      ...initialListingState,
    },
    UploadUrlFilter: {
      ...initialListingState,
      orderBy: "createdOn",
      order: "desc",
      search: "",
    },
    PolicyCopilotFilter: {
      ...initialListingState,
    },
    policyFilterMain: {
      ...initialListingState,
    },
    eligibilityFilterMain: {
      ...initialListingState,
    },

    EligibilityCopilotFilter: {
      ...initialListingState,
    },
    // departmentByCarcDashboardFilter
    departmentByCarcDashboardFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      claimStatusIds: [
        // {
        //   value: "1",
        //   label: "",
        // },
        // {
        //   value: "5",
        //   label: "",
        // },
      ],
      selHospitalsArr: [],
      smartFilter: true,
      claimStatusFilter: false,
      payerFilter: [],
      selFacilitiesArr: [],
      cptCodeFilter: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
      groupByRemarkCodeFilter: "",
    },

    // for getting BucketDropdown835 data
    getBucketDropdownData835Filter: {
      ...initialListingState,
    },

    // departmentByCptDashboardFilter
    departmentByCptDashboardFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      claimStatusIds: [
        // {
        //   value: "1",
        //   label: "",
        // },
        // {
        //   value: "5",
        //   label: "",
        // },
      ],
      selHospitalsArr: [],
      smartFilter: true,
      claimStatusFilter: false,
      payerFilter: [],
      selFacilitiesArr: [],
      cptCodeFilter: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
      groupByRemarkCodeFilter: "",
    },
    // departmentByRarcDashboardFilter
    departmentByRarcDashboardFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      claimStatusIds: [
        // {
        //   value: "1",
        //   label: "",
        // },
        // {
        //   value: "5",
        //   label: "",
        // },
      ],
      selHospitalsArr: [],
      smartFilter: true,
      claimStatusFilter: false,
      payerFilter: [],
      selFacilitiesArr: [],
      cptCodeFilter: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
      groupByRemarkCodeFilter: "",
    },
    // insightReasonCodeHeatMap filter
    insightReasonCodeHeatMapFilter: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      claimStatusIds: [
        // {
        //   value: "1",
        //   label: "",
        // },
        // {
        //   value: "5",
        //   label: "",
        // },
      ],
      selHospitalsArr: [],
      smartFilter: true,
      claimStatusFilter: false,
      payers: [],
      selFacilitiesArr: [],
      cptCodeFilter: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
      groupByRemarkCodeFilter: "",
    },
    insightReasonCodeHeatMapFilterAll: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      selHospitalsArr: [],
      smartFilter: true,
      selFacilitiesArr: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
    },
    insightReasonCodeHeatMapFilterRemarkCode: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      selHospitalsArr: [],
      smartFilter: true,
      selFacilitiesArr: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
    },
    insightReasonCodeHeatMapFilterAllMissedOpp: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      selHospitalsArr: [],
      smartFilter: true,
      selFacilitiesArr: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
    },
    insightReasonCodeHeatMapFilterCategory: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      selHospitalsArr: [],
      smartFilter: true,
      selFacilitiesArr: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
    },
    insightReasonCodeHeatMapFilterDepartment: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      selHospitalsArr: [],
      smartFilter: true,
      selFacilitiesArr: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
    },
    insightReasonCodeHeatMapFilterGroupCode: {
      ...initialListingState,

      dropdownbButtons: [
        "3M",
        "Current Year",
        "2021",
        "3Y",
        "Q4-2020",
        "Q4-2019",
        "Custom",
      ],

      selFilterType: "2021",
      loadingSmartFilter: "data-loading",

      smartFilterArr: [],
      selHospitalsArr: [],
      smartFilter: true,
      selFacilitiesArr: [],

      selHspHeatMapFilter: "All",
      selDeptHeatMapFilter: [],
      groupByHeatMapFilter: "ReasonCode",
      groupByCARCFilter: "All (Actual)",
      groupByCPTFilter: "All (Actual)",
    },
  },

  reducers: {
    setListingState: (state, action) => {
      let { payload } = action;

      state[payload.listing] = {
        ...state[payload.listing],
        ...payload.value,
      };
    },

    setInitialPageSize: (state, action) => {
      const { payload } = action;
      if (payload) {
        Object.keys(state).map((item) => {
          state[item] = {
            ...state[item],
            pageSize: payload,
          };
        });
      }
    },

    resetListingState: (state, action) => {
      let { payload } = action;
      var newState = _.cloneDeep(initialListingState);

      if (payload.ignore) {
        for (let key of payload.ignore) {
          delete state[payload.listing][key];
        }
      }

      state[payload.listing] = {
        //...state[payload.listing],
        // =========== oldCode start here only one line ===========
        // ...newState,
        // =========== oldCode end here only one line ===========

        //==================================================================================

        // =========== newCode start here ===========
        cachePagination: {},
        cacheParams: {},

        pageNumber: 1,
        currentPage: 1,
        pageSize: Number(localStorage.getItem("NoOfRecordInTable")) || 10,
        hasMore: true,
        loadingMore: "data-loading",
        initialLoading: true,
        search: state[payload.listing]["search"],
        orderBy: "",
        // order: "asc",
        order: "",
        dateValid: true,
        dateErrorMsg: "",
        isValidDateForErrMsg: true,
        to: state[payload.listing]["to"], // end date
        from: state[payload.listing]["from"], // start date
        dateMainState: state[payload.listing]["dateMainState"],
        dateForMonth: state[payload.listing]["dateForMonth"],
        dateForYear: state[payload.listing]["dateForYear"],
        dateForQuarter: state[payload.listing]["dateForQuarter"],
        datePickerType: state[payload.listing]["datePickerType"],
        showFilterBody: state[payload.listing]["showFilterBody"],

        fetchLatest: state[payload.listing]["fetchLatest"],
        clientId: state[payload.listing]["clientId"],

        // for denialDetailsFilter
        serviceLineTypeonCology: false,
        selectedClaimStatus: "All",
        serviceLineSelClaimStatus: "All",

        // It's use for all screens filter
        pageNumberForBack: 1,

        // for handle GroupByView on denial screens
        groupByViewTypeForDenialScreens: ["ALL", "CPT", "Payer", "Reason Code"],
        selGroupByViewTypeForDenialScreens: "ALL",
        groupViewType: state[payload.listing]["groupViewType"],

        // for denialByPayer Dashboard screen filter
        selFilterType: state[payload.listing]["selFilterType"],

        dropdownbButtons: state[payload.listing]["dropdownbButtons"],

        // for claimAgingReportFilter handling
        fromDate: state[payload.listing]["fromDate"],
        toDate: state[payload.listing]["toDate"],

        serviceLineTypeArr: [
          { value: "All", label: "All Others" },
          { value: "oncology", label: "Oncology" },
        ],

        // it's for oncologyDashboardFilter
        loadingFilters: state[payload.listing]["loadingFilters"],
        activeTab: state[payload.listing]["activeTab"],
        groupBy: state[payload.listing]["groupBy"],
        smartFilter: state[payload.listing]["smartFilter"],

        // reason code heatmap
        selHspHeatMapFilter: state[payload.listing]["selHospital"],
        // selDeptHeatMapFilter: state[payload.listing]["selDeptHeatMapFilter"],
        groupByHeatMapFilter: state[payload.listing]["groupByHeatMapFilter"],

        // smartFilterArr: state[payload.listing]["smartFilterArr"],
        smartFilterArr: [],

        groupByHeatMapFilter: "ReasonCode",
        groupByCARCFilter: "All (Actual)",
        groupByCPTFilter: "All (Actual)",
        // selFacilitiesArr: state[payload.listing]["selFacilitiesArr"],

        // Claim Adjustments screen filter
        hardDenial: state[payload.listing]["hardDenial"],
        chargeAmountCondition: state[payload.listing]["chargeAmountCondition"],
        claimViewType: state[payload.listing]["claimViewType"],

        // denial by reports
        groupByForApi: state[payload.listing]["groupByForApi"],
        selectedRowState: state[payload.listing]["selectedRowState"],

        // =========== newCode end here ===========
      };
    },

    resetListingStateForResetButton: (state, action) => {
      let { payload } = action;
      var newState = _.cloneDeep(initialListingState);

      if (payload.ignore) {
        for (let key of payload.ignore) {
          delete state[payload.listing][key];
        }
      }
      state[payload.listing] = {
        //...state[payload.listing],
        // =========== oldCode start here only one line ===========
        ...newState,

        // Claim Adjustments screen filter
        hardDenial: state[payload.listing]["hardDenial"],
        groupViewType: state[payload.listing]["groupViewType"],

        // =========== oldCode end here only one line ===========

        //==================================================================================

        // =========== newCode start here ===========
        // pageNumber: 1,
        // pageSize: Number(localStorage.getItem("NoOfRecordInTable")) || 10,
        // hasMore: true,
        // loadingMore: "data-loading",
        // initialLoading: true,
        // search: "",
        // orderBy: "",
        // // order: "asc",
        // order: "",
        // to: state[payload.listing]["to"], // end date
        // from: state[payload.listing]["from"], // start date
        // =========== newCode end here ===========
      };
    },
  },
});

export const {
  setInitialPageSize,
  setListingState,
  resetListingState,
  resetListingStateForResetButton,
} = listingSlice.actions;

export default listingSlice.reducer;
