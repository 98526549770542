import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import {
  getTotalDenialAmt,
  getDescArray,
  getAscArray,
  getRandom_rgba,
  getMultiSelectFilterValueForApi,
  commonPagination,
  roundToTwo,
  commonPaginationWithLoading,
  getShortNames,
  getLegendsExtraShortName,
  getFilterValuesByKey,
  getCurrentClientId,
} from "../../../components/common/Utils/utils";
import store from "../../store";

export const OncologyDashboardKpisSlice = createSlice({
  name: "OncologyDashboardKpisSlice",
  initialState: {
    oncologyDashboardAncillaryCard: {},
    oncologyDashboardCardsLoading: false,
    barChartJcodeLoading: "",
    All_LinechartLoading: "",
    barChartAncillaryLoading: "",
    lineChartJcodeLoading: "",
    barChartPreMedLoading: "",
    barChartAdminLoading: "",
    lineChartAncillaryLoading: "",
    lineChartPreMedLoading: "",
    lineChartAdminLoading: "",
    oncologyDashboardAdminCodeCard: {},
    oncologyDashboardJCodeCard: {},
    oncologyDashboardPremedCard: {},
    oncologyDashboardTotalCard: {},
    oncologyDashboardCardsArr: [],

    oncologyDashboardAcillaryAmountArr: [],
    oncologyDashboardAcillaryCountArr: [],

    oncologyDashboardAdminAmountArr: [],
    oncologyDashboardAdminCountArr: [],

    oncologyDashboardJCodeAmountArr: [],
    oncologyDashboardJCodeCountArr: [],

    oncologyDashboardPremedAmountArr: [],
    oncologyDashboardPremedCountArr: [],

    oncologyDashboardCPTDenialCodeArr: [],
    oncologyDashboardCPTDenialAmountArr: [],

    // oncology denials Line chart
    oncologyDashboardJCodeTimelinePaymentAmountArr: [],

    oncologyDashboardAllCodeTimelineAmountArr: [],
    oncologyDashboardAllCodeTimelineAmountArr1: [],
    oncologyDashboardAllCodeTimelineCountArr: [],
    oncologyDashboardJCodeTimelineAmountArr: [],
    oncologyDashboardJCodeTimelineAmountArr1: [],
    oncologyDashboardJCodeTimelineCountArr: [],

    oncologyDashboardAcillaryTimelinePaymentAmountArr: [],
    oncologyDashboardAcillaryTimelineAmountArr: [],
    oncologyDashboardAcillaryTimelineAmountArr1: [],
    oncologyDashboardAcillaryTimelineCountArr: [],

    oncologyDashboardAdminCodeTimelinePaymentAmountArr: [],
    oncologyDashboardAdminCodeTimelineAmountArr: [],
    oncologyDashboardAdminCodeTimelineAmountArr1: [],
    oncologyDashboardAdminCodeTimelineCountArr: [],

    oncologyDashboardPremedTimelinePaymentAmountArr: [],
    oncologyDashboardPremedTimelineAmountArr: [],
    oncologyDashboardPremedTimelineAmountArr1: [],
    oncologyDashboardPremedTimelineCountArr: [],

    oncologyDashboardAllReasonArr: [],
    oncologyDenialAllReasonArr: [],
    oncologyDashboardJCodeReasonArr: [],
    oncologyDashboardPremedReasonArr: [],
    oncologyDashboardAdminCodeReasonArr: [],
    oncologyDashboardAcillaryReasonArr: [],

    // top reasonCode by CPT
    oncologyAdminCodeReasonCPTArr: [],
    oncologyAncillaryReasonCPTArr: [],
    oncologyDashboardAllReasonCPTArr: [],
    oncologyJCodeReasonCPTArr: [],
    oncologyPremedReasonCPTArr: [],
    serviceLineFilterArr: [],
    loading: true,
    // below code for new logic for filters on Oncology screen

    cancerFacilityFilterArr: [],
    payerFilterArr: [],

    // oncologyDenial filters arr
    smartFiltersForFilterArr: [],

    // appeal Dashboard
    appealDashboardTopCards: {},
    appealDashboardLineChart: [],
    appealDashboardBarChart: {
      barChartCountArr: [],
      barChartAmountArr: [],
    },
  },
  reducers: {
    setOncologyDenialTreeChartValues: (state, action) => {
      let data = action.payload;
      // handle for oncologyDashboardAllCodeReason  here
      if (data && data?.oncologyDashboardAllCodeReason?.length) {
        let res_data = data?.oncologyDashboardAllCodeReason;

        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.code,
            code: item?.code,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [
          ...new Set(tempArr.map((item) => item?.reasonCode)),
        ];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter(
            (item) => item?.reasonCode === code_item
          );

          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 10 Oncology Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.oncologyDenialAllReasonArr = obj2;
      } else {
        state.oncologyDenialAllReasonArr = [];
      }
    },

    setOncologyDashBaordKpi_JcodeData: (state, action) => {
      let data = action.payload;

      // handle for oncologyDashboardJCode  here
      if (data?.oncologyDashboardJCode) {
        if (data?.oncologyDashboardJCode?.length) {
          let amountArr = [];
          let countArr = [];
          data?.oncologyDashboardJCode.forEach((item, index) => {
            // for handle amount
            amountArr.push({
              country: item?.jCode,
              description: item?.description,
              "Claim Adj": item?.denailAmount,
              DeniedAmountColor: "hsl(111, 70%, 50%)",

              "Charge Amount": item?.totalChargeAmount,
              // percentage: roundToTwo(item?.percentage * 100),
              percentage: item?.percentage,
              TotalChargeAmountColor: "hsl(14, 70%, 50%)",

              DeniedCount: item?.count,
              TotalCount: item?.count,

              type: "JCode",
            });
            // for handle count
            countArr.push({
              country: item?.jCode,
              description: item?.description,
              "Denial Count": item?.count,
              DeniedCountColor: "hsl(111, 70%, 50%)",
              "Total Count": item?.rcCount,
              percentage: item?.percentage,
              TotalCountColor: "hsl(14, 70%, 50%)",
              type: "JCode",
            });
          });

          let asc_amountArr = getAscArray(amountArr, "Claim Adj");
          let asc_countArr = getAscArray(countArr, "Denial Count");

          state.oncologyDashboardJCodeAmountArr = asc_amountArr;
          state.oncologyDashboardJCodeCountArr = asc_countArr;
        } else {
          state.oncologyDashboardJCodeAmountArr = [];
          state.oncologyDashboardJCodeCountArr = [];
        }
      }
      state.barChartJcodeLoading = "";
    },

    setOncologyDashBaordKpi_AncillaryData: (state, action) => {
      let data = action.payload;

      // handle for oncologyDashboardAcillary  here
      if (data?.oncologyDashboardAcillary) {
        if (data?.oncologyDashboardAcillary?.length) {
          let amountArr = [];
          let countArr = [];

          data?.oncologyDashboardAcillary.forEach((item) => {
            // for handle amount
            amountArr.push({
              country: item?.ancillary,
              description: item?.description,
              "Claim Adj": item?.denailAmount,
              DeniedAmountColor: "hsl(111, 70%, 50%)",

              "Charge Amount": item?.totalChargeAmount,
              TotalChargeAmountColor: "hsl(14, 70%, 50%)",

              DeniedCount: item?.totalCount,
              TotalCount: item?.totalCount,
              percentage: item?.percentage,

              type: "Ancillary Code",
            });
            // for handle count
            countArr.push({
              country: item?.ancillary,
              description: item?.description,
              "Denial Count": item?.totalCount,
              DeniedCountColor: "hsl(111, 70%, 50%)",

              "Total Count": item?.rcCount,
              percentage: item?.percentage,
              TotalCountColor: "hsl(14, 70%, 50%)",
              type: "Ancillary Code",
            });
          });

          let asc_amountArr = getAscArray(amountArr, "Claim Adj");
          let asc_countArr = getAscArray(countArr, "Denial Count");
          state.oncologyDashboardAcillaryAmountArr = asc_amountArr;
          state.oncologyDashboardAcillaryCountArr = asc_countArr;
        } else {
          state.oncologyDashboardAcillaryAmountArr = [];
          state.oncologyDashboardAcillaryCountArr = [];
        }
      }
      state.barChartAncillaryLoading = "";
    },

    setOncologyDashBaordKpi_PremedData: (state, action) => {
      let data = action.payload;
      // handle for oncologyDashboardPremed  here
      if (data?.oncologyDashboardPremed) {
        if (data?.oncologyDashboardPremed?.length) {
          let amountArr = [];
          let countArr = [];
          data?.oncologyDashboardPremed.forEach((item, index) => {
            // for handle amount
            amountArr.push({
              country: item?.premed,
              description: item?.description,
              "Claim Adj": item?.denailAmount,
              DeniedAmountColor: "hsl(111, 70%, 50%)",

              "Charge Amount": item?.totalChargeAmount,
              percentage: item?.percentage,

              TotalChargeAmountColor: "hsl(14, 70%, 50%)",

              DeniedCount: item?.totalCount,
              TotalCount: item?.totalCount,

              type: "Pre-Med",
            });
            // for handle count
            countArr.push({
              country: item?.premed,
              description: item?.description,
              "Denial Count": item?.totalCount,
              DeniedCountColor: "hsl(111, 70%, 50%)",

              "Total Count": item?.rcCount,
              percentage: item?.percentage,

              TotalCountColor: "hsl(14, 70%, 50%)",
              type: "Pre-Med",
            });
          });

          let asc_amountArr = getAscArray(amountArr, "Claim Adj");
          let asc_countArr = getAscArray(countArr, "Denial Count");

          state.oncologyDashboardPremedAmountArr = asc_amountArr;
          state.oncologyDashboardPremedCountArr = asc_countArr;
        } else {
          state.oncologyDashboardPremedAmountArr = [];
          state.oncologyDashboardPremedCountArr = [];
        }
      }
      state.barChartPreMedLoading = "";
    },

    setOncologyDashBaordKpi_AdminData: (state, action) => {
      let data = action.payload;
      // handle for oncologyDashboardAdminCode  here
      if (data?.oncologyDashboardAdminCode) {
        if (data?.oncologyDashboardAdminCode?.length) {
          let amountArr = [];
          let countArr = [];
          data?.oncologyDashboardAdminCode.forEach((item) => {
            // for handle amount
            amountArr.push({
              country: item?.adminCode,
              description: item?.description,
              "Claim Adj": item?.denailAmount,
              DeniedAmountColor: "hsl(111, 70%, 50%)",

              "Charge Amount": item?.totalChargeAmount,
              TotalChargeAmountColor: "hsl(14, 70%, 50%)",

              DeniedCount: item?.totalCount,
              percentage: item?.percentage,
              TotalCount: item?.totalCount,

              type: "Admin Code",
            });
            // for handle count
            countArr.push({
              country: item?.adminCode,
              description: item?.description,
              "Denial Count": item?.totalCount,
              DeniedCountColor: "hsl(111, 70%, 50%)",

              "Total Count": item?.rcCount,
              percentage: item?.percentage,
              TotalCountColor: "hsl(14, 70%, 50%)",
              type: "Admin Code",
            });
          });

          let asc_amountArr = getAscArray(amountArr, "Claim Adj");
          let asc_countArr = getAscArray(countArr, "Denial Count");

          state.oncologyDashboardAdminAmountArr = asc_amountArr;
          state.oncologyDashboardAdminCountArr = asc_countArr;
        } else {
          state.oncologyDashboardAdminAmountArr = [];
          state.oncologyDashboardAdminCountArr = [];
        }
      }
      state.barChartAdminLoading = "";
    },

    setOncologyDashBaordKpi_All_Linechart: (state, action) => {
      let data = action.payload;
      // handle for oncologyDashboardAllCodes  here
      if (data?.oncologyAllCodeTimeline) {
        if (data?.oncologyAllCodeTimeline?.length) {
          let charge = [];
          let newArr1 = [];
          let paymentAmount = [];
          let payment = [];
          let chargeCount = [];
          let paymentCount = [];
          data?.oncologyAllCodeTimeline.forEach((item, index) => {
            // let totalChargeAmt = "Total Charge Amount";

            // for new chart values
            newArr1.push({
              // name: `${
              //   item?.monthName.includes("CY") === true
              //     ? item?.year
              //     : item?.monthName
              // }`,
              name: `${
                ["m", "w"].includes(item?.filterType?.toLowerCase())
                  ? `Week ${index + 1}`
                  : item?.monthName
              }`,
              "Charge Amount": item?.totalChargeAmount,
              "Denial Amount":
                item?.denailAmount < 0
                  ? item?.denailAmount * -1
                  : item?.denailAmount,
              "Denial AmountTemp": item?.denailAmount,
              "Total Count": item?.totalCount,
              "Denial Count": item?.count,
              type: "All",
            });

            // for handle amount
            charge.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.totalChargeAmount,
              ytemp: item?.totalChargeAmount,
              type: "All",
            });

            payment.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y:
                item?.denailAmount < 0
                  ? item?.denailAmount * -1
                  : item?.denailAmount,
              ytemp: item?.denailAmount,
              type: "All",
              show: true,
            });
            paymentAmount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.paymentAmount,
              type: "All",
            });

            // for handle count
            chargeCount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.totalCount,
              type: "All",
              // show: true,
            });
            paymentCount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.count,
              type: "All",
              show: true,
            });
          });

          let newAmountArr = [
            {
              id: "Denial Amount",
              color: "hsl(6, 70%, 50%)",
              data: payment,
            },
            // {
            //   id: "Payment Amount",
            //   color: "hsl(116, 70%, 50%)",
            //   data: paymentAmount,
            // },
            {
              id: "Charge Amount",
              color: "hsl(116, 70%, 50%)",
              data: charge,
            },
          ];
          let newCountArr = [
            {
              id: "Denial Count",
              color: "hsl(6, 70%, 50%)",
              data: paymentCount,
            },
            {
              id: "Total Count",
              color: "hsl(116, 70%, 50%)",
              data: chargeCount,
            },
          ];

          state.oncologyDashboardAllCodeTimelineAmountArr = newAmountArr;
          state.oncologyDashboardAllCodeTimelineAmountArr1 = newArr1;
          state.oncologyDashboardAllCodeTimelineCountArr = newCountArr;
        } else {
          state.oncologyDashboardAllCodeTimelineAmountArr = [];
          state.oncologyDashboardAllCodeTimelineCountArr = [];
          state.oncologyDashboardAllCodeTimelineAmountArr1 = [];
        }
      }
      state.All_LinechartLoading = "";
    },

    setOncologyDashBaordKpi_Jcode_Linechart: (state, action) => {
      let data = action.payload;
      // handle for oncologyDashboardJCodes  here

      if (data?.oncologyJCodeTimeline) {
        if (data?.oncologyJCodeTimeline?.length) {
          let charge = [];
          let paymentAmount = [];
          let payment = [];
          let newArr1 = [];
          let chargeCount = [];
          let paymentCount = [];
          data?.oncologyJCodeTimeline.forEach((item, index) => {
            // let totalChargeAmt = "Total Charge Amount";

            // for new chart values
            newArr1.push({
              // name: `${
              //   item?.monthName.includes("CY") === true
              //     ? item?.year
              //     : item?.monthName
              // }`,
              name: `${
                ["m", "w"].includes(item?.filterType?.toLowerCase())
                  ? `Week ${index + 1}`
                  : item?.monthName
              }`,
              "Charge Amount": item?.totalChargeAmount,
              "Denial Amount":
                item?.denailAmount < 0
                  ? item?.denailAmount * -1
                  : item?.denailAmount,
              "Denial AmountTemp": item?.denailAmount,
              "Total Count": item?.totalCount,
              "Denial Count": item?.count,
              type: "JCode",
            });

            // for handle amount
            charge.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.totalChargeAmount,
              ytemp: item?.totalChargeAmount,
              type: "JCode",
            });

            payment.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y:
                item?.denailAmount < 0
                  ? item?.denailAmount * -1
                  : item?.denailAmount,
              ytemp: item?.denailAmount,
              type: "JCode",
              show: true,
            });
            paymentAmount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.paymentAmount,
              type: "JCode",
            });

            // for handle count
            chargeCount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.totalCount,
              type: "JCode",
            });
            paymentCount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.count,
              type: "JCode",
              show: true,
            });
          });

          let newAmountArr = [
            {
              id: "Denial Amount",
              color: "hsl(6, 70%, 50%)",
              data: payment,
            },
            // {
            //   id: "Payment Amount",
            //   color: "hsl(116, 70%, 50%)",
            //   data: paymentAmount,
            // },
            {
              id: "Charge Amount",
              color: "hsl(116, 70%, 50%)",
              data: charge,
            },
          ];
          let newCountArr = [
            {
              id: "Denial Count",
              color: "hsl(6, 70%, 50%)",
              data: paymentCount,
            },
            {
              id: "Total Count",
              color: "hsl(116, 70%, 50%)",
              data: chargeCount,
            },
          ];

          state.oncologyDashboardJCodeTimelineAmountArr = newAmountArr;
          state.oncologyDashboardJCodeTimelineCountArr = newCountArr;
          state.oncologyDashboardJCodeTimelineAmountArr1 = newArr1;
        } else {
          state.oncologyDashboardJCodeTimelineAmountArr = [];
          state.oncologyDashboardJCodeTimelineCountArr = [];
          state.oncologyDashboardJCodeTimelineAmountArr1 = [];
        }
      }
      state.lineChartJcodeLoading = "";
    },

    setOncologyDashBaordKpi_Ancillary_Linechart: (state, action) => {
      let data = action.payload;

      // handle for oncologyDashboardAcillaryTimeline  here
      if (data?.oncologyAcillaryTimeline) {
        if (data?.oncologyAcillaryTimeline?.length) {
          let charge = [];
          let paymentAmount = [];
          let newArr1 = [];
          let payment = [];
          let chargeCount = [];
          let paymentCount = [];
          data?.oncologyAcillaryTimeline.forEach((item, index) => {
            // for new chart values
            newArr1.push({
              // name: `${
              //   item?.monthName.includes("CY") === true
              //     ? item?.year
              //     : item?.monthName
              // }`,
              name: `${
                ["m", "w"].includes(item?.filterType?.toLowerCase())
                  ? `Week ${index + 1}`
                  : item?.monthName
              }`,
              "Charge Amount": item?.totalChargeAmount,
              "Denial Amount":
                item?.denailAmount < 0
                  ? item?.denailAmount * -1
                  : item?.denailAmount,
              "Denial AmountTemp": item?.denailAmount,
              "Total Count": item?.totalCount,
              "Denial Count": item?.count,
              type: "Acillary Code",
            });

            // for handle amount
            charge.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.totalChargeAmount,
              ytemp: item?.totalChargeAmount,
              type: "Acillary Code",
            });
            payment.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y:
                item?.denailAmount < 0
                  ? item?.denailAmount * -1
                  : item?.denailAmount,
              ytemp: item?.denailAmount,
              type: "Acillary Code",
              show: true,
            });
            // paymentAmount.push({
            //   x: `${
            //     item?.monthName.includes("CY") === true
            //       ? item?.year
            //       : item?.monthName
            //   }`,
            //   y: item?.paymentAmount,
            //   type: "Acillary Code",
            // });

            // for handle count
            chargeCount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.totalCount,
              type: "Acillary Code",
            });
            paymentCount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.count,
              type: "Acillary Code",
              show: true,
            });
          });

          let newAmountArr = [
            {
              id: "Denial Amount",
              color: "hsl(6, 70%, 50%)",
              data: payment,
            },
            // {
            //   id: "Payment Amount",
            //   color: "hsl(116, 70%, 50%)",
            //   data: paymentAmount,
            // },
            {
              id: "Charge Amount",
              color: "hsl(116, 70%, 50%)",
              data: charge,
            },
          ];
          let newCountArr = [
            {
              id: "Denial Count",
              color: "hsl(6, 70%, 50%)",
              data: paymentCount,
            },
            {
              id: "Total Count",
              color: "hsl(116, 70%, 50%)",
              data: chargeCount,
            },
          ];

          state.oncologyDashboardAcillaryTimelineAmountArr1 = newArr1;
          state.oncologyDashboardAcillaryTimelineAmountArr = newAmountArr;
          state.oncologyDashboardAcillaryTimelineCountArr = newCountArr;
        } else {
          state.oncologyDashboardAcillaryTimelineAmountArr = [];
          state.oncologyDashboardAcillaryTimelineCountArr = [];
          state.oncologyDashboardAcillaryTimelineAmountArr1 = [];
        }
      }
      state.lineChartAncillaryLoading = "";
    },
    setOncologyDashBaordKpi_Premed_Linechart: (state, action) => {
      let data = action.payload;
      // handle for oncologyDashboardPremedTimeline  here

      if (data?.oncologyPremedTimeline) {
        if (data?.oncologyPremedTimeline?.length) {
          let paymentAmount = [];
          let charge = [];
          let newArr1 = [];
          let payment = [];
          let chargeCount = [];
          let paymentCount = [];
          data?.oncologyPremedTimeline.forEach((item, index) => {
            // for new chart values
            newArr1.push({
              // name: `${
              //   item?.monthName.includes("CY") === true
              //     ? item?.year
              //     : item?.monthName
              // }`,
              name: `${
                ["m", "w"].includes(item?.filterType?.toLowerCase())
                  ? `Week ${index + 1}`
                  : item?.monthName
              }`,
              "Charge Amount": item?.totalChargeAmount,
              "Denial Amount":
                item?.denailAmount < 0
                  ? item?.denailAmount * -1
                  : item?.denailAmount,
              "Denial AmountTemp": item?.denailAmount,
              "Total Count": item?.totalCount,
              "Denial Count": item?.count,
              type: "Pre-Med",
            });

            // for handle amount
            charge.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.totalChargeAmount,
              ytemp: item?.totalChargeAmount,
              type: "Pre-Med",
            });
            payment.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y:
                item?.denailAmount < 0
                  ? item?.denailAmount * -1
                  : item?.denailAmount,
              ytemp: item?.denailAmount,
              type: "Pre-Med",
              show: true,
            });
            // paymentAmount.push({
            //   x: `${
            //     item?.monthName.includes("CY") === true
            //       ? item?.year
            //       : item?.monthName
            //   }`,
            //   y: item?.paymentAmount,
            //   type: "Pre-Med",
            // });

            // for handle count
            chargeCount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.totalCount,
              type: "Pre-Med",
            });
            paymentCount.push({
              x: `${
                item?.monthName.includes("CY") === true
                  ? item?.year
                  : item?.monthName
              }`,
              y: item?.count,
              type: "Pre-Med",
              show: true,
            });
          });

          let newAmountArr = [
            {
              id: "Denial Amount",
              color: "hsl(6, 70%, 50%)",
              data: payment,
            },
            // {
            //   id: "Payment Amount",
            //   color: "hsl(116, 70%, 50%)",
            //   data: paymentAmount,
            // },
            {
              id: "Charge Amount",
              color: "hsl(116, 70%, 50%)",
              data: charge,
            },
          ];
          let newCountArr = [
            {
              id: "Denial Count",
              color: "hsl(6, 70%, 50%)",
              data: paymentCount,
            },
            {
              id: "Total Count",
              color: "hsl(116, 70%, 50%)",
              data: chargeCount,
            },
          ];

          state.oncologyDashboardPremedTimelineAmountArr = newAmountArr;
          state.oncologyDashboardPremedTimelineAmountArr1 = newArr1;
          state.oncologyDashboardPremedTimelineCountArr = newCountArr;
        } else {
          state.oncologyDashboardPremedTimelineAmountArr1 = [];
          state.oncologyDashboardPremedTimelineAmountArr = [];
          state.oncologyDashboardPremedTimelineCountArr = [];
        }
      }
      state.lineChartPreMedLoading = "";
    },
    setOncologyDashBaordKpi_Admin_Linechart: (state, action) => {
      let data = action.payload;
      // handle for oncologyDashboardAdminCodeReason  here
      try {
        // handle for oncologyDashboardAdminCodeTimeline  here
        if (data?.oncologyAdminCodeTimeline) {
          if (data?.oncologyAdminCodeTimeline?.length) {
            let charge = [];
            let paymentAmount = [];
            let newArr1 = [];
            let payment = [];
            let chargeCount = [];
            let paymentCount = [];
            data?.oncologyAdminCodeTimeline.forEach((item, index) => {
              // for new chart values
              newArr1.push({
                // name: `${
                //   item?.monthName.includes("CY") === true
                //     ? item?.year
                //     : item?.monthName
                // }`,
                name: `${
                  ["m", "w"].includes(item?.filterType?.toLowerCase())
                    ? `Week ${index + 1}`
                    : item?.monthName
                }`,
                "Charge Amount": item?.totalChargeAmount,
                "Denial Amount":
                  item?.denailAmount < 0
                    ? item?.denailAmount * -1
                    : item?.denailAmount,
                "Denial AmountTemp": item?.denailAmount,
                "Total Count": item?.totalCount,
                "Denial Count": item?.count,
                type: "Admin Code",
              });

              // for handle amount
              charge.push({
                x: `${
                  item?.monthName.includes("CY") === true
                    ? item?.year
                    : item?.monthName
                }`,
                y: item?.totalChargeAmount,
                ytemp: item?.totalChargeAmount,
                type: "Admin Code",
              });
              payment.push({
                x: `${
                  item?.monthName.includes("CY") === true
                    ? item?.year
                    : item?.monthName
                }`,
                y:
                  item?.denailAmount < 0
                    ? item?.denailAmount * -1
                    : item?.denailAmount,
                ytemp: item?.denailAmount,
                type: "Admin Code",
                show: true,
              });
              // paymentAmount.push({
              //   x: `${
              //     item?.monthName.includes("CY") === true
              //       ? item?.year
              //       : item?.monthName
              //   }`,
              //   y: item?.paymentAmount,
              //   type: "Admin Code",
              // });

              // for handle count
              chargeCount.push({
                x: `${
                  item?.monthName.includes("CY") === true
                    ? item?.year
                    : item?.monthName
                }`,
                y: item?.totalCount,
                type: "Admin Code",
              });
              paymentCount.push({
                x: `${
                  item?.monthName.includes("CY") === true
                    ? item?.year
                    : item?.monthName
                }`,
                y: item?.count,
                type: "Admin Code",
                show: true,
              });
            });

            let newAmountArr = [
              {
                id: "Denial Amount",
                color: "hsl(6, 70%, 50%)",
                data: payment,
              },
              // {
              //   id: "Payment Amount",
              //   color: "hsl(116, 70%, 50%)",
              //   data: paymentAmount,
              // },
              {
                id: "Charge Amount",
                color: "hsl(116, 70%, 50%)",
                data: charge,
              },
            ];
            let newCountArr = [
              {
                id: "Denial Count",
                color: "hsl(6, 70%, 50%)",
                data: paymentCount,
              },
              {
                id: "Total Count",
                color: "hsl(116, 70%, 50%)",
                data: chargeCount,
              },
            ];

            state.oncologyDashboardAdminCodeTimelineAmountArr1 = newArr1;
            state.oncologyDashboardAdminCodeTimelineAmountArr = newAmountArr;
            state.oncologyDashboardAdminCodeTimelineCountArr = newCountArr;
          } else {
            state.oncologyDashboardAdminCodeTimelineAmountArr = [];
            state.oncologyDashboardAdminCodeTimelineCountArr = [];
            state.oncologyDashboardAdminCodeTimelineAmountArr1 = [];
          }
        }
      } catch (err) {
        // console.log("err--->>", err);
      }
      state.lineChartAdminLoading = "";
    },

    setOncologyDashBaordKpi_data: (state, action) => {
      let data = action.payload;

      // handle for oncologyDashboardCPTDenial  here
      if (data?.oncologyDashboardCPTDenial) {
        if (data?.oncologyDashboardCPTDenial?.length) {
          let codeArr = [];
          let amtArr = [];
          data?.oncologyDashboardCPTDenial.forEach((item, index) => {
            codeArr.push({
              id: item?.cpt,
              ranges: [item?.totalCount],
              measures: [item?.count],
            });
            amtArr.push({
              id: item?.cpt,
              ranges: [item?.totalChargeAmount],
              measures: [item?.denailAmount],
            });
          });
          state.oncologyDashboardCPTDenialCodeArr = codeArr;
          state.oncologyDashboardCPTDenialAmountArr = amtArr;
        } else {
          state.oncologyDashboardCPTDenialCodeArr = [];
          state.oncologyDashboardCPTDenialAmountArr = [];
        }
      }
    },
    setoncologyBarChartCallJCode: (state, action) => {},
    setOncologyDahsboardKpiCards_data: (state, action) => {
      let data = action.payload;

      var cardsArr = [];

      // handle for DashboardAcillaryCard  here
      if (data?.oncologyDashboardAcillaryCard) {
        if (data?.oncologyDashboardAcillaryCard?.length) {
          let obj = {
            id: "Ancillary",
            label: "Ancillary",
            value: roundToTwo(
              Number(data?.oncologyDashboardAcillaryCard[0]?.percentage)
            ),
          };

          state.oncologyDashboardAncillaryCard =
            data?.oncologyDashboardAcillaryCard[0];

          cardsArr.push(obj);
        } else {
          state.oncologyDashboardAncillaryCard = {};
        }
      }

      // handle for DashboardAdminCodeCard  here
      if (data?.oncologyDashboardAdminCodeCard) {
        if (data?.oncologyDashboardAdminCodeCard?.length) {
          let obj = {
            id: "AdminCode",
            label: "AdminCode",
            value: roundToTwo(
              Number(data?.oncologyDashboardAdminCodeCard[0]?.percentage)
            ),
          };
          state.oncologyDashboardAdminCodeCard =
            data?.oncologyDashboardAdminCodeCard[0];
          cardsArr.push(obj);
        } else {
          state.oncologyDashboardAdminCodeCard = {};
        }
      }

      // handle for cologyDashboardTotalCard  here
      if (data?.oncologyDashboardTotalCard) {
        if (data?.oncologyDashboardTotalCard?.length) {
          state.oncologyDashboardTotalCard =
            data?.oncologyDashboardTotalCard[0];
        } else {
          state.oncologyDashboardTotalCard = {};
        }
      }

      // handle for DashboardJCodeCard  here
      if (data?.oncologyDashboardJCodeCard) {
        if (data?.oncologyDashboardJCodeCard?.length) {
          let obj = {
            id: "JCode",
            label: "JCode",
            value: roundToTwo(
              Number(data?.oncologyDashboardJCodeCard[0]?.percentage)
            ),
          };

          state.oncologyDashboardJCodeCard =
            data?.oncologyDashboardJCodeCard[0];
          cardsArr.push(obj);
        } else {
          state.oncologyDashboardJCodeCard = {};
        }
      }

      // handle for DashboardPremedCard  here
      if (data?.oncologyDashboardPremedCard) {
        if (data?.oncologyDashboardPremedCard?.length) {
          let obj = {
            id: "Pre-Med",
            label: "Pre-Med",
            value: roundToTwo(
              Number(data?.oncologyDashboardPremedCard[0]?.percentage)
            ),
          };

          state.oncologyDashboardPremedCard =
            data?.oncologyDashboardPremedCard[0];
          cardsArr.push(obj);
        } else {
          state.oncologyDashboardPremedCard = {};
        }
      }

      state.oncologyDashboardCardsArr = cardsArr;
      state.oncologyDashboardCardsLoading = false;
    },
    gettingTopCards_data: (state, action) => {
      state.oncologyDashboardCardsLoading = true;
    },

    gettingBarChartJCode_data: (state, action) => {
      state.barChartJcodeLoading = "data-loading";
    },

    gettingLineChartAllCode_data: (state, action) => {
      state.All_LinechartLoading = "data-loading";
    },

    gettingBarChartAncillary_data: (state, action) => {
      state.barChartAncillaryLoading = "data-loading";
    },

    gettingLineChartJcodeLoading_data: (state, action) => {
      state.lineChartJcodeLoading = "data-loading";
    },

    gettingBarChartPreMedLoading_data: (state, action) => {
      state.barChartPreMedLoading = "data-loading";
    },

    gettingLineChartAncillaryLoading_data: (state, action) => {
      state.lineChartAncillaryLoading = "data-loading";
    },

    gettingBarChartAdminLoading_data: (state, action) => {
      state.barChartAdminLoading = "data-loading";
    },

    gettingLineChartPreMedLoading_data: (state, action) => {
      state.lineChartPreMedLoading = "data-loading";
    },

    gettingLineChartAdminLoading_data: (state, action) => {
      state.lineChartAdminLoading = "data-loading";
    },

    setAppealsDahsboardKpiCards_data: (state, action) => {
      let data = action.payload;
      // console.log("123123 res top cards", data);
      state.appealDashboardTopCards = data[0] || {};
    },
    setAppealsDahsboardLineChart_data: (state, action) => {
      let data = action.payload;
      // console.log("123123 res line chart", data);
      state.appealDashboardLineChart = data || [];
    },
    setAppealsDahsboardBarChart_data: (state, action) => {
      let data = action.payload;

      if (data?.payers?.length) {
        const res = data?.payers?.map((item) => {
          let payerName = item?.payerName.replace(/(?<!\()(\()(?!\))/g, "");
          return {
            Amount: item.chargeAmount,
            // count: item?.count,
            country: getLegendsExtraShortName(payerName),
            payerName: item?.payerName,
            amountColor: "hsl(14, 70%, 50%)",
          };
        });

        const res2 = data?.payers?.map((item) => {
          let payerName = item?.payerName.replace(/(?<!\()(\()(?!\))/g, "");

          return {
            // amount: item.chargeAmount,
            Count: item?.count,
            country: getLegendsExtraShortName(payerName),
            payerName: item?.payerName,
            countColor: "hsl(111, 70%, 50%)",
          };
        });

        const descCount = getDescArray(res2, "Count");
        const descAmount = getDescArray(res, "Amount");

        state.appealDashboardBarChart.barChartAmountArr = descAmount;
        state.appealDashboardBarChart.barChartCountArr = descCount;
      } else {
        state.appealDashboardBarChart.barChartAmountArr = [];
        state.appealDashboardBarChart.barChartCountArr = [];
      }
    },

    setOncologyDashboardFilterData: (state, action) => {
      let data = action.payload;
      // for  Payers filter Arr
      if (data?.cptLevelASJSON) {
        state.serviceLineFilterArr = JSON.parse(data?.cptLevelASJSON);
      } else {
        state.serviceLineFilterArr = [];
      }

      if (data?.oncologyCancerFilter?.length) {
        let newArr = [];
        data?.oncologyCancerFilter?.forEach((item) => {
          newArr.push({
            label: item?.facilityName,
            value: item?.facilityName,
          });
        });

        state.cancerFacilityFilterArr = newArr;
      } else {
        state.cancerFacilityFilterArr = [];
      }

      // handle payer filter array here

      if (data?.payerFilterData?.length) {
        // removing duplicate payerName here start
        const uniquePayerNames = {};
        const uniqueArrayOfObjects = data?.payerFilterData.filter((obj) => {
          if (!uniquePayerNames[obj.payerName]) {
            uniquePayerNames[obj.payerName] = true;
            return true;
          }
          return false;
        });
        // removing duplicate payerName here end

        // Sort the array alphabetically based on payerName
        uniqueArrayOfObjects.sort((a, b) => {
          const payerNameA = a.payerName.toLowerCase();
          const payerNameB = b.payerName.toLowerCase();
          if (payerNameA < payerNameB) return -1;
          if (payerNameA > payerNameB) return 1;
          return 0;
        });

        let newArr = [];
        uniqueArrayOfObjects?.forEach((item) => {
          newArr.push({
            label: item?.payerName,
            value: item?.payerId,
          });
        });

        state.payerFilterArr = newArr;
      } else {
        state.payerFilterArr = [];
      }
      //
    },
    setAppealsDashboardFilterData: (state, action) => {
      let data = action.payload;
      // console.log("123123 respose - ", data);
    },

    setOncologyTopReasonByCpt_data: (state, action) => {
      let data = action.payload;
      // handle for oncologyDashboardAllReasonCPT  here
      if (
        data?.oncologyDashboardAllReasonCPT &&
        data?.oncologyDashboardAllReasonCPT?.length
      ) {
        let res_data = data?.oncologyDashboardAllReasonCPT;

        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.code,
            code: item?.code,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [
          ...new Set(tempArr.map((item) => item?.reasonCode)),
        ];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter(
            (item) => item?.reasonCode === code_item
          );

          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 10 Reason Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.oncologyDashboardAllReasonCPTArr = obj2;
      } else {
        state.oncologyDashboardAllReasonCPTArr = [];
      }

      // handle for oncologyJCodeReasonCPT  here
      if (
        data?.oncologyJCodeReasonCPT &&
        data?.oncologyJCodeReasonCPT?.length
      ) {
        let res_data = data?.oncologyJCodeReasonCPT;

        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.code,
            code: item?.code,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [
          ...new Set(tempArr.map((item) => item?.reasonCode)),
        ];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter(
            (item) => item?.reasonCode === code_item
          );

          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Reason Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.oncologyJCodeReasonCPTArr = obj2;
      } else {
        state.oncologyJCodeReasonCPTArr = [];
      }

      // handle for oncologyAncillaryReasonCPT  here
      if (
        data?.oncologyAncillaryReasonCPT &&
        data?.oncologyAncillaryReasonCPT?.length
      ) {
        let res_data = data?.oncologyAncillaryReasonCPT;

        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.code,
            code: item?.code,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [
          ...new Set(tempArr.map((item) => item?.reasonCode)),
        ];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter(
            (item) => item?.reasonCode === code_item
          );

          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Reason Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.oncologyAncillaryReasonCPTArr = obj2;
      } else {
        state.oncologyAncillaryReasonCPTArr = [];
      }

      // handle for oncologyPremedReasonCPT  here
      if (
        data?.oncologyPremedReasonCPT &&
        data?.oncologyPremedReasonCPT?.length
      ) {
        let res_data = data?.oncologyPremedReasonCPT;

        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.code,
            code: item?.code,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [
          ...new Set(tempArr.map((item) => item?.reasonCode)),
        ];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter(
            (item) => item?.reasonCode === code_item
          );

          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Reason Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.oncologyPremedReasonCPTArr = obj2;
      } else {
        state.oncologyPremedReasonCPTArr = [];
      }

      // handle for oncologyAdminCodeReasonCPT  here
      if (
        data?.oncologyAdminCodeReasonCPT &&
        data?.oncologyAdminCodeReasonCPT?.length
      ) {
        let res_data = data?.oncologyAdminCodeReasonCPT;

        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.code,
            code: item?.code,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [
          ...new Set(tempArr.map((item) => item?.reasonCode)),
        ];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter(
            (item) => item?.reasonCode === code_item
          );

          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Reason Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.oncologyAdminCodeReasonCPTArr = obj2;
      } else {
        state.oncologyAdminCodeReasonCPTArr = [];
      }
    },

    setOncologyDenialFilters: (state, action) => {
      let data = action.payload;
      // for  serviceDenialReasonCode filter Arr
      if (data?.serviceDenialReasonCode?.length) {
        let newArr = [];
        data?.serviceDenialReasonCode?.forEach((item) => {
          let desc = item?.code + ` - ${item?.description}`;
          newArr.push({
            label: desc,
            value: item?.code,
          });
        });

        state.smartFiltersForFilterArr = newArr;
        state.loading = false;
      } else {
        state.smartFiltersForFilterArr = [];
        state.loading = false;
      }
    },
    gettingSmartFilterArr: (state, action) => {
      state.loading = true;
      // for  serviceDenialReasonCode filter Arr
    },
    failedGettingSmartFilterArr: (state, action) => {
      state.loading = false;
      // for  serviceDenialReasonCode filter Arr
    },
  },
});

export const {
  setOncologyDashBaordKpi_data,
  setoncologyBarChartCallJCode,
  setOncologyDenialFilters,
  setOncologyDashboardFilterData,

  setOncologyTopReasonByCpt_data,
  setOncologyDahsboardKpiCards_data,

  setAppealsDahsboardKpiCards_data,
  setAppealsDahsboardLineChart_data,
  setAppealsDahsboardBarChart_data,

  setOncologyDenialTreeChartValues,
  gettingSmartFilterArr,
  failedGettingSmartFilterArr,
  setOncologyDashBaordKpi_JcodeData,
  setOncologyDashBaordKpi_AncillaryData,
  setOncologyDashBaordKpi_PremedData,
  setOncologyDashBaordKpi_AdminData,
  setOncologyDashBaordKpi_All_Linechart,
  setOncologyDashBaordKpi_Jcode_Linechart,
  setOncologyDashBaordKpi_Ancillary_Linechart,
  setOncologyDashBaordKpi_Premed_Linechart,
  setOncologyDashBaordKpi_Admin_Linechart,
  gettingTopCards_data,
  gettingBarChartJCode_data,
  gettingLineChartAllCode_data,
  gettingBarChartAncillary_data,
  gettingLineChartJcodeLoading_data,
  gettingBarChartPreMedLoading_data,
  gettingLineChartAncillaryLoading_data,
  gettingBarChartAdminLoading_data,
  gettingLineChartPreMedLoading_data,
  gettingLineChartAdminLoading_data,
} = OncologyDashboardKpisSlice.actions;

// get onCology list

const settingDataList = (res, loader, dispatch, valueSetter) => {
  try {
    if (!res?.data?.success) {
      toast.error(`Something went wrong`, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(valueSetter([]));
      dispatch(
        setListingState({
          listing: "oncologyDashboardFilter",
          value: {
            // loadingMore: "",
            [loader]: "",
          },
        })
      );
      setListingState({
        listing: "appealsDashboardFilter",
        value: {
          // loadingMore: "data-loading",
          [loader]: "",
        },
      });
    } else {
      dispatch(valueSetter(res?.data?.data));
      dispatch(
        setListingState({
          listing: "oncologyDashboardFilter",
          value: {
            // hasMore: res?.data?.data?.length === sliceState.pageSize,
            // loadingMore: "",

            [loader]: "",
          },
        })
      );
      dispatch(
        setListingState({
          listing: "appealsDashboardFilter",
          value: {
            // loadingMore: "data-loading",
            [loader]: "",
          },
        })
      );
    }
  } catch (error) {
    dispatch(
      setListingState({
        listing: "oncologyDashboardFilter",
        value: {
          initialLoading: false,
          // loadingMore: "",
          [loader]: "",

          hasMore: false,
        },
      })
    );
    setListingState({
      listing: "appealsDashboardFilter",
      value: {
        // loadingMore: "data-loading",
        [loader]: "",
      },
    });
    dispatch(valueSetter([]));
  }
};

export const gettingOncologyDashBoardKpis_Call =
  (parameter) => async (dispatch, getState) => {
    if (parameter?.clear === true) {
      dispatch(
        resetListingState({
          listing: "oncologyDashboardFilter",
          ignore: ["claimStatus,payers,cptCodes,CARC"],
        })
      );
    }

    try {
      let sliceState = getState().npReducers.filters.oncologyDashboardFilter;

      ///// API CALL //////

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };

      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setOncologyDashBaordKpi_data,
        "oncologyDashboardFilter",
        "GET_TOP_DRUGS_KPIS",
        null,
        "",
        ""
      );
      return false;

      ////// API CALL END //////////////
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// getting onCology Denial Dash treeChart values
export const gettingOncologyDenialDashTreeChart_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "oncologyDashboardFilter",
          ignore: ["claimStatus,payers,cptCodes,CARC"],
        })
      );
    }

    try {
      let sliceState = getState().npReducers.filters.oncologyDashboardFilter;

      ///// API CALL //////
      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };
      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setOncologyDenialTreeChartValues,
        "oncologyDashboardFilter",
        "GET_TOP_DRUGS_KPIS",
        null,
        "",
        ""
      );
      return false;

      ////// API CALL END //////////////
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

export const gettingOncologyTopReasonByCpt_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "oncologyDashboardFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.oncologyDashboardFilter;

    try {
      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };
      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setOncologyTopReasonByCpt_data,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_TOP_REASONCODE_BY_CPT_KPIS",
        null,
        "",
        ""
      );
      return false;
    } catch (e) {
      return false;
      // console.log("e--->>", e);
    }
  };
const oncologyBarChartCall =
  (paramsTemp, params) => async (dispatch, getState) => {
    try {
      params = { ...params, groupBy: "actual" };
      paramsTemp = { ...paramsTemp, groupBy: "actual" };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPaginationWithLoading(
        getState,
        paramsTemp,
        params,
        dispatch,
        setoncologyBarChartCallJCode,
        "oncologyDashboardFilter",
        "barChartJcodeLoading",
        "GET_ONCOLOGY_DASH_KPIS_BARCHART_JCODE",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const gettingOnlyOncologyDashBoardKpis_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch(
        setListingState({
          listing: "oncologyDashboardFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "oncologyDashboardFilter",
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        );

        // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
        let advStngServiceLineExecList =
          getState().pReducers.user.advStngServiceLineExecList;
        let data = advStngServiceLineExecList?.split(",")?.map((item) => {
          return {
            label: item.toString(),
            value: item.toString(),
          };
        });
        dispatch(
          setListingState({
            listing: "oncologyDashboardFilter",
            value: {
              smartFilterArr: data,
              pageNumber: 1,
            },
          })
        );
        // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
      }

      dispatch(
        setListingState({
          listing: "oncologyDashboardFilter",
          value: {
            loading1: "data-loading",
            loading2: "data-loading",
            loading3: "data-loading",
            loading4: "data-loading",
            loading5: "data-loading",
            loading6: "data-loading",
            loading7: "data-loading",
            loading8: "data-loading",
            loading9: "data-loading",
          },
        })
      );

      let sliceStateForDate =
        getState().npReducers.filters.insightsSreensCommonFilter;
      let sliceState = getState().npReducers.filters.oncologyDashboardFilter;
      let sliceStateUser = getState().pReducers.user;

      ///// API CALL //////

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.selFilterType !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),
        ...(sliceState?.cancerFacility?.length > 0 && {
          CancerFacility: getMultiSelectFilterValueForApi(
            sliceState?.cancerFacility
          ),
        }),
        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : // : sliceStateForDate?.clientId || getCurrentClientId(),
                sliceStateForDate?.clientId,
        }),
      };

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.selFilterType !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),
        ...(sliceState?.cancerFacility?.length > 0 && {
          CancerFacility: getMultiSelectFilterValueForApi(
            sliceState?.cancerFacility
          ),
        }),
        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : // : sliceStateForDate?.clientId || getCurrentClientId(),
                sliceStateForDate?.clientId,
        }),
      };

      let params2 = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.cancerFacility?.length > 0 && {
          CancerFacility: getMultiSelectFilterValueForApi(
            sliceState?.cancerFacility
          ),
        }),
        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : // : sliceStateForDate?.clientId || getCurrentClientId(),
                sliceStateForDate?.clientId,
        }),
      };

      let params2Temp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.cancerFacility?.length > 0 && {
          CancerFacility: getMultiSelectFilterValueForApi(
            sliceState?.cancerFacility
          ),
        }),
        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : // : sliceStateForDate?.clientId || getCurrentClientId(),
                sliceStateForDate?.clientId,
        }),
      };

      // below params for smartFilters
      let params3 = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.cancerFacility?.length > 0 && {
          CancerFacility: getMultiSelectFilterValueForApi(
            sliceState?.cancerFacility
          ),
        }),
        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : // : sliceStateForDate?.clientId || getCurrentClientId(),
                sliceStateForDate?.clientId,
        }),
      };
      let params3Temp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.cancerFacility?.length > 0 && {
          CancerFacility: getMultiSelectFilterValueForApi(
            sliceState?.cancerFacility
          ),
        }),
        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : // : sliceStateForDate?.clientId || getCurrentClientId(),
                sliceStateForDate?.clientId,
        }),
      };

      // console.log("api_call--->>", params);

      let sliceState12 =
        getState().npReducers.filters["oncologyDashboardFilter"];
      let cacheData = { ...sliceState12.cachePagination };
      let cachParamsState = { ...sliceState12.cacheParams };
      let cache = cacheData[`page_${sliceState12.pageNumber}`];

      if (
        cache &&
        // cache.claims.length > 0 &&
        JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
      ) {
        // do nothing
      } else {
        // API call here
        dispatch(gettingTopCards_data());
        dispatch(gettingBarChartJCode_data());
        dispatch(gettingLineChartAllCode_data());
        dispatch(gettingBarChartAncillary_data());
        dispatch(gettingLineChartJcodeLoading_data());
        dispatch(gettingBarChartPreMedLoading_data());
        dispatch(gettingLineChartAncillaryLoading_data());
        dispatch(gettingBarChartAdminLoading_data());
        dispatch(gettingLineChartPreMedLoading_data());
        dispatch(gettingLineChartAdminLoading_data());
        dispatch(
          gettingSmartFilterForOncologyDenial_Call("oncologyDashboardFilter")
        );
      }

      //  ========== start TOP CARD API call here ==========
      // genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_TOP_CARD_KPIS,
      //   "get",
      //   "",
      //   params3
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading1",
      //     dispatch,
      //     setOncologyDahsboardKpiCards_data
      //   );
      // });

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        params3Temp,
        params3,
        dispatch,
        setOncologyDahsboardKpiCards_data,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_TOP_CARD_KPIS",
        null
      );

      //  ========== end TOP CARD API call here ==========

      //  ========== start Filters API call here ==========

      // filters call here
      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_FILTERS,
      //   "get",
      //   "",
      //   params2
      // ).then((result) => {
      //   if (result?.data?.data) {
      //     dispatch(
      //       setListingState({
      //         listing: "oncologyDashboardFilter",
      //         value: {
      //           loadingMore: "",
      //         },
      //       })
      //     );
      //     dispatch(setOncologyDashboardFilterData(result?.data?.data));
      //   } else {
      //     dispatch(
      //       setListingState({
      //         listing: "oncologyDashboardFilter",
      //         value: {
      //           loadingMore: "",
      //         },
      //       })
      //     );
      //     dispatch(setOncologyDashboardFilterData([]));
      //   }
      // });

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        params2Temp,
        params2,
        dispatch,
        setOncologyDashboardFilterData,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_FILTERS",
        null
      );

      //  ========== end Filters API call here ==========

      //  ========== start GET_ONCOLOGY_DASH_KPIS_BARCHART_JCODE API call here ==========

      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_KPIS_BARCHART_JCODE,
      //   "get",
      //   "",
      //   params3
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading3",
      //     dispatch,
      //     setOncologyDashBaordKpi_JcodeData
      //   );
      // });

      // loading3
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        params3Temp,
        params3,
        dispatch,
        setOncologyDashBaordKpi_JcodeData,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_KPIS_BARCHART_JCODE",
        null
      );

      //  ========== end GET_ONCOLOGY_DASH_KPIS_BARCHART_JCODE API call here ==========

      //  ========== start GET_ONCOLOGY_DASH_KPIS_LINECHART_ALL API call here ==========

      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_KPIS_LINECHART_ALL,
      //   "get",
      //   "",
      //   params
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading2",
      //     dispatch,
      //     setOncologyDashBaordKpi_All_Linechart
      //   );
      // });

      // loading2
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setOncologyDashBaordKpi_All_Linechart,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_KPIS_LINECHART_ALL",
        null
      );

      //  ========== end GET_ONCOLOGY_DASH_KPIS_LINECHART_ALL API call here ==========

      //  ========== end GET_ONCOLOGY_DASH_KPIS_BARCHART_ANCILLARY API call here ==========

      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_KPIS_BARCHART_ANCILLARY,
      //   "get",
      //   "",
      //   params3
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading4",
      //     dispatch,
      //     setOncologyDashBaordKpi_AncillaryData
      //   );
      // });

      // loading4
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        params3Temp,
        params3,
        dispatch,
        setOncologyDashBaordKpi_AncillaryData,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_KPIS_BARCHART_ANCILLARY",
        null
      );

      //  ========== end GET_ONCOLOGY_DASH_KPIS_BARCHART_ANCILLARY API call here ==========

      //  ========== start GET_ONCOLOGY_DASH_KPIS_LINECHART_JCODE API call here ==========

      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_KPIS_LINECHART_JCODE,
      //   "get",
      //   "",
      //   params
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading2",
      //     dispatch,
      //     setOncologyDashBaordKpi_Jcode_Linechart
      //   );
      // });

      // loading2
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setOncologyDashBaordKpi_Jcode_Linechart,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_KPIS_LINECHART_JCODE",
        null
      );

      //  ========== start GET_ONCOLOGY_DASH_KPIS_LINECHART_JCODE API call here ==========

      //  ========== start GET_ONCOLOGY_DASH_KPIS_BARCHART_PREMED API call here ==========

      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_KPIS_BARCHART_PREMED,
      //   "get",
      //   "",
      //   params3
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading5",
      //     dispatch,
      //     setOncologyDashBaordKpi_PremedData
      //   );
      // });

      // loading5
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        params3Temp,
        params3,
        dispatch,
        setOncologyDashBaordKpi_PremedData,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_KPIS_BARCHART_PREMED",
        null
      );

      //  ========== start GET_ONCOLOGY_DASH_KPIS_BARCHART_PREMED API call here ==========

      //  ========== start GET_ONCOLOGY_DASH_KPIS_LINECHART_ANCILLARY API call here ==========

      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_KPIS_LINECHART_ANCILLARY,
      //   "get",
      //   "",
      //   params
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading9",
      //     dispatch,
      //     setOncologyDashBaordKpi_Ancillary_Linechart
      //   );
      // });

      // loading9
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setOncologyDashBaordKpi_Ancillary_Linechart,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_KPIS_LINECHART_ANCILLARY",
        null
      );

      //  ========== end GET_ONCOLOGY_DASH_KPIS_LINECHART_ANCILLARY API call here ==========

      //  ========== start GET_ONCOLOGY_DASH_KPIS_BARCHART_ADMIN API call here ==========

      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_KPIS_BARCHART_ADMIN,
      //   "get",
      //   "",
      //   params3
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading6",
      //     dispatch,
      //     setOncologyDashBaordKpi_AdminData
      //   );
      // });

      // loading6
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        params3Temp,
        params3,
        dispatch,
        setOncologyDashBaordKpi_AdminData,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_KPIS_BARCHART_ADMIN",
        null
      );

      //  ========== end GET_ONCOLOGY_DASH_KPIS_BARCHART_ADMIN API call here ==========

      //  ========== start GET_ONCOLOGY_DASH_KPIS_LINECHART_PREMED API call here ==========

      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_KPIS_LINECHART_PREMED,
      //   "get",
      //   "",
      //   params
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading7",
      //     dispatch,
      //     setOncologyDashBaordKpi_Premed_Linechart
      //   );
      // });

      // loading6
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setOncologyDashBaordKpi_Premed_Linechart,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_KPIS_LINECHART_PREMED",
        null
      );

      //  ========== end GET_ONCOLOGY_DASH_KPIS_LINECHART_PREMED API call here ==========

      //  ========== start GET_ONCOLOGY_DASH_KPIS_LINECHART_ADMIN API call here ==========

      // await genericAxiosCall(
      //   serverRoutes?.GET_ONCOLOGY_DASH_KPIS_LINECHART_ADMIN,
      //   "get",
      //   "",
      //   params
      // ).then((result) => {
      //   settingDataList(
      //     result,
      //     "loading8",
      //     dispatch,
      //     setOncologyDashBaordKpi_Admin_Linechart
      //   );
      // });

      // loading8
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setOncologyDashBaordKpi_Admin_Linechart,
        "oncologyDashboardFilter",
        "GET_ONCOLOGY_DASH_KPIS_LINECHART_ADMIN",
        null
      );

      //  ========== end GET_ONCOLOGY_DASH_KPIS_LINECHART_ADMIN API call here ==========

      ////// API CALL END //////////////
    } catch (e) {
      console.log("e--->>", e);

      // dispatch(
      //   setListingState({
      //     listing: "oncologyDashboardFilter",
      //     value: {
      //       initialLoading: false,
      //       loadingMore: "",
      //       hasMore: false,
      //     },
      //   })
      // );
      // dispatch(setOncologyDashBaordKpi_data([]));

      return false;
    }
  };

export const gettingAppealsDashBoardKpis_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch(
        setListingState({
          listing: "appealsDashboardFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      dispatch(
        setListingState({
          listing: "appealsDashboardFilter",
          value: {
            loading1: "data-loading",
            loading2: "data-loading",
            loading3: "data-loading",
          },
        })
      );

      let sliceStateUser = getState().pReducers.user;

      let sliceStateForDate =
        getState().npReducers.filters.insightsSreensCommonFilter;
      let sliceState = getState().npReducers.filters.appealsDashboardFilter;

      // below params for smartFilters
      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.workQueueId !== "" && {
          workQueueId: sliceState.workQueueId || "",
        }),
        ...(sliceState?.assigneeId !== "" && {
          assigneeId: sliceState.assigneeId || "",
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      // console.log("params--->>", params);

      //TOP CARD
      // /Analysis/GetOncologyTopCardkpis?  -->>
      genericAxiosCall(
        serverRoutes?.GET_APPEALS_DASHBOARD_TOP_CARDS,
        "get",
        "",
        // ""
        params
      ).then((result) => {
        settingDataList(
          result,
          "loading1",
          dispatch,
          setAppealsDahsboardKpiCards_data
        );
      });

      await genericAxiosCall(
        serverRoutes?.GET_APPEAL_DASHBOARD_LINECHART,
        "get",
        "",
        params
      ).then((result) => {
        settingDataList(
          result,
          "loading3",
          dispatch,
          setAppealsDahsboardLineChart_data
        );
      });

      dispatch(setAppealsDahsboardBarChart_data([]));
      await genericAxiosCall(
        serverRoutes?.GET_APPEAL_DASHBOARD_BARCHART,
        "get",
        "",
        params
      ).then((result) => {
        settingDataList(
          result,
          "loading4",
          dispatch,
          setAppealsDahsboardBarChart_data
        );
      });

      ////// API CALL END //////////////
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// get filters
export const gettingSmartFilterForOncologyDenial_Call =
  (target) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[target];
    let filterArr = sliceState?.smartFilterArr;
    dispatch(gettingSmartFilterArr());
    try {
      dispatch(
        setListingState({
          listing: target,
          value: {
            loadingFilters: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.GET_SERVICE_LINE_EXECPTIONS,
        "get"
      );

      ////// API CALL END //////////////
      // console.log("s123123 response > ", res);
      if (!res?.data?.success) {
        dispatch(setOncologyDenialFilters([]));
        dispatch(failedGettingSmartFilterArr());
        dispatch(
          setListingState({
            listing: target,
            value: {
              loadingFilters: "",
            },
          })
        );
      } else {
        dispatch(
          setListingState({
            listing: target,
            value: {
              loadingFilters: "",
            },
          })
        );
        dispatch(setOncologyDenialFilters(res?.data?.data));
      }

      return res?.data;
    } catch (e) {
      // console.log("123123e--->>");
      dispatch(failedGettingSmartFilterArr());

      dispatch(
        setListingState({
          listing: target,
          value: {
            loadingFilters: "",
          },
        })
      );
      return false;
    }
  };

export default OncologyDashboardKpisSlice.reducer;
