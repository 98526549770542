import React, { useEffect, useState } from "react";
import FooterCopyright from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { Tour } from "antd";
import { setListingState } from "../Redux/Reducers/filters/claimManagerFilters";
import { useDispatch } from "react-redux";
import genericAxiosCall from "../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../constants/serverRoutes";
import { userData } from "../Redux/Reducers/UserReducer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import browserRoute from "../constants/browserRoutes";
// import useRouteChangeListener from "../components/common/CustomHooks/useRouteChangeListener";

const withMainLayout = (WrappedComponent) => {
  const WithMain = () => {
    const navigate = useNavigate();
    const [openTour, setOpenTour] = useState(false);
    const { openDrawer, openDrawerChatbot, openClaimNotes } = useSelector(
      (state) => state.npReducers.filters.userPreferencesFilter
    );
    const user = useSelector((state) => state.pReducers.user);
    const dispatch = useDispatch();

    // useRouteChangeListener();

    useEffect(() => {
      if (!user?.loginUserData?.IsTourCompleted) {
        // navigate(browserRoute.REACT_SERVER_DEFAULT_URL);

        dispatch(
          setListingState({
            listing: "userPreferencesFilter",
            value: {
              openClaimNotes: false,
              openDrawerChatbot: false,
              openDrawer: true,
            },
          })
        );
        setOpenTour(true);
      }
    }, [user]);

    const steps = [
      {
        title: "Step 1",
        description:
          "Choose your desired solution from the menu to get started. ",
        placement: "bottom",
        // cover: <img style={{ width: "100px" }} alt="tour.png" src={DRLogoGr} />,
        target: () => document.getElementById("Step#1"),
      },
      {
        title: "Step 2",
        description:
          "Easily navigate through various modules using the left navigation bar. ",

        // cover: <img style={{ width: "100px" }} alt="tour.png" src={DRLogoGr} />,
        placement: "right",
        target: () => document.getElementById("Step#2"),
      },
      {
        title: "Step 3",
        description:
          "Personalize your experience by setting preferences or managing your profile.",
        // cover: <img style={{ width: "100px" }} alt="tour.png" src={DRLogoGr} />,
        placement: "bottom",
        target: () => document.getElementById("Step#3"),
      },
      {
        title: "Step 4",

        description:
          "Explore the settings to configure the platform according to your preferences.",
        // cover: <img style={{ width: "100px" }} alt="tour.png" src={DRLogoGr} />,
        placement: "top",
        target: () => document.getElementById("Step#4"),
      },
      {
        title: "Step 5",
        description:
          "Access date-specific information by selecting a date from the Date Picker.",
        // cover: <img style={{ width: "100px" }} alt="tour.png" src={DRLogoGr} />,
        placement: "bottom",
        target: () => document.getElementById("Step#5"),
      },
    ];

    const handleTourDismiss = () => {
      const data = {
        IsTourCompleted: true,
        UserId: user?.loginUserData?.userId,
      };
      try {
        genericAxiosCall(`${serverRoutes?.NEW_USER_TOUR}`, "put", data, "")
          .then((res) => {
            if (!res?.data?.success) {
              toast.error(`Something went wrong`, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              const userDataCustom = {
                ...user,
                loginUserData: {
                  ...user?.loginUserData,
                  IsTourCompleted: true,
                },
              };
              dispatch(userData(userDataCustom));
            }
          })
          .catch((error) => {
            toast.error(
              error.response.data.message
                ? error.response.data.message
                : "Something went wrong",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          });
      } catch (e) {}
      setOpenTour(false);
    };
    const botRoutes = [
      browserRoute?.CHAT_BOT_RESULT,
      browserRoute?.CHAT_BOT_RESULT,
      browserRoute?.ASK_ZOE,
      browserRoute?.ASK_ROVERS,
      browserRoute?.ASK_CUSTOM,
      browserRoute?.UPLOAD_DOCOMENTS,
      browserRoute?.DOMAIN_COPILOT,
    ];

    return (
      <div
        className={`dashbaord-parent-wrapper ${
          openDrawer ? "sidebar-open" : "sidebar-close"
        } ${openDrawerChatbot || openClaimNotes ? "chat-open" : "chat-close"}
            ${botRoutes?.includes(window.location.pathname) && "dr-copilot-llm"}
        `}
      >
        <Tour
          open={openTour}
          onClose={handleTourDismiss}
          steps={steps}
          zIndex="1610"
        />
        <WrappedComponent />
        {/* <FooterCopyright /> */}
      </div>
    );
  };

  return React.memo(WithMain);
};

export default withMainLayout;
