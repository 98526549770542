import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  MenuItem,
  SwipeableDrawer,
  FormControlLabel,
  Switch,
  Grid,
} from "@mui/material";
// import { NavLink } from "react-router-dom";
import { Select } from "antd";

import CloseIcon from "@mui/icons-material/Close";
import { saveUserPreferences_Call } from "../../Redux/Reducers/Settings/userPreferenceSlice";
import { setPreferences, userData } from "../../Redux/Reducers/UserReducer";
import PrefrencesIcon from "../../assets/svgs/PrefrencesIcon";
import SingleTagsDropDown from "../common/filters/SingleTagsDropDown";
import { getAllDenialCoordinator } from "../../Redux/Reducers/user/userSlice";
import {
  getDefaultDateForDashboard,
  macAddressOptions,
  setDefaultDateOnAllDashboards,
  timeZoneArray,
} from "../common/Utils/utils";
import { environment } from "../../constants/serverRoutes";
import {
  copilotStates,
  getJurisdictionPreference,
  getMacAddressPreference,
} from "../common/Utils/copilot";
import SingleTagsDropDownZoe from "../../pages/AskZoe/SingleTagsDropDownZoe";

const { Option } = Select;

export default function NewPreference({ setOpen }) {
  const dispatch = useDispatch();

  const {
    userPreferences,
    denialCoordinatorsList,
    loginUserData,
    currentModule,
    userTimeZone,
  } = useSelector((state) => state.pReducers.user);

  const user2 = useSelector((state) => state.pReducers.user);

  const PeriodDashboardArr = [
    "Current Month",
    "Last Month",
    "Current Quarter",
    "Last Quarter",
    // "Today",
    // "Yesterday",
    // "Last 7 Days",
    // "This Month",
    // "Last Month",
  ];

  const defaultTableRow = [10, 15, 25, 30];
  const [selectedRow, setSelectedRow] = useState(
    userPreferences?.NoOfRecordInTable ? userPreferences?.NoOfRecordInTable : 10
  );
  const [selectedCoordinator, setSelectedCoordinator] = useState(
    userPreferences?.CoordinatorPreference
      ? userPreferences?.CoordinatorPreference
      : null
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    userTimeZone ? userTimeZone : null
  );
  const [selectedMacAddress, setSelectedMacAddress] = useState(
    getMacAddressPreference()
  );
  const [jurisdictionOption, setJurisdictionOption] = useState(
    selectedMacAddress?.children
  );
  const [selectedJurisdiction, setSelectedJurisdiction] = useState(
    getJurisdictionPreference()
  );
  const defaultDateRange = ["1 Month", "2 Month", "3 Month"];

  const [dashboardTimePeriodState, setDashboardTimePeriodState] = useState(
    userPreferences?.dashboardTimePeriod
      ? userPreferences?.dashboardTimePeriod
      : "Current Month"
  );
  const [loadingClass, setLoadingClass] = useState("");

  const [selectedDateRange, setSelectedDateRange] = useState(
    userPreferences?.DateRange ? userPreferences?.DateRange : "3 Month"
  );

  useEffect(() => {
    setSelectedTimeZone(userTimeZone);
  }, [userTimeZone]);

  // const classes = useStyles();
  const [state, setState] = useState({
    right: false,
  });

  // handle Change row
  function handleChangeRow(value) {
    setSelectedRow(value);
  }

  // handle Change row
  function handleChangeDateRange(value) {
    setSelectedDateRange(value);
  }

  // handle submit preferences data
  const handleSubmit = async () => {
    try {
      let obj = {
        NoOfRecordInTable: `${selectedRow}`,
        Navigation: "left",
        Theme: "null",
        DateRange: `${selectedDateRange}`,
        PinFilter: filterPin,
        CoordinatorPreference:
          selectedCoordinator === "none" ? null : selectedCoordinator,
        // MacAddressPreference: selectedMacAddress,
        TimeZone: selectedTimeZone === "none" ? null : selectedTimeZone,
        MacAddressPreference: JSON.stringify({
          macAddress: selectedMacAddress,
          jurisdiction: selectedJurisdiction,
        }),
        dashboardTimePeriod: dashboardTimePeriodState,
      };
      // handleSavePreference(obj);
      setLoadingClass("loading");
      const res = await dispatch(saveUserPreferences_Call(obj));
      if (res?.success) {
        setState({ right: false });
        setOpen(false);
        dispatch(setPreferences(obj));

        //============= timeZone settings =============
        let finalUserModifiedObj = {};

        finalUserModifiedObj = {
          ...user2,
          userTimeZone: selectedTimeZone === "none" ? null : selectedTimeZone,
        };

        // console.log("finalUserModifiedObj--->>", finalUserModifiedObj);

        dispatch(userData(finalUserModifiedObj));

        //============= timeZone settings =============

        await setDefaultDateOnAllDashboards(dispatch, obj?.dashboardTimePeriod);
        localStorage.setItem("NoOfRecordInTable", selectedRow);
        // window.location.reload();

        setTimeout(() => {
          window.location.reload();
        }, 150);
      }
    } catch (err) {
      console.log("err--->>", err);
    } finally {
      setLoadingClass("");
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // handle change filterPin
  const [filterPin, setFilterPin] = useState(
    userPreferences?.PinFilter ? userPreferences?.PinFilter : false
  );
  const handleChangeFilterPin = (event) => {
    setFilterPin(event.target.checked);
  };

  const handleChangeDashboardTime = (e) => {
    setDashboardTimePeriodState(e);
  };

  const list = (anchor) => (
    <div role="presentation">
      <div className="DR-pre-sidebar-title">
        <h3>Preferences </h3>
        <div className="actions">
          <CloseIcon onClick={toggleDrawer(anchor, false)} />
        </div>
      </div>
      <div
        className="DR-pre-sidebar"
        style={{ minHeight: "calc(100vh - 60px)", position: "relative" }}
      >
        <Grid container>
          {!loginUserData?.isOnlyPolicyCopilotSelected &&
            currentModule.label !== "Copilot" && (
              <Grid item sm={12}>
                <Box className=" mb-4">
                  <FormControlLabel
                    className="DR-Exp-swith DR-inner-smart ml-0"
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={filterPin}
                        onChange={handleChangeFilterPin}
                        name="filterPin"
                        color="primary"
                      />
                    }
                    label="Pinned Filter"
                  />
                </Box>
              </Grid>
            )}
          {!loginUserData?.isOnlyPolicyCopilotSelected &&
            currentModule.label !== "Copilot" &&
            environment == "dev" && (
              <Grid item sm={12}>
                <label className="new-input-lable flex mb-1">
                  Dashboard Time Period
                </label>
                <Box className="input-new-design-icon mb-4">
                  <Select
                    defaultValue="Current Month"
                    className="input-new-drop-st pref-drop-height"
                    style={{ width: "100%" }}
                    popupClassName="DR-pre-dro"
                    defaultOpen
                    value={dashboardTimePeriodState}
                    optionFilterProp="children"
                    onChange={handleChangeDashboardTime}
                  >
                    {PeriodDashboardArr.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Box>
              </Grid>
            )}

          <Grid item sm={12}>
            <label className="new-input-lable flex mb-1">
              No. of Rows (Table)
            </label>
            <Box className="input-new-design-icon mb-4">
              <Select
                defaultValue={selectedRow}
                className="input-new-drop-st pref-drop-height"
                style={{ width: "100%" }}
                popupClassName="DR-pre-dro"
                onChange={handleChangeRow}
                defaultOpen
              >
                {defaultTableRow.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Box>
          </Grid>
          {!loginUserData?.isOnlyPolicyCopilotSelected &&
            currentModule.label !== "Copilot" && (
              <Grid item sm={12}>
                <label className="new-input-lable flex mb-1">
                  Denials Coordinator
                </label>
                <Box className="input-new-design-icon pref-drop-height full-width-drop mb-4">
                  <SingleTagsDropDown
                    data={denialCoordinatorsList}
                    defaultValue={userPreferences?.CoordinatorPreference}
                    selected={selectedCoordinator}
                    setSelected={setSelectedCoordinator}
                    labelTarget="coordinatorName"
                    valueTarget="coordinatorId"
                    none={true}
                  />
                </Box>
              </Grid>
            )}
          {currentModule.label == "Copilot" && (
            <Grid item sm={12}>
              <label className="new-input-lable flex mb-1">MAC</label>
              <Box className="input-new-design-icon pref-drop-height full-width-drop mb-4">
                <SingleTagsDropDownZoe
                  data={copilotStates}
                  // defaultValue={getMacAddressPreference()}
                  selected={selectedMacAddress}
                  setSelected={setSelectedMacAddress}
                  labelTarget="label"
                  valueTarget="value"
                  none={false}
                  noneLabel={"Select your MAC"}
                  mainMac={true}
                  setJurisdictionOption={setJurisdictionOption}
                  setSelectedJurisdiction={setSelectedJurisdiction}
                />
              </Box>
              <Box className="input-new-design-icon pref-drop-height full-width-drop mb-4">
                <SingleTagsDropDownZoe
                  data={jurisdictionOption}
                  // defaultValue={getMacAddressPreference()}

                  selected={selectedJurisdiction}
                  setSelected={setSelectedJurisdiction}
                  labelTarget="label"
                  valueTarget="value"
                  none={false}
                  noneLabel={"Select your Jurisdiction"}
                />
              </Box>
            </Grid>
          )}

          <Grid item sm={12}>
            <label className="new-input-lable flex mb-1">Time Zone</label>
            <Box className="input-new-design-icon pref-drop-height full-width-drop mb-4">
              <SingleTagsDropDown
                data={timeZoneArray}
                defaultValue={selectedTimeZone}
                selected={selectedTimeZone}
                setSelected={setSelectedTimeZone}
                labelTarget="timeZoneTitleLabel"
                valueTarget="timeZoneTitleLabel"
                none={true}
              />
            </Box>
          </Grid>
        </Grid>

        <Box
          className=""
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        >
          <Button
            style={{
              height: "44px",
            }}
            className={"new-coman-btn w-100 mt-2 " + loadingClass}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </div>
    </div>
  );

  useEffect(() => {
    dispatch(getAllDenialCoordinator());
  }, []);

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuItem onClick={toggleDrawer(anchor, true)}>
            <span className="link-inn-menu m-0 p-0 flex">
              <PrefrencesIcon color="#131722" />
              <span className="link-one-text pb-4 ml-3">Preferences</span>
            </span>
          </MenuItem>
          <SwipeableDrawer
            className="DR-pref-open"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            style={{ width: "400px" }}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
