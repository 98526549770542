import React from "react";

const V2ChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="Chatgpt"
      stroke="none"
    >
      <path
        d="m7.77,10.69c.1.04.2.06.3.06.29,0,.56-.17.69-.45l2.77-6.29c.11-.24.32-.27.41-.27s.3.03.41.27l.57,1.29c.17.38.61.55.99.38.38-.17.55-.61.38-.99l-.57-1.29c-.32-.72-1-1.16-1.78-1.16h0c-.78,0-1.46.45-1.78,1.16l-2.77,6.29c-.17.38,0,.82.38.99Z"
        fill="#ffffff"
        stroke="none"
        class="color000000 svgShape"
      ></path>
      <path
        d="m5.93,12.42l1.35,2c.15.21.38.33.62.33.15,0,.29-.04.42-.13.34-.23.43-.7.2-1.04l-1.37-2.02-2.34-3.2c-.16-.21-.07-.41-.03-.49.04-.08.17-.25.44-.22l1.63.18c.4.04.78-.25.83-.66.04-.41-.25-.78-.66-.83l-1.63-.18c-.78-.09-1.5.28-1.9.96-.39.68-.35,1.49.12,2.12l2.32,3.17Z"
        fill="#ffffff"
        stroke="none"
        class="color000000 svgShape"
      ></path>
      <path
        d="m12.68,16.35c-.04-.41-.4-.71-.81-.68l-6.64.67c-.27.02-.39-.15-.44-.22-.04-.08-.13-.27.03-.49l.72-.99c.24-.33.17-.8-.16-1.05-.33-.24-.8-.17-1.05.16l-.72.99c-.46.63-.51,1.45-.12,2.12.35.61.98.97,1.68.97.07,0,.15,0,.22-.01l3.92-.43,2.69-.24c.41-.04.72-.4.68-.81Z"
        fill="#ffffff"
        stroke="none"
        class="color000000 svgShape"
      ></path>
      <path
        d="m16.06,13.31c-.38-.16-.82.02-.98.4l-2.73,6.28c-.11.24-.32.27-.41.27s-.3-.03-.41-.27l-.57-1.29c-.17-.38-.61-.55-.99-.38-.38.17-.55.61-.38.99l.57,1.29c.32.72,1,1.16,1.78,1.16h0c.78,0,1.47-.44,1.78-1.16l1.6-3.63,1.13-2.66c.16-.38-.02-.82-.4-.98Z"
        fill="#ffffff"
        stroke="none"
        class="color000000 svgShape"
      ></path>
      <path
        d="m16.55,9.62c-.24-.34-.71-.42-1.05-.17-.34.24-.42.71-.17,1.05l3.73,5.14c.16.21.07.41.03.49-.04.08-.17.25-.43.22l-1.63-.18c-.41-.04-.78.25-.83.66-.04.41.25.78.66.83l1.63.18c.07,0,.15.01.22.01.69,0,1.32-.36,1.68-.97.39-.68.35-1.49-.12-2.12l-3.72-5.13Z"
        fill="#ffffff"
        stroke="none"
        class="color000000 svgShape"
      ></path>
      <path
        d="m11.19,7.67c.04.41.4.71.82.67l6.64-.69c.26-.02.39.15.43.22s.13.28-.03.49l-.87,1.19c-.25.33-.17.8.16,1.05.13.1.29.15.44.15.23,0,.46-.11.61-.31l.88-1.19c.46-.63.51-1.45.11-2.12-.39-.68-1.12-1.04-1.9-.96l-6.62.69c-.41.04-.71.41-.67.82Z"
        fill="#ffffff"
        stroke="none"
        class="color000000 svgShape"
      ></path>
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   stroke="#131722"
    //   stroke-width="1.5"
    //   stroke-linecap="round"
    //   stroke-linejoin="round"
    // >
    //   <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
    // </svg>
    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g opacity="0.7">
    //     <path
    //       d="M15.5 11.25H8.5C8.09 11.25 7.75 10.91 7.75 10.5C7.75 10.09 8.09 9.75 8.5 9.75H15.5C15.91 9.75 16.25 10.09 16.25 10.5C16.25 10.91 15.91 11.25 15.5 11.25Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M16 22.3199C15.66 22.3199 15.32 22.22 15.03 22.03L10.77 19.1899H7C3.56 19.1899 1.25 16.8799 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.6199 20.77 18.84 17.75 19.15V20.5699C17.75 21.2199 17.4 21.8099 16.83 22.1099C16.57 22.2499 16.28 22.3199 16 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.4299C2.75 16.0099 4.42 17.6799 7 17.6799H11C11.15 17.6799 11.29 17.7199 11.42 17.8099L15.87 20.77C15.98 20.84 16.08 20.81 16.13 20.78C16.18 20.75 16.26 20.6899 16.26 20.5599V18.4299C16.26 18.0199 16.6 17.6799 17.01 17.6799C19.59 17.6799 21.26 16.0099 21.26 13.4299V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z"
    //       fill="#092C4C"
    //     />
    //   </g>
    // </svg>
  );
};

export default V2ChatIcon;
