import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { TextField, Typography } from "@mui/material";
import CrossIcon from "../../assets/svgs/CrossIcon";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DRRightArrow from "./DRRightArrow";

export default function DRGroupQuestions({
  title,
  sampleQuestion,
  handleClick,
  loading,
}) {
  const [state, setState] = React.useState(false);

  const OpenSideDraw = () => {
    setState(true);
  };

  return (
    <div style={{ width: "285px" }}>
      <div
        className="side-bar-title flex items-center justify-between "
        style={{
          borderBottom: "1px solid rgb(239, 239, 239)",
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
      >
        <Typography
          variant=""
          className="noticication-bar-title-1"
          style={{ fontWeight: 600 }}
        >
          {title ? title : "Smart Insights"}
        </Typography>
        <div className="flex items-center ">
          <div className="flex items-center">
            {/* <div
              className="flex justify-center items-center cursor-pointer"
              onClick={() => setState(false)}
            >
              <CrossIcon />
            </div> */}
          </div>
        </div>
      </div>
      <div className="p-4">
        <p className="mb-4 DR-group-que-text-top">
          Select a question from the list below or type your question directly
          in the input field.
        </p>
        {sampleQuestion?.map((item, index) => {
          return (
            <div className="DR-Group-question-collaps mt-2" key={index}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {item?.title}
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="DR-grp-question-list">
                    {item?.questions?.map((question, index2) => {
                      return (
                        <li
                          key={index2}
                          style={{
                            opacity: question?.disabled ? "0.5" : "1",
                            cursor: question?.disabled
                              ? "not-allowed"
                              : "pointer",
                          }}
                          onClick={() => {
                            if (!loading && !question?.disabled) {
                              handleClick(question?.value);
                              setState(false);
                            }
                          }}
                        >
                          <span>{question?.value}</span>
                          <span className="DR-grp-question-list-icon">
                            <DRRightArrow />
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
    </div>
  );
}
