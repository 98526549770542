import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import { commonPagination } from "../../../components/common/Utils/utils";
import { getMultiSelectFilterValueForApi } from "./../../../components/common/Utils/utils";

export const LogsSlice = createSlice({
  name: "LogsSlice",
  initialState: {
    activityLogsList: { count: null, errors: [] },
    errorLogsList: { count: null, errors: [] },
    jobEventsList: { count: null, result: [] },
    fileLogsList: { count: null, tabularArr: [] },
    dataSource_data: {},
    detailsByPTCN_data: { claimLevel: [], serviceLevel: [], ptcNslicer: [] },
  },
  reducers: {
    setActivityLogsTabular_dataAction: (state, action) => {
      let data = action.payload;
      if (data?.errors.length > 0) {
        state.activityLogsList = data;
      } else {
        state.activityLogsList.count = null;
        state.activityLogsList.errors = [];
      }
    },

    setErrorLogsTabular_dataAction: (state, action) => {
      let data = action.payload;
      if (data?.errors.length > 0) {
        state.errorLogsList = data;
      } else {
        state.errorLogsList.count = null;
        state.errorLogsList.errors = [];
      }
    },

    setJobEventsTabular_dataAction: (state, action) => {
      let data = action.payload;

      if (data?.length > 0) {
        state.jobEventsList.result = data;
        state.jobEventsList.count = data[0]?.totalRows;
      } else {
        state.jobEventsList.count = null;
        state.jobEventsList.result = [];
      }
    },

    setDataSource_dataAction: (state, action) => {
      let data = action.payload;
      state.dataSource_data = data;
    },

    setFilesSourceErrorSuccess_Data: (state, action) => {
      let data = action.payload;

      if (data && data?.length) {
        state.fileLogsList.tabularArr = data;
        state.fileLogsList.count = data[0]?.countRecords;
      } else {
        state.fileLogsList.tabularArr = [];
        state.fileLogsList.count = 0;
      }
    },

    setDetailsByPTCN_dataAction: (state, action) => {
      let data = action.payload;
      if (data?.ptcNslicer?.length > 0) {
        let newArr = [];
        data?.ptcNslicer?.forEach((item) => {
          newArr.push({
            label: item?.ptcn,
            value: item?.ptcn,
          });
        });

        state.detailsByPTCN_data.ptcNslicer = newArr;
      } else {
        state.detailsByPTCN_data.ptcNslicer = [];
      }
      if (data?.claimLevel?.length > 0) {
        state.detailsByPTCN_data.claimLevel = data?.claimLevel;
      } else {
        state.detailsByPTCN_data.claimLevel = [];
      }
      if (data?.serviceLevel?.length > 0) {
        state.detailsByPTCN_data.serviceLevel = data?.serviceLevel;
      } else {
        state.detailsByPTCN_data.serviceLevel = [];
      }
    },
  },
});

export const {
  setActivityLogsTabular_dataAction,
  setErrorLogsTabular_dataAction,
  setJobEventsTabular_dataAction,
  setDataSource_dataAction,
  setDetailsByPTCN_dataAction,
  setFilesSourceErrorSuccess_Data,
} = LogsSlice.actions;

// get activity logs list
export const gettingActivityLogsList_Call =
  (id) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.logsPageFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    let search = sliceState.search;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "logsPageFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      var res = await genericAxiosCall(
        `${serverRoutes?.GET_DATA_ACTIVITY_ERRORS}${
          id ? id : sliceState?.ftpSourceId
        }/${start}/${limit}/${search === "" ? "%20" : search}/`,
        "get"
      );
      if (!res?.data?.success) {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(
          setListingState({
            listing: "logsPageFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(setActivityLogsTabular_dataAction(res?.data?.data));
        dispatch(
          setListingState({
            listing: "logsPageFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      }

      return res?.data;
    } catch (e) {
      //   console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "logsPageFilter",
          value: {
            loadingMore: "",
          },
        })
      );
      dispatch(setActivityLogsTabular_dataAction([]));
    }
  };

// get Error logs list
export const gettingErrorLogsList_Call = (id) => async (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.logsPageFilter;
  let start = sliceState.pageNumber;
  let limit = sliceState.pageSize;
  let search = sliceState.search;
  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    start = rec_val;
  }
  if (start == 1) {
    start = 0;
  }

  try {
    dispatch(
      setListingState({
        listing: "logsPageFilter",
        value: {
          loadingMore: "data-loading",
        },
      })
    );

    const res = await genericAxiosCall(
      `${serverRoutes?.GET_DATA_SOURCE_ERRORS}${
        id ? id : sliceState?.ftpSourceId
      }/${start}/${limit}/${search === "" ? "%20" : search}/`,
      "get"
    );
    if (!res?.data?.success) {
      toast.error(`Something went wrong`, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(
        setListingState({
          listing: "logsPageFilter",
          value: {
            loadingMore: "",
          },
        })
      );
      dispatch(setErrorLogsTabular_dataAction([]));
    } else {
      dispatch(setErrorLogsTabular_dataAction(res?.data?.data));
      dispatch(
        setListingState({
          listing: "logsPageFilter",
          value: {
            loadingMore: "",
          },
        })
      );
    }

    return res?.data;
  } catch (e) {
    // console.log("e--->>", e);

    dispatch(
      setListingState({
        listing: "logsPageFilter",
        value: {
          loadingMore: "",
        },
      })
    );
    dispatch(setErrorLogsTabular_dataAction([]));
  }
};

// get job envents list
let jobEventId;
export const gettingJobEventsList_Call = (id) => async (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.logsPageFilter;
  let start = sliceState.pageNumber;
  let limit = sliceState.pageSize;
  let search = sliceState.search;
  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    start = rec_val;
  }
  if (start == 1) {
    start = 0;
  }

  try {
    dispatch(
      setListingState({
        listing: "logsPageFilter",
        value: {
          loadingMore: "data-loading",
        },
      })
    );

    const res = await genericAxiosCall(
      `${serverRoutes?.GET_JOB_EVENTS_ERRORS_BY_DATES}${
        id ? id : sliceState?.ftpSourceId
      }/${start}/${limit}`,
      "get"
    );
    if (!res?.data?.success) {
      toast.error(`Something went wrong`, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(
        setListingState({
          listing: "logsPageFilter",
          value: {
            loadingMore: "",
          },
        })
      );
      dispatch(setJobEventsTabular_dataAction([]));
    } else {
      dispatch(setJobEventsTabular_dataAction(res?.data?.data));
      dispatch(
        setListingState({
          listing: "logsPageFilter",
          value: {
            loadingMore: "",
          },
        })
      );
    }

    return res?.data;
  } catch (e) {
    // console.log("e--->>", e);

    dispatch(
      setListingState({
        listing: "logsPageFilter",
        value: {
          loadingMore: "",
        },
      })
    );
    dispatch(setJobEventsTabular_dataAction([]));
  }
};
//
export const getJobEventTabularLevelOne_Data =
  (createdOn, sourceId) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.fileSuccessFailFilter1stDropdown;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }
    try {
      ///// API CALL //////

      let params = {
        start,
        limit,
        sourceId: createdOn?.sourceId,
        date: createdOn?.createdOn,
      };
      const res = await genericAxiosCall(
        `${serverRoutes?.GET_JOB_EVENTS_ERRORS}`,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

//
export const getJobEventTabularLevelOne_DataWithPage =
  (createdOn, sourceId, pageNumber, pageSize) => async (dispatch, getState) => {
    let start = pageNumber;
    let limit = pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let params = {
        start,
        limit,
        sourceId: createdOn?.sourceId,
        date: createdOn?.createdOn,
      };
      const res = await genericAxiosCall(
        `${serverRoutes?.GET_JOB_EVENTS_ERRORS}`,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };
//
// get details by PTCN list
export const gettingDetailPTCN_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "detailsByPTCNFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.detailsByPTCNFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selPTCN?.length > 0 && {
          PTCN: getMultiSelectFilterValueForApi(sliceState?.selPTCN),
        }),
      };
      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selPTCN?.length > 0 && {
          PTCN: getMultiSelectFilterValueForApi(sliceState?.selPTCN),
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDetailsByPTCN_dataAction,
        "detailsByPTCNFilter",
        "GET_DETAILS_BY_PTCN_DATA",
        null
      );
    } catch (e) {
      //   console.log("e--->>", e);
    }
  };

// get file logs success error
export const gettingFilesSourceErrorSuccess_Call =
  (isSuccess, eventId, sourceId) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.fileSuccessFailFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(eventId !== "" && {
          eventId: eventId,
        }),
        ...(sourceId !== "" && {
          sourceId: sourceId,
        }),
        ...(isSuccess !== "" && {
          fileType:
            isSuccess === "all" ? "all" : isSuccess ? "success" : "error",
        }),
      };
      let params = {
        start,
        limit,
        ...(eventId !== "" && {
          eventId: eventId,
        }),
        ...(sourceId !== "" && {
          sourceId: sourceId,
        }),
        ...(isSuccess !== "" && {
          fileType:
            isSuccess === "all" ? "all" : isSuccess ? "success" : "error",
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setFilesSourceErrorSuccess_Data,
        "fileSuccessFailFilter",
        "GET_SOURCE_FILES_SUCCESS_FAIL_LIST",
        null
      );
    } catch (e) {
      //   console.log("e--->>", e);
    }
  };

// get details by PTCN list
export const gettingCombinedClaims_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "detailsByPTCNFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.detailsByPTCNFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selPTCN?.length > 0 && {
          PTCN: getMultiSelectFilterValueForApi(sliceState?.selPTCN),
        }),
      };

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_RELATED_CLAIMS_837}${"claimId"}`,
        "get",
        "",
        ""
      );

      if (res?.data?.success) {
        // setClaim_Types(res?.data?.data ? res?.data?.data : []);
      }
    } catch (e) {
      //   console.log("e--->>", e);
    }
  };

export default LogsSlice.reducer;
