import { createSlice } from '@reduxjs/toolkit';
import {
  resetListingState,
} from '../filters/claimManagerFilters';
import { commonPagination } from '../../../components/common/Utils/utils';

export const LoginActivitySlice = createSlice({
  name: 'LoginActivitySlice',
  initialState: {
    loginActivityArr: {},
  },
  reducers: {
    setLoginActivityTabular_dataAction: (state, action) => {
      let data = action.payload;
        state.loginActivityArr = data;
      }
  },
});

export const { setLoginActivityTabular_dataAction } = LoginActivitySlice.actions;

// get dataSources list
export const gettingLoginActivityTabularData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: 'loginActivityFilter',
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.loginActivityFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState?.from !== '' && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== '' && {
          to: sliceState.to,
        }),
        ...(sliceState.order !== '' && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== '' && {
          OrderBy: sliceState.orderBy,
        }),
      };
      let params = {
        start,
        limit,
        ...(sliceState?.from !== '' && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== '' && {
          to: sliceState.to,
        }),
        ...(sliceState.order !== '' && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== '' && {
          OrderBy: sliceState.orderBy,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setLoginActivityTabular_dataAction,
        'loginActivityFilter',
        'GET_ALL_LOGIN_ACTIVITY',
        null
      );
    } catch (e) {
      //   console.log("e--->>", e);
    }
  };

export default LoginActivitySlice.reducer;
