import React from "react";

const DRFlagClaimIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-flag-3-filled"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="#fd0061"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        d="M19 4c.852 0 1.297 .986 .783 1.623l-.076 .084l-3.792 3.793l3.792 3.793c.603 .602 .22 1.614 -.593 1.701l-.114 .006h-13v6a1 1 0 0 1 -.883 .993l-.117 .007a1 1 0 0 1 -.993 -.883l-.007 -.117v-16a1 1 0 0 1 .883 -.993l.117 -.007h14z"
        stroke-width="0"
        fill="#fd0061"
      />
    </svg>
  );
};

export default DRFlagClaimIcon;
