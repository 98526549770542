import { minWidth, width } from "@mui/system";

export default {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      height: 90,
    },
    highlighter: {
      padding: 9,
      lineHeight: "16px",
      border: "1px solid transparent",
      maxHeight: "90px",
    },
    input: {
      padding: 9,
      lineHeight: "16px",
      border: "1px solid silver",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: "100%",

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.06)",
      fontSize: 14,
      overflowY: "auto", // Add overflow-y property
      maxHeight: "300px",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.06)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
