import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { common } from "../../../../actions";
import CommonService from "../../../../actions/common";
// import genericAxiosCall from "./../../../../AxiosConfig/genericAxiosCall";
import {
  getMultiSelectFilterValueForApi,
  getMultiSelectFilterIdForApi_ForId,
  // encodeData,
} from "../../../../components/common/Utils/utils";

import {
  setListingState,
  resetListingState,
  resetListingStateForResetButton,
} from "../../filters/claimManagerFilters";

export const claimManagerTestSlice = createSlice({
  name: "claimManager",
  initialState: {
    claimManager_Data: { count: 0, totalChargeAmount: 0, output: [] },
    payers: [],
    providers: [],
    cptCodesForManager: [],
    diagCodesForManager: [],
    claimStatus: [
      { value: "Accepted", label: "Accepted" },
      { value: "Rejected", label: "Rejected" },
      { value: "Open", label: "Open" },
    ],
    claimFrequency: [
      { value: 1, label: "Original" },
      { value: 7, label: "Replacement" },
      { value: 8, label: "Void/Cancel" },
    ],
  },
  reducers: {
    claimManagerFilter_DataAction: (state, action) => {
      let data = action.payload;

      if (data?.claims?.length) {
        state.claimManager_Data.output = data?.claims;
        state.claimManager_Data.count = data?.claims[0]?.count;
        state.claimManager_Data.totalChargeAmount =
          data?.claims[0]?.totalChargeAmount;
      } else {
        state.claimManager_Data.output = [];
        state.claimManager_Data.count = null;
        state.claimManager_Data.totalChargeAmount = null;
      }
    },

    setPayersForFilter: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.forEach((item) => {
          newArr.push({
            label: item?.payer,
            value: item?.id,
            // id: item.id,
          });
        });

        state.payers = newArr;
      } else {
        state.payers = [];
      }
    },

    setProvidersForFilter: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.forEach((item) => {
          newArr.push({
            label: item?.name,
            value: item?.billingProviderId,
          });
        });

        state.providers = newArr;
      } else {
        state.providers = [];
      }
    },

    setCptCodesForFilter: (state, action) => {
      //   console.log("setCptCodesForFilter--->>", action?.payload);
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.slice(0, 50).forEach((item) => {
          if (item?.CPTCode !== "") {
            let userFriendlyName = item?.description
              ? `${item.cptCode} - ${item.description}`
              : `${item.cptCode}`;
            newArr.push({
              label: userFriendlyName,
              value: item?.cptCode,
            });
          }
        });

        state.cptCodesForManager = newArr;
      } else {
        state.cptCodesForManager = [];
      }
    },

    setDiagnosisCodesForFilter: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.slice(0, 50).forEach((item) => {
          newArr.push({
            label: item?.diagnosisCode,
            value: item?.diagnosisCode,
          });
        });

        state.diagCodesForManager = newArr;
      } else {
        state.diagCodesForManager = [];
      }
    },

    setClaimFrequencyForFilter: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.forEach((item) => {
          newArr.push({
            label: item?.displayText,
            value: item?.value,
          });
        });

        state.claimFrequency = newArr;
      } else {
        state.claimFrequency = [];
      }
    },

    setClaimStatusForFilter: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.forEach((item) => {
          newArr.push({
            label: item?.displayText,
            value: item?.value,
          });
        });

        state.claimStatus = newArr;
      } else {
        state.claimStatus = [];
      }
    },
  },
});

export const {
  setDiagnosisCodesForFilter,
  setCptCodesForFilter,
  claimManagerFilter_DataAction,
  setPayersForFilter,
  setProvidersForFilter,
  setClaimFrequencyForFilter,
  setClaimStatusForFilter,
} = claimManagerTestSlice.actions;

// get DiagnosisCodes list for Manager(837)
export const gettingDiagnosisCodesForManagerTest =
  (targetedFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetedFilter];

    const diagCodes = await CommonService.getDiagnosisCodesForClaimRequest({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });
    if (diagCodes?.data?.success) {
      dispatch(setDiagnosisCodesForFilter(diagCodes?.data?.data));
    } else {
      dispatch(setDiagnosisCodesForFilter([]));
    }
    return diagCodes?.data;
  };

// get CPTCodes list for Manager(837)
export const gettingCPTCodesForManagerTest =
  (targetedFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetedFilter];

    const cptCodes = await CommonService.getCPTCodesForClaimRequest({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });
    if (cptCodes?.data?.success) {
      dispatch(setCptCodesForFilter(cptCodes?.data?.data));
    } else {
      dispatch(setCptCodesForFilter([]));
    }
    return cptCodes?.data;
  };

// get all payers list for Manager(837)
export const gettingPayersForManagerTest =
  (targetedFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetedFilter];

    const res = await CommonService.getPayersForClaimRequest({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });
    if (res?.data?.success) {
      dispatch(setPayersForFilter(res?.data?.data));
    } else {
      dispatch(setPayersForFilter([]));
    }
    return res?.data;
  };

// getting Claim Request For Filter test compo (837) table data 2nd api
export const claimManagerRequestForFilter2ndAPI_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "claimManagerTestFilter",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "claimManagerTestFilter",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
    } else {
    }

    let sliceState = getState().npReducers.filters.claimManagerTestFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }
    // console.log("claimManagerRequestForFilter2ndAPI_Call--->>");
    try {
      dispatch(
        setListingState({
          listing: "claimManagerTestFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await common.getClaimRequestForFilter_testApi_2nd({
        start,
        limit,
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),

        ...(sliceState?.BillingProviderIds?.length > 0 && {
          BillingProviderIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.BillingProviderIds
          ),
        }),

        ...(sliceState?.frequencyType?.length > 0 && {
          FrequencyCode: getMultiSelectFilterValueForApi(
            sliceState?.frequencyType
          ),
        }),
        ...(sliceState?.claimStatus?.length > 0 && {
          ClaimStatus: getMultiSelectFilterValueForApi(sliceState?.claimStatus),
        }),
        ...(sliceState?.DiagnosisCode?.length > 0 && {
          DiagnosisCode: getMultiSelectFilterValueForApi(
            sliceState?.DiagnosisCode
          ),
        }),
        ...(sliceState?.CPTCode?.length > 0 && {
          CPTCode: getMultiSelectFilterValueForApi(sliceState?.CPTCode),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(!sliceState?.search &&
          sliceState?.from !== "" && {
            from: sliceState?.from,
          }),
        ...(!sliceState?.search &&
          sliceState?.to !== "" && {
            to: sliceState?.to,
          }),
      });
      ////// //////////////

      if (!res?.data?.success) {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(claimManagerFilter_DataAction([]));
        dispatch(setCptCodesForFilter([]));
        dispatch(setDiagnosisCodesForFilter([]));
        dispatch(setPayersForFilter([]));
        // dispatch(setClaimFrequencyForFilter([]));
        // dispatch(setClaimStatusForFilter([]));
        dispatch(
          setListingState({
            listing: "claimManagerTestFilter",
            value: {
              // hasMore: res?.data?.data.output.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      } else {
        if (res?.data?.data?.claims?.length) {
          dispatch(claimManagerFilter_DataAction(res?.data?.data));
        } else {
          dispatch(claimManagerFilter_DataAction([]));
        }

        if (res?.data?.data?.cptCodes?.length) {
          dispatch(setCptCodesForFilter(res?.data?.data?.cptCodes));
        } else {
          dispatch(setCptCodesForFilter([]));
        }

        if (res?.data?.data?.diagnoseCodes?.length) {
          dispatch(setDiagnosisCodesForFilter(res?.data?.data?.diagnoseCodes));
        } else {
          dispatch(setDiagnosisCodesForFilter([]));
        }

        if (res?.data?.data?.payers?.length) {
          dispatch(setPayersForFilter(res?.data?.data?.payers));
        } else {
          dispatch(setPayersForFilter([]));
        }

        if (res?.data?.data?.billingProviders?.length) {
          dispatch(setProvidersForFilter(res?.data?.data?.billingProviders));
        } else {
          dispatch(setProvidersForFilter([]));
        }

        // if (res?.data?.data?.frequencyTypes?.length) {
        //   dispatch(setClaimFrequencyForFilter(res?.data?.data?.frequencyTypes));
        // } else {
        //   dispatch(setClaimFrequencyForFilter([]));
        // }

        // if (res?.data?.data?.claimStatus?.length) {
        //   dispatch(setClaimStatusForFilter(res?.data?.data?.claimStatus));
        // } else {
        //   dispatch(setClaimStatusForFilter([]));
        // }

        // alert("success_true--->>");
        dispatch(
          setListingState({
            listing: "claimManagerTestFilter",
            value: {
              hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: "",
              // payers: [],
              // frequencyType: [],
              // DiagnosisCode: [],
              // CPTCode: [],
              // BillingProviderIds: [],
            },
          })
        );
      }

      return res?.data;
    } catch (e) {
      // console.log("catch_err--->>", e);

      dispatch(
        setListingState({
          listing: "claimManagerTestFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(claimManagerFilter_DataAction([]));
      dispatch(setCptCodesForFilter([]));
      dispatch(setDiagnosisCodesForFilter([]));
      dispatch(setPayersForFilter([]));
      dispatch(setClaimFrequencyForFilter([]));
      dispatch(setClaimStatusForFilter([]));

      return false;
    }
  };

export default claimManagerTestSlice.reducer;
