import { Tooltip } from "@mui/material";

export default function BootstrapTooltip(props) {
  return <Tooltip arrow {...props} />;

  // PopperProps={{ disablePortal: true }}
}

// export default function CustomizedTooltips() {
//   return (
//     <div>
//       <BootstrapTooltip title="Add">
//         <Button>Bootstrap</Button>
//       </BootstrapTooltip>
//     </div>
//   );
// }
